import { useState, SyntheticEvent, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FormTextElement } from "./form elements/FormTextElement";
import { FormDateElement } from "./form elements/FormDateElement";
import { FormSelectElement } from "./form elements/FormSelectElement";
import { EVENT_CHOICES, WEAPON_CHOICES } from "../../constants/VarOptions";
import { CSRF } from "./form elements/CSRF";
import { getNewRefreshToken } from "../../hooks/utils";
import swal from "sweetalert";


interface ChildComponentProps {
  setDataUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setDataReceived: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  slugData: string;
  setSlugData: React.Dispatch<React.SetStateAction<string>>;
  btnValue: string;
}

export const EventForm = (props: ChildComponentProps) => {

  const { tournamentSlug } = useParams();
  const [eventName, setEventName] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [eventLevel, setEventLevel] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [weapon, setWeapon] = useState<string>("");
  const [userId, setUserId] = useState<String>("");
  const [tournamentId, setTournamentId] = useState<String>("");
  const [validations, setValidations] = useState(false);
  

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault(); // prevents page from reloading.

    if (props.slugData) {
      if (eventName && date && eventLevel && weapon) {
        setValidations(false);
        try {
          await getNewRefreshToken();
          const { data } = await axios.patch(
            `api/events/update/${props.slugData}/`,
            {
              tournament: tournamentId,
              slug: props.slugData,
              name: eventName,
              date,
              weapon,
              event_type: eventLevel,
              notes,
            },
            { withCredentials: true }
          );
          setDate('');
          setEventName('');
          setEventLevel('');
          setNotes('');
          setWeapon('');
          setTournamentId('')
          props.setSlugData("");
          props.setDataUpdate(true)
          props.setDataReceived(false)
          props.setOpen(false)
          swal({
            title: 'Event Updated',
            icon: 'success',
            timer: 3000,
          });

        }
        catch (error: any) {
          console.log('error', error)
          swal({
            title: "Error",
            text: error.response?.data?.detail,
            icon: "warning",
            dangerMode: true,
          })
        }
      } else {
        setValidations(true);
      }
    }
    else {
      if (eventLevel && weapon && eventName && date) {
        setValidations(false);
        try {
          await getNewRefreshToken();
          const { data } = await axios.post(
            "api/events/create/",
            {
              tournament_slug: tournamentSlug,
              name: eventName,
              date,
              weapon,
              event_type: eventLevel,
              notes
            },
            { withCredentials: true }
          );
          props.setDataUpdate(true)
          props.setDataReceived(false)
          setDate('');
          setEventName('');
          setEventLevel('');
          setNotes('');
          setWeapon('');
          setTournamentId('')

          swal({
            title: 'Event Created',
            icon: 'success',
            timer: 3000,
          });

        } catch (error: any) {
          console.log('error', error)
          swal({
            title: "Error",
            text: error.response?.data?.detail,
            icon: "warning",
            dangerMode: true,
          })
        }
      }
      else {
        setValidations(true);
      }
    }
  }

  useEffect(() => {
    const myObjectString = localStorage.getItem("userData");
    if (myObjectString !== null) {
      const myObject = JSON.parse(myObjectString);
      // setUserId(myObject.id);
    }

    if (props.slugData) {
      (async () => {
        const result = await axios.get(`api/events/detail/${props.slugData}/`, {
          withCredentials: true,
        });
        // this is gross, why are we replacing the date on edits??
        const dateString = "06/09/2023";
        const dateParts = dateString.split("/");
        const year = dateParts[2];
        const month = dateParts[1];
        const day = dateParts[0];
        const formattedDate = `${year}-${month}-${day}`;
        setDate(formattedDate);
        setEventName(result.data.name);
        setEventLevel(result.data.event_type);
        setNotes(result.data.notes);
        setWeapon(result.data.weapon);
        setTournamentId(result.data.tournament)
      })();
    }
  }, [])

  return (
    <>
      <form className="eventform" onSubmit={submit}>
        <CSRF />
        <div className="Please-error">
          <FormTextElement setValue={setEventName} value={eventName} elementName="event" placeholder="" labelText="Event Name" />
          {validations && !eventName && <p className="testerror">Please enter event name</p>}
        </div>
        <div className="Please-error">
          <FormDateElement setValue={setDate} value={date} elementName="date" placeholder="" labelText="Date" />
          {validations && !date && <p className="testerror">Please enter date</p>}
        </div>
        <div className="Please-error">
          <FormSelectElement setValue={setWeapon} value={weapon} elementName="weapon" selectOptions={WEAPON_CHOICES} placeholder="" labelText="Weapon"/>
          {validations && !weapon && <p className="testerror">Please enter weapon</p>}
        </div>
        <div className="Please-error">
          <FormSelectElement setValue={setEventLevel} value={eventLevel} elementName="event-level" selectOptions={EVENT_CHOICES} placeholder="" labelText="Event Level" />
          {validations && !eventLevel && <p className="testerror">Please enter level</p>}
        </div>
        <div className="Please-error">
          <FormTextElement setValue={setNotes} value={notes} elementName="host" placeholder="" labelText="Notes" />
        </div>

        <button className="submit-button addevent" type="submit">
          {props.btnValue ? props.btnValue : 'Event'}
        </button>
      </form>
    </>
  );
};
