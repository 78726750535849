import axios from "axios";
import { setAuth } from "../redux/authSlice";
import { useDispatch } from "react-redux";
import { getCSRFToken } from "../hooks/CSRFToken";

// axios.defaults.baseURL = "http://122.160.74.251:8029/";
// axios.defaults.baseURL = "https://finalstrip.com/";
axios.defaults.baseURL = "http://localhost:8000/";
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

let refresh = false;
let refreshTokenPromise: Promise<string | void> | null = null;



export const getRefreshToken = (): Promise<string | void> => {
  
  // making use csrf is correct
  function getToken(name: string) {
    var cookieValue = "cookie not found";
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          console.log('got csrf: ', cookieValue)
          break;
        }
      }
    }
    return cookieValue;
  }
  axios.defaults.headers.common['X-CSRFToken'] = getToken('csrftoken');
  
  // refreshing access token
  return axios
    .post("api/auth/refresh", { withCredentials: true })
    .then((response) => {
      console.log('new access token from refresh: ', response.data.token)
      const token = response.data.token;
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    });
};


axios.interceptors.response.use(
  (resp) => {
    console.log('response data:', resp.data)
    return resp
  },
  (error) => {
    console.log("refresh: ", refresh);
    if (
      (error.response.status === 401 && !refresh) ||
      (error.response.status === 500 && !refresh)
    ) {
      refresh = true;
      console.log("intercepting...");

      if (!refreshTokenPromise) {
        // if nothing is in-progress, start a new refresh token request
        console.log('getting new token')
        refreshTokenPromise = getRefreshToken().then((token) => {
          refreshTokenPromise = null; // clear state
          return token; // resolve with the new token
        }).then(()=>{
          return axios.request(error.config)
        })
      }

    } else if (error.response.status){
      refresh = true
    } else {
      refresh = false;
    }
    
    refreshTokenPromise = null;
    return Promise.reject(error);
  }
);


// Old method that did not allow for multiple requests at the same time because of timing of auth tokens refreshing
// let refresh = false

// axios.interceptors.response.use(
//   resp => resp, 
//   async error => {

//     if ((error.response.status === 401 && !refresh) || (error.response.status === 500 && !refresh)) {
//         refresh = true;
//         const response = await axios.post('api/auth/refresh')
//         if (response.status === 200) {
//             axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`
//             console.log('interceptor authenticated')
//             return axios(error.config);
//         }else{
//             console.log("interceptor failed")
//         }
//     }
//     refresh = false;
//     return error;
// });







// logs out request before sending it to server
axios.interceptors.request.use(
  function (config) {
    
    // Logg out request for testing purposes
    console.log("request to django: ", config);

    // const dispatch = useDispatch()
    // dispatch({type: '', auth: true})
    // console.log('authenticated')

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
