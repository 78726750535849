import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './interceptors/axios';
import {store} from './redux/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <div>
    {/* <div style={{display:'none'}}> */}
    <div>
      <Provider store={store}>
          <App />
      </Provider>
    </div>
    
    {/* <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#333',
        padding: '50px 0',
        color: '#db6804',
      }}
    >
      <div
        style={{
          fontSize: '10em',
          fontFamily: 'Arial, sans-serif',
          textShadow: '6px 6px 6px #000',
          fontWeight: 'bold',
          letterSpacing: '0.1em',

          marginBottom: '20px',
        }}
      >
        FinalStrip
      </div>
      <div style={{ 
        fontSize: '3em',
        fontFamily: 'Arial, sans-serif',
          textShadow: '3px 3px 3px #000',
          fontWeight: 'bold',
        }}>Coming Soon...</div>
      </div> */}
  </div>
    
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
