import React, { SyntheticEvent, useState, useRef, useEffect } from 'react'
import axios from "axios";
import { FormTextElement } from "../../components/forms/form elements/FormTextElement";
import { getNewRefreshToken } from "../../hooks/utils";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setAuth } from "../../redux/authSlice";
import swal from 'sweetalert';

interface Item {
    id: number;
    tag_name: string;
}

export const CreateLesson = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [totalTags, settotalTags] = useState<string[]>([]);
    const [inputError, setInputerror] = useState<boolean>(false);
    const [searchList, setsearchList] = useState<Item[]>([]);
    const [validations, setValidations] = useState<boolean>(false);

    useEffect(() => {
        var myData = localStorage.getItem("userData")
        if (myData) {
            dispatch(setAuth(true));
        }
    }, []);

    const handleBoutTagss = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputerror(false)
        const query = e.target.value;
        if (query !== '') {
            try {
                await getNewRefreshToken();
                const response = await axios.get(`api/bouts/boutname/?search=${query}`, {
                    withCredentials: true,
                });
                setsearchList(response.data)
            } catch (error) {
                console.error('POST API Error:', error);
            }
        }
        if (query === '') {
            setsearchList([])
        }
    }


    const handleDivClick = (e: SyntheticEvent) => {
        e.preventDefault();
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    const handleDel = (i: number, e: SyntheticEvent) => {
        e.preventDefault();
        const filBout = totalTags.filter((item, index) => index !== i)
        settotalTags(filBout)
    }
    const handleSelectValue = (value: string) => {
        setInputerror(false)
        if (!totalTags.includes(value)) {
            settotalTags(prevArray => [...prevArray, value]);
        }
        else {
            setInputerror(true)
        }
    }
    const handleSubmit = async () => {
        if (description) {
            setValidations(false)
            try {
                await getNewRefreshToken();
                const response = await axios.post(`api/lessons/create/`, {
                    title: title,
                    description: description,
                    tag_name: totalTags,
                },
                    {
                        withCredentials: true,
                    });
                swal({
                    title: 'Success',
                    timer: 3000,
                    text: 'Lesson created',
                    icon: 'success',
                })
                navigate('/journal/lessons')
            } catch (error) {
                swal({
                    title: 'warning',
                    icon: "warning",
                    text: 'Error',
                    timer: 3000
                })
            }
        }
        else {
            setValidations(true)
        }
        console.log('create lesson')
    }


    return (
        <div className='container'>
            <div style={{ position: 'relative' }}>
                <Link className='backBtn backbtn-lesson' to='/journal/lessons'>Back</Link>
            </div>
            <h2 className="headingerofpage subscr-heading " style={{fontSize:'34px'}}>Add New Lesson</h2>
            <form className="fencerform ">
   
                <FormTextElement setValue={setTitle} value={title} elementName="title" placeholder="" labelText="Title" />
              
                <div className="Please-error">
                    <div className='form-input'>
                        <textarea
                            className='lesson-textarea'
                            id='description'
                            name='description'
                            value={description}
                            placeholder={''}
                            onChange={e => setDescription(e.target.value)}
                        />
                        <label htmlFor='description'>Description</label>
                    </div>
                    {validations && !description && (
                        <p className="testerror" style={{ bottom: '2px' }}>Description required</p>
                    )}
                </div>
                
                {/* Tag elements to add back in later */}
                {/* <div className='form-input'>
                    <label htmlFor='nameInput'>Lesson Tags</label>
                    
                    <div onClick={handleDivClick} className='input-div12'>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {totalTags.map((item, index) => (
                                <span className="bout-tagg-span" key={index}>{item} <CloseIcon className='icon-212' onClick={(e) => handleDel(index, e)} /></span>
                            ))}
                        </div>
                        <div style={{ position: 'relative' }}>
                            <input
                                type='text'
                                list="item-options"
                                id="item-options"
                                ref={inputRef}
                                onChange={handleBoutTagss}
                            />
                            {searchList.length === 0 ? <div className="show-list1222" style={{ display: 'none' }}></div>
                                : <div className="show-list1222">
                                    {searchList.map((item, i) => (
                                        <p onClick={() => handleSelectValue(item.tag_name)}>{item.tag_name}</p>
                                    ))}
                                </div>}

                        </div>
                        {inputError && <span style={{ color: "red" }}>Already exits</span>}
                    </div>

                    {validations && totalTags.length===0 && (
                        <p style={{bottom:'-14px'}}className="testerror">Tags required</p>
                    )}
                </div> */}

                {/* moved the toggle here to its own form element */}

                <button
                    className="submit-button Fencerbtn lesson-btnMargg"
                    type="button"
                    onClick={() => handleSubmit()}
                >
                    Add Lesson
                </button>
            </form>
        </div>
    )
}

