import { useEffect, useState } from "react";
import axios from "axios";
import { TournamentForm } from "../../components/forms/TournamentForm";
import { tournamentType } from "../../constants/VarTypes";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../redux/authSlice";
import swal from "sweetalert";
import { getNewRefreshToken } from "../../hooks/utils";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';


// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import Rating from "@mui/material/Rating";
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { CSRF } from "../../components/forms/form elements/CSRF";

export const Tournaments = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUrl = location.pathname;
  const navigate = useNavigate();
  const [dataReceived, setDataReceived] = useState<boolean>(false);
  const [loadingTourneys, setLoadingTourneys] = useState<boolean>(true);
  const [tournaments, setTournaments] = useState<[tournamentType] | []>([]);
  const [previousPage, setPreviousPage] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<string>("api/tournaments/all");
  const [nextPage, setNextPage] = useState<string>("");
  const [shwoTour, setShowTour] = useState<boolean>(false);
  const [slug, setSlug] = useState<string>("");
  const [authh,setAuthh] =  useState<boolean>(false);
  const [open, setOpen] = useState(false);
  
  const auth = useSelector((state: RootState) => state.auth.value);

  useEffect(() => {
    window.scroll(0, 0);
    var myData = localStorage.getItem("userData");
    console.log("journal", myData);
    if (myData) {
      dispatch(setAuth(true));
      setAuthh(true)
    }
  }, []);
  // console.log("tournaments-------------------------", tournaments);

  // -----------------------------form-----------------------------

  async function getData(data_url: string) {
    setLoadingTourneys(true);
    // gets data from API
    // not sure why dataReceived prevents infinite loop
    if (dataReceived === false) {
      await getNewRefreshToken();
      try {
        const result = await axios.get(data_url, { withCredentials: true });
        // Process the response data

        setTournaments(result.data.results);
        setDataReceived(true);
        setPreviousPage(result.data.previous);
        setNextPage(result.data.next);
      } catch (error) {
        console.error("Error:", error);
      }

    }
    setLoadingTourneys(false);
  }
  useEffect(() => {
    getData(currentPage);
  }, [currentPage, dataReceived]);

  // pagination functions
  function goToNextPage() {
    // console.log("next");
    setCurrentPage(nextPage);
    setDataReceived(false);
    getData(currentPage);
    window.scrollTo(0, 0);
  }

  function goToPreviousPage() {
    // console.log("previous");
    setCurrentPage(previousPage);
    setDataReceived(false);
    getData(currentPage);
    window.scrollTo(0, 0);
  }

  function handleClickOpen(
    event: React.MouseEvent<HTMLButtonElement>,
    slug: string
  ) {
    setSlug(slug);
    setOpen(true);
    setShowTour(false);
  }

  const handleDeleteCkick = async (
    event: React.MouseEvent<HTMLButtonElement>,
    slug: string
  ) => {
    event.preventDefault();
    if (auth) {
      try {
        const data = await axios.delete(`api/tournaments/delete/${slug}/`, {
          withCredentials: true,
        });
        // console.log(data, "freergegeg");
        setDataReceived(false);
        // use a type assertion to tell TypeScript that data is of type { token: string, status: number

        //this is duplicated in login.tsx and should be cleaned up at some point

        // I don't remember why this is here
        if (data == undefined) {
          return;
        }
      } catch (error: any) {
        swal({
          title: "Error",
          text: error.response?.data?.detail,
          icon: "warning",
          dangerMode: true,
        });
      }
    } else {
      navigate("/login");
    }
  };
  //  else {
  //   // setValidations(true);
  // }

 

  const handleClose = () => {
    setOpen(false);
    setSlug("");
  };
  const handleAddBtn = () =>{
    if(auth){
      setShowTour(true);
    }
    else{
      navigate('/login')
    }  
  }
  return (
    <>
      <div className="commonContainer">
        {/* {loadingTourneys ? (
          <>Loading Tournaments...</>
        ) : ( */}
        <>
          <div className="backtohome">
            <div className="Tournament-btn">
              {shwoTour ? (
                <div className="container-text tour">
                  <TournamentForm
                    setShowTour={setShowTour}
                    slugProp={slug}
                    setOpen={setOpen}
                    setSlug={setSlug}
                    setDataReceived={setDataReceived}
                    btnValue={'Add Tournament'}
                  />
                  <div className="backbtntour">
                  <CloseIcon style={{ fontSize: '25px' }} 
                             className="crossIcon icon-pointer" 
                             onClick={() => setShowTour(false)} 
                             />
                  </div>
                </div>
              ) : (
                <div className="addnewtournamnet">
                  <button
                    onClick={handleAddBtn}
                    className="submitbtn black btn-position"
                  >
                    Add New Tournament
                  </button>
                    <Link className="backtohomelink" to="/">
                      Back
                    </Link>
                </div>
              )}
            </div>
          </div>
          {/* <div className="back-div">
            <div className="connectFromHome">
              <h2><b>Proven and Trusted: A Legacy of Excellence</b></h2>
              <p className="text-14">Since XXXX, Finalstrip has established itself as a trusted partner and invaluable resource for elite sports teams around the world. Our track record speaks for itself, as we have consistently delivered exceptional value and support to athletes and organizations within the fencing community.</p>
              <Link to={"/contactus"} className="linktoconnect">
              </Link>
            </div>
          </div> */}

          {authh ? 
             loadingTourneys ? (
              <div className="container">
              <h1>Loading ... </h1>
            </div>
             ) : (
               <>
                 {tournaments.length <= 0 && (
                   <div className="container">
                     <h1 className="tourtext">You do not have any tournaments</h1>
                   </div>
                 )}
   
                 <div className="card-category-1">
                   {Object.entries(tournaments).map(([i, value]) => (
                     <div key={i} className="basic-card basic-card-aqua">
                       <div className="card-content" onClick={()=>navigate(`/journal/events/${value.slug}`)}>
                         <span className="card-title"></span>
                         <p className="card-text">
                           <h2>                         
                             {value.name}
                           </h2>
                           <p>
                             <b>Date: </b>
                             {value.date}
                           </p>
                           <p>
                             <b>Location: </b>
                             {value.location}
                             
                           </p>
                         </p>
                       </div>
   
                       <div className="card-link action-btn">
                         <div className="action-btns">
                           <a href="#" title="Delete">
                             <button
                               className="delete-btn"
                               onClick={(event) =>
                                 handleDeleteCkick(event, value.slug)
                               }
                             >
                               <DeleteIcon style={{ color: 'white', fontSize: '20px' }} />
                             </button>
                           </a>
                         </div>
                         <div className="action-btns">
                           <a href="#" title="Edit">
                             <button
                               className="edit-btn"
                               onClick={(event) =>
                                 handleClickOpen(event, value.slug)
                               }
                             >
                               <EditIcon style={{ color: 'white', fontSize: '20px' }} />
                             </button>
                           </a>
                         </div>
                       </div>
                     </div>
                   ))}
                 </div>
   
   
                 <div className="pagination-buttons">
                   <button
                     className={previousPage ? "previous-button" : "hidden"}
                     onClick={() => goToPreviousPage()}
                   >
                     Previous
                   </button>
                   <button
                     className={nextPage ? "next-button" : "hidden"}
                     onClick={() => goToNextPage()}
                   >
                     Next
                   </button>
                 </div>
               </>
             )
          
          : null
         }
          
        

          <Dialog
            className="profileImgDialognew"
            open={open}
            onClose={handleClose}
          >
            <h1 className="h1 Tournament-title-t">
              Edit Tournament{" "}
              <span className="crossTagsDiv-1">
                {" "}
                <CloseIcon onClick={handleClose} className="icon-pointer" style={{ fontSize: '25px' }} />
              </span>
            </h1>

            <DialogContent className="image_and_namesinvite">
              <div>
                <TournamentForm
                  setShowTour={setShowTour}
                  slugProp={slug}
                  setOpen={setOpen}
                  setSlug={setSlug}
                  setDataReceived={setDataReceived}
                  btnValue={'Update '}
                />
              </div>
            </DialogContent>
          </Dialog>
        </>
        {/* )} */}
      </div>
    </>
  );
};
