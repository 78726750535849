import axios from "axios";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import swal from "sweetalert";
import { setAuth } from "../../redux/authSlice";
import { RootState } from "../../redux/store";
import { getRefreshToken } from "../../interceptors/axios";
import { PushNote } from "../../components/PushNote";
import { getNewRefreshToken } from "../../hooks/utils";

interface Membership {
  id: number;
  membership_type: string;
  price: number;
  created_at: string;
  updated_at: string;
}

// This should be deleted at some point


export const Subscription_payment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [memberships, setMemberships] = useState<Membership[]>([]);
  // const [userid, setUserid] = useState<number>(0);
  const [login, setLogin] = useState<boolean>(false);
  const [subscriptionCount, setSubscriptionCount] = useState<number>(0);
  useEffect(() => {
    // Fetch the price from the backend API
    axios
      .get("/api/subscription/api/memberships/")
      .then((response) => {
        setMemberships(response?.data);
        console.log('subscription...', response?.data)
      })
      .catch((error) => {
        console.log(error);
      });
    const data = localStorage.getItem('userData');
    if (data) {
      const id = JSON.parse(data);
      if (id.subscription === 'Premium') {
        setSubscriptionCount(3)
      }
      else if (id.subscription === 'Advanced') {
        setSubscriptionCount(2)
      }
      else {
        setSubscriptionCount(1)
      }
    }
  }, []);

  useEffect(() => {
    var myData = localStorage.getItem("userData");
    if (myData) {
      dispatch(setAuth(true));
    }
    var log = localStorage.getItem("login");
    if (log === "true") {
      setLogin(true)
    }
  }, []);

  // const handleButtonClick = async (dataId: number) => {
  //   if (login === false) {
  //     navigate('/login')
  //   }
  //   await getNewRefreshToken();
  //   try {
  //     // Make API request
  //     const { data } = await axios.post("/api/subscription/checkout_session/", {
  //       user_id: userid,
  //       package_type: dataId,

  //     }, { withCredentials: true });
  //     // Redirect to API URL
  //     window.location.href = data?.sessionId?.url;
  //   } catch (error) {
  //     // Handle error
  //     swal("An error occurred");
  //   }
  // };
  const handleButtonClick = async (num: number) => {
    navigate(`/subscriptionDetail/${num}`)
  }

  useEffect(() => {
    getRefreshToken();
  }, []);
  return (
    <>{login ?<> <h2 className="headingerofpage subscr-heading ">Subscription</h2>
    <div className="container">
      <div className="subscriptionDataflex">
        {memberships?.map((member) => (
          <div className="subscriptionpage" key={member.id}>
            <h1>Buy</h1>
            <p>{member?.membership_type}</p>
            <p>Price: $ {member?.price}</p>

            {subscriptionCount === 1 && (member.price === 0 ? <button className="opacity" disabled>Activated</button>
              : <button onClick={() => handleButtonClick(member.id)}>Pay now</button>)}

            {subscriptionCount === 2 && (
              member.price === 0 ? <button className="opacity" disabled>Upgraded</button>
                : member.price === 200 ? <button className="opacity" disabled>Activated</button>
                  : <button onClick={() => handleButtonClick(member.id)}>Pay Now</button>
            )}

            {subscriptionCount === 3 && (
              member.price < 500 ? <button className="opacity" disabled>Upgraded</button>
                : <button className="opacity" onClick={() => handleButtonClick(member.id)} disabled>Activated</button>
            )}
            <h2>Subscription</h2>
          </div>
        ))}
      </div>
    </div></>
      : <>
        <h2 className="headingerofpage subscr-heading ">Subscription</h2>
        <div className="container subscriptionDataflex">
          {memberships?.map((member, index) => (
            index > 0 && (
              <div className="subscriptionpage" key={member.id}>
                <h1>Buy</h1>
                <p>{member?.membership_type}</p>
                <p>Price: $ {member?.price}</p>
                <button onClick={() => handleButtonClick(member.id)}>
                  Pay Now
                </button>
                <h2>Subscription</h2>
              </div>
            )
          ))}
        </div>
      </>}
    </>
  );
};