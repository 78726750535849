import React, { useState } from 'react'
import { ProfileSidebar } from './ProfileSidebar'
import { Outlet } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';

export const Layout: React.FC = () => {
   const [open,setOpen] = useState<boolean>(false);
   console.log(open)
   const handleclick = () =>{
     setOpen(!open)
   }
  return (
    <>
      <div className='profile-layout'>
        <MenuIcon className={open ? 'menu-bar menu-bar-color' :'menu-bar '} onClick={handleclick}/>
        <div className={open ? 'profile-page-1-display' :'profile-page-1'} >
          <ProfileSidebar setOpen={setOpen} open={open}/>
        </div>
        <div className='profile-page-2'>
          <Outlet/>
        </div>
      </div>
    </>
  )
}
