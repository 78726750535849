import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { FencerView } from "./FencerView";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from '../../redux/authSlice'
import { getNewRefreshToken } from "../../hooks/utils";
import swal from 'sweetalert';
interface Fencer {
  slug: string;
  first_name: string;
  last_name: string;
  // Add other properties as needed
}

export const Fencers = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [fencerList, setFencerList] = useState<Fencer[]>([]);
  const [authh, setAuthh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const auth = useSelector((state: RootState) => state.auth.value);

  useEffect(() => {
    window.scroll(0, 0);
    var myData = localStorage.getItem('userData');
    // console.log("journal", myData)
    if (myData) {
      dispatch(setAuth(true))
      setAuthh(true)
    }
  }, [])

  const handleDelete = async (slug: string) => {
    if (auth) {
      try{
        const dataDelete = await axios.delete(`api/fencers/delete/${slug}/`, {
          withCredentials: true,
        });
        const result = await axios.get("/api/fencers/all/", {
          withCredentials: true,
        });
        setFencerList(result.data);
      } catch (error: any) {
        swal({
          title: "Error",
          text: error.response?.data?.detail,
          icon: "warning",
          dangerMode: true,
        })
      }
      
    } else {
      navigate("/login");
    }
    // Add your logic here to handle the delete operation
  };


  const updatevalue = async () => {
    const fetchData = async () => {
      await getNewRefreshToken();
      try {
        const result = await axios.get("/api/fencers/all/", {
          withCredentials: true,
        });
        setFencerList(result.data);
      } catch (error) {
        console.error("Error fetching fencers:", error);
      }
    };

    fetchData();
  };

  useEffect(() => {
    const fetchData = async () => {
      await getNewRefreshToken();
      try {
        const result = await axios.get("/api/fencers/all/", {
          withCredentials: true,
        });
        setFencerList(result.data);
        setLoading(false)
      } catch (error) {
        console.error("Error fetching fencers:", error);
      }
    };  
    fetchData();
  }, []);

  const handleCreate = () => {
    if (authh) {
      navigate('/journal/fencer/create')
    }
    else {
      navigate('/login')
    }
  }


  return (
    <>
      <div className="commonContainer">

        <div className="fencerView">
          <div className="fencer-create-button">
            <button type="button" className="submitbtn fencercreate black" onClick={handleCreate}>Create Fencer</button>
          </div>
          {/* <h2 className="headingerofpage fencerHeading-marginTop">Fencers</h2> */}
          {/* {auth ? fencerList ?
            <FencerView
              fencerList={fencerList}
              onDelete={handleDelete}
              onupdate={updatevalue}
            /> : <div className="container">
              <h1>Loading ... </h1>
            </div> : null} */}
          {authh ? loading ?
            <div className="container">
              <h1>Loading ... </h1>
            </div>
            : <FencerView
              fencerList={fencerList}
              onDelete={handleDelete}
              onupdate={updatevalue} />
            : <div className="container">
              <h3>Please login to access the data</h3>
            </div>}
        </div>
      </div>
    </>
  );
};
