import React, { useEffect, SyntheticEvent, useState, useRef } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getNewRefreshToken } from "../../hooks/utils";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setAuth } from "../../redux/authSlice";
import { Dialog, DialogContent } from "@material-ui/core";
import { FormTextElement } from "../../components/forms/form elements/FormTextElement";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from 'sweetalert';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface User {
    title: string;
    description: string;
    lesson_date: string;
}

export const LessonView = () => {
    const { slug } = useParams()
    const dispatch = useDispatch();
    const [lesson, setLesson] = useState<User>({
        title: '',
        description: '',
        lesson_date: ''
    });

    const getData = async () => {
        await getNewRefreshToken();
        const response = await axios.get(`api/lessons/lessons-self/${slug}/`, {
            withCredentials: true,
        });
        setLesson(response.data)
    }

    useEffect(() => {
        window.scroll(0, 0);
        var myData = localStorage.getItem('userData');
        if (myData) {
            dispatch(setAuth(true));
        }
        getData();
    }, [])
  

    return(
        <>
            <Link className='backBtn backbtn-lessonview' to='/journal/lessons'>Back</Link>
            <h2 className="headingerofpage">Lesson Detail</h2>
            <div className='lessonview-div'>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h3 style={{ textTransform: 'capitalize' }} className='lessonlist-heading'>{lesson.title}</h3>
                    <div style={{
                        display: 'flex' }}>
                        <p style={{ display: 'flex' }}>{lesson.lesson_date}</p>
                    </div>
                   
                </div>
           
                {lesson.description.split('\n').map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </div>
        </>
    )
}