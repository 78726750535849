import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from '../../redux/authSlice'
import { getNewRefreshToken } from "../../hooks/utils";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Bout_Type_Options } from "../../constants/VarOptions";

interface Fencer {
  slug: string;
  first_name: string;
  last_name: string;
  fencer_a: string;
  fencer_b: string;
  score_a: number;
  winner_is_a: boolean;
  score_b: number;
  notes: string;
  // Add other properties as needed
}
export const FencerBouts = () => {
  const dispatch = useDispatch()
  const { boutsId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [dataReceived, setDataReceived] = useState(false);
  const [fencerList, setFencerList] = useState<Fencer[]>([]);
  const [fencerNumber, setfencerNumber] = useState<number>(0)

  const [firstName, setfirstName] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [hand, setHand] = useState<string>("");
  const [age, setAge] = useState([]);
  const [club1, setClub1] = useState<string>("");
  const [club2, setClub2] = useState<string>("");
  const [school, setSchool] = useState<string>("");
  const [division, setDivision] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [nationality, setNationality] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [ratingEpee, setRatingEpee] = useState<string>("");
  const [ratingFoil, setRatingFoil] = useState<string>("");
  const [ratingSabre, setRatingSabre] = useState<string>("");

  const [bladeWork, setBladeWork] = useState<number>(0)
  const [distance, setDistance] = useState<number>(0)
  const [energy, setEnergy] = useState<number>(0)
  const [technique, setTechnique] = useState<number>(0)
  const [indicator, setIndicator] = useState<string>("");
  const [total, setTotal] = useState<number>(0)
  const [wins, setWins] = useState<number>(0)
  const [losses, setLosses] = useState<number>(0)
  const [score, setScore] = useState<number>(0)
  const [againt, setAgainst] = useState<number>(0)
  const [winLoss, setWinLoss] = useState<number>(0)

  const [boutType, setBoutType] = useState<string>('');

  // console.log("fencerList----show----", fencerList)
  const auth = useSelector((state: RootState) => state.auth.value);

  const fetchData = async () => {
    try {
      // const result = await axios.get(`/api/bouts/all/${boutsId}/`, {
      //   withCredentials: true,
      // });
      const result = await axios.get(`api/fencers/details/${boutsId}/?type=${boutType}`, {
        withCredentials: true,
      });
      console.log('result and data  ', result.data)
      setfencerNumber(result.data.usa_fencing_info.member_id)
      setFencerList(result.data.bouts);
      setfirstName(result.data.first_name)
      setlastName(result.data.last_name)
      setHand(result.data.handed)
      setAge(result.data.my_age_stats)
      setRatingEpee(result.data.usaf_rating_epee)
      setRatingFoil(result.data.usaf_rating_foil)
      setRatingSabre(result.data.usaf_rating_sabre)
      setClub1(result.data.club)
      setClub2(result.data.club2)
      setSchool(result.data.school)
      setDivision(result.data.division)
      setRegion(result.data.region)
      setNationality(result.data.nationality)
      setNotes(result.data.notes)
      setBladeWork(result.data.average_bladework)
      setDistance(result.data.average_distance)
      setEnergy(result.data.average_energy)
      setTechnique(result.data.average_technique)
      setIndicator(result.data.my_bout_stats.indicator)
      setTotal(result.data.my_bout_stats.total)
      setWins(result.data.my_bout_stats.wins)
      setLosses(result.data.my_bout_stats.losses)
      setScore(result.data.my_bout_stats.scored)
      setAgainst(result.data.my_bout_stats.against)
      setWinLoss(result.data.my_bout_stats.win_loss)
    } catch (error) {
      // Handle any errors that occur during the request
      console.log("error", error)
    }
  };

  useEffect(() => {
    console.log('effect 1')
    fetchData();
  }, []);

  useEffect(() => {
    console.log('effect 2')
    var myData = localStorage.getItem('userData');
    // console.log("journal", myData)
    if (myData) {
      dispatch(setAuth(true))
    }
  }, [])

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setBoutType(value)
  };

  const handleSearch = (e: React.SyntheticEvent) => {
    e.preventDefault()
    fetchData();
  }


  return (
    <>
      <div className="showFencerBoutsListHello">
        <div className="fencer-detail" style={{paddingBottom: '16px'}}>
          <h1 className="font-family" style={{marginTop: '10px'}}>{firstName} {lastName}</h1>
          <div className="showFencerBouts-2" style={{position: 'absolute', right: '40px'}}>
            <Link className="backtohomelink " to="/journal/fencers">
              Back
            </Link>
          </div>
        </div>
        <br />
        <div className="showFencer" style={{flexDirection: 'column', margin: 'auto'}}>

          <br />
          <div className="showFencerBouts-1">
            <div className="fencer-detail-flex" >
              {/* <p>USAF Number : {fencerNumber}</p> */}
              <table>
                <tr><td>Club: </td><td>{club1} {club2 ? ' / ' + club2 : ''}</td></tr>
                {school ? <tr><td>School: </td><td>{school}</td></tr> : ''}
                <tr><td>Division: </td><td>{division}</td></tr>
                <tr><td>Region: </td><td>{region}</td></tr>
                <tr><td>Nationality: </td><td>{nationality}</td></tr>
                <tr><td>Age Group: </td><td>{age.toString()}</td></tr>
                <tr><td>Hand: </td><td>{hand}</td></tr>
              </table>
              <br />
              <table>
              <tr><td style={{width: '50px'}}>Epee: </td><td style={{width: '50px'}}>{ratingEpee} </td><td style={{width: '50px'}}>Sabre: </td><td style={{width: '50px'}}>{ratingSabre}</td><td style={{width: '50px'}}>Foil: </td><td>{ratingFoil} </td></tr>
                <tr></tr>
                <tr></tr>
                </table>
            </div>
          </div>

          <div className="showFencerBouts-1">
            <div>
              <div className="fencer-detail">
                <div className="fencer-detail-flex">
                
                
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="fencer-detail" style={{flexDirection: 'column'}}>
          
          <h3 style={{margin: 'auto', marginBottom: '12px'}}>How I Do</h3>
          
          <div className="fencer-detail-flex">
            <table>
              <tr><td style={{width: '90px'}}>Win/Loss:</td><td>{winLoss}</td>  <td style={{width: '110px'}}>Indicator:</td><td>{indicator}</td></tr>
              <tr><td style={{width: '90px'}}>Total Wins:</td><td>{wins}</td>  <td style={{width: '110px'}}>Touches Scored:</td><td>{"   "}{score}</td></tr>
              <tr><td style={{width: '90px'}}>Total Losses:</td><td>{losses}</td>  <td style={{width: '110px'}}>Touches Against:</td><td>{"   "}{againt}</td></tr>
            </table>
          </div>
          <br />
          <h3 style={{margin: 'auto', marginBottom: '12px'}}>How They Do</h3>

          <div className="fencer-detail-flex" style={{margin: 'auto'}}>
            <p>Bladework: {bladeWork}</p>
            <p>Distance: {distance}</p>
            <p>Energy: {energy}</p>
            <p>Technique: {technique}</p>
            <p>Notes: {notes}</p>
          </div>
        </div>

        <div className="bout-typelist">
          <label>Bout Format</label>
          <select
            id='eventLevelInput'
            className=""
            onChange={handleSelectChange}
            value={boutType}
            name='bout-type'
          >
            <option value={''}>All</option>
            <option value='Pool'>POOL</option>
            <option value='DE'>DE</option>
            {/* <option value='DE - Vet'>DE VET</option> */}
            {/* {Object.entries(Bout_Type_Options).map(([key, value]) => (
              <option key={key} value={key}>{value}</option>
            ))} */}
          </select>
          <button className="flex-btnn" onClick={handleSearch}>Sort</button>
        </div>

        <h2 className="fener-heading" style={{display: 'flex', justifyContent: 'center' }}>Bouts</h2>

        <div className="showFencerBoutsList">
          {fencerList.map((fencer, index) => (

            <div key={index} className="container">
              {/* {JSON.stringify(value)} */}

              {/* <a href={"/journal/bout/" + value.slug}> */}
              {/* <h1 onClick={() => handlebreadcrumbs(fencer.slug)}>
                {fencer.slug}
              </h1> */}
              <h2 onClick={() => navigate(`/journal/bout/${fencer.slug}`)}>
                {fencer.fencer_a} <br/>
                vs  <br/>
                {fencer.fencer_b}
              </h2>

              <p style={{ display: 'flex', justifyContent: 'center' }} onClick={() => navigate(`/journal/bout/${fencer.slug}`)}>
                {fencer.score_a}{" "}
                {fencer.winner_is_a && (
                  <>
                    <EmojiEventsIcon style={{ color: 'white', fontSize: '18px', paddingTop: '2px' }} />
                  </>
                )}{" "}
                - {fencer.score_b}{" "}
                {!fencer.winner_is_a && (
                  <>
                    <EmojiEventsIcon style={{ color: 'white', fontSize: '18px', paddingTop: '2px' }} />
                  </>
                )}
              </p>
              {/* </a> */}
              <hr className="boder-new" />
            </div>

          ))}

          {fencerList.length < 1 && (
            <div className="container">
              {/* {JSON.stringify(value)} */}

              {/* <a href={"/journal/bout/" + value.slug}> */}
              <h2 >
                This Fencer does not have any bout
              </h2>
              <hr className="boder-new" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
