import { ChangeEvent, useEffect, useRef, useState, SyntheticEvent } from "react";
import axios from 'axios';
import swal from "sweetalert";

type searchListTypes = {
  name: string,
  club: string,
  usafid: string
}

export function SearchUSAFencing() {
  const searchRef = useRef<HTMLInputElement>(null);
  const fencerIdRef = useRef<HTMLInputElement>(null);
  const [fencerNames, setFencerNames] = useState<searchListTypes[]>([
    { name: 'Doe, John', club: 'unattached', usafid: '999999999' }
  ]);
  const [searchState, setSearchState] = useState<{
    searchWord: string
    searchList: searchListTypes[]
  }>({
    searchWord: '',
    searchList: []
  });
  const [loadingFencers, setLoadingFencers] = useState<Boolean>(false)



  function handleChange() {
    setLoadingFencers(true)

    const targetValue = searchRef.current?.value ? searchRef.current?.value : '';

    if (targetValue.length === 0) {
      setSearchState({
        searchWord: targetValue,
        searchList: []
      });
    setLoadingFencers(false)
      return;
    }

    if (targetValue.length >= 2) {
      axios.get('api/usaf-data/search-members/' + targetValue, { withCredentials: true })
      .then(result => {
        const data: searchListTypes[] = result.data; 
        setFencerNames(data);
    
        const filteredNames = data.filter((fencer: searchListTypes) => { 
          const removePunctuation = targetValue.replaceAll(',', ' ').replaceAll('.', '');
          const searchTerms = removePunctuation.toLowerCase().split(' ');
          setLoadingFencers(false)
          return searchTerms.every(searchTerm => fencer.name.toLowerCase().includes(searchTerm));
        });
    
        setSearchState({
          searchWord: targetValue,
          searchList: filteredNames
        });
    
        if (filteredNames.length === 1 && fencerIdRef.current) {
          fencerIdRef.current.value = filteredNames[0].usafid;
        }
      })
      .catch(error => {
        console.error(error);
        alert('Unable to search for opponent');
      });
    setLoadingFencers(false)
    } else {
      setSearchState({
        searchWord: targetValue,
        searchList: []
      });
    setLoadingFencers(false)
    }
  }

  function selectFencer(name: string, id: string) {
    if (name !== null && searchRef.current && fencerIdRef.current) {
      searchRef.current.value = name;
      fencerIdRef.current.value = id;
      setSearchState({
        searchWord: "",
        searchList: []
      });
      // handleChange();
    }
  }

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!fencerIdRef.current) {
      alert("Error: Please select a name from the list");
      return;
    }
    try {
      await axios.post('/api/fencers/create/', {
        member_id: fencerIdRef.current?.value
      }, { withCredentials: true }).then((res) => {
        swal({
          title: "Success",
          text: res?.data?.message,
          icon: "success",
          timer: 1000,
          // buttons: "",
        });
      })
      console.log('fencer created with USAFID');

    } catch (error) {
      console.error(error);
      alert('Error occurred while adding fencer');
    }
  }

  let searchItems;
  if (searchState.searchList.length === 0) {
    searchItems = <div>Fencer not found</div>;
  } else {
    searchItems = searchState.searchWord === '' ? "" : searchState.searchList.slice(0, 10).map(fencer => (
      <li key={fencer.usafid}>
        <div onClick={() => selectFencer(fencer.name, fencer.usafid)}>
          {fencer.name} - {fencer.club}
        </div>
      </li>
    ));
  }

  return (
    <>
      <form onSubmit={submit}>
        <label htmlFor="searchUSAFencing">Search USA Fencing:</label>
        <br />
        <input id="searchUSAFencing" type="search" ref={searchRef} className="search-bar" onChange={handleChange} />
        {/* <input id="searchUSAFencing" type="search" ref={searchRef} className="search-bar" value={searchState.searchWord} onChange={handleChange} /> */}
        <input id="searchUSAFencingId" type="text" ref={fencerIdRef} className="hidden" />

        {loadingFencers && (
          <div className='loadingFencers'>Loading Fencers...</div>
        )}

        <ul className={searchState.searchWord === '' ? "hidden" : "search-options"}>
          {searchItems}
        </ul>

        <button className='submit-button' type='submit'>Add New Fencer</button>
      </form>
    </>
  );
}
