import React, { SyntheticEvent, useState } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Password';
import EmailIcon from '@mui/icons-material/Email';

import { registerDataType } from "../../constants/VarTypes";



export const RegisterFirstPage = ({
    setPageNumber,
    setRegisterData,
    registerData
}:{
    setPageNumber: React.Dispatch<React.SetStateAction<number>>,
    setRegisterData: React.Dispatch<React.SetStateAction<any>>,
    registerData: registerDataType
}) => {
    const spanStyle = {
        color: "#D14F4F",
        float: "right",
        fontSize: "13px"
    }

    const [errors, setErrors] = useState<registerDataType>({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirm: "",
    });

    //validation functions
    const validName = (value: string) => {
        if (!value) return "*Required";
        if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
            return "Only contain Alphabet";

        return '';
    };
    // const validName = (value: string) => {
    //     if (!value) return "*Required";
    //     if (!value.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g))
    //         return "Only contain Alphabet ";

    //     return "";
    // };


    const validEmail = (value: string) => {
        if (!value) return "*Required";
        if (!value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
            return "Email is not valid";

        return "";
    };
    const validPassword = (value: any) => {
        if (!value) return "*Required";
        if (value.length < 6) return "Atleast 6 characters required";
        return "";
    };
    const validConfirmPassword = (password: any, confirmPassword: any) => {
        if (!password) return "*Required";
        if (password !== confirmPassword) return "Password not match";
        return "";
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setRegisterData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
        if (value !== "") {
            setErrors(prevData => ({
                ...prevData,
                [name]: "",
            }));
        }
    };

    // check errors then increase page number
    const handleNextPage = async (e: SyntheticEvent) => {
        e.preventDefault();
        console.log("going to next page")
        setErrors({
            firstName: validName(registerData.firstName),
            lastName: validName(registerData.lastName),
            email: validEmail(registerData.email),
            password: validPassword(registerData.password),
            passwordConfirm: validConfirmPassword(registerData.password, registerData.passwordConfirm),
        });

        if (
            validName(registerData.firstName) === "" &&
            validName(registerData.lastName) === "" &&
            validEmail(registerData.email) === "" &&
            validPassword(registerData.password) === "" &&
            validConfirmPassword(registerData.password, registerData.passwordConfirm) === ""
        ) {
            setPageNumber(2)
        }
    }


    return (
        <main className="loginPageWidthDiv">
            <div className="toploginpage">
                <div className="loginpage Registersec">
                    
                    <form className='auth-form reg-form-width'>
                        <h1 className=" Registertitle"> Register</h1>
                        <div className="row">
                            <div className="col-sm-6">
                                <div>
                                    <label htmlFor="firstName">First Name: </label>
                                    <span
                                        style={{
                                            color: "#D14F4F",
                                            float: "right",
                                            fontSize: "13px"
                                        }}
                                    >
                                        {errors.firstName && errors.firstName}
                                    </span>
                                </div>
                                <div className="login-input-user">
                                    <div className="emailicon">
                                        <AccountCircleIcon style={{ color: 'white', fontSize: '20px' }} />
                                    </div>
                                    <div className="email-input">
                                        <input type="text" className="form-control" id="firstName" placeholder="First Name"
                                            value={registerData.firstName}
                                            name="firstName"
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div>
                                    <label htmlFor="lastName">Last Name: </label>
                                    <span
                                        style={{
                                            color: "#D14F4F",
                                            float: "right",
                                            fontSize: "13px",
                                        }}
                                    >
                                        {errors.lastName && errors.lastName}
                                    </span>
                                </div>
                               
                                <div className="login-input-user">
                                    <div className="emailicon">
                                        <AccountCircleIcon style={{ color: 'white', fontSize: '20px' }} />
                                    </div>
                                    <div className="email-input">
                                        <input type="text" className="form-control" id="lastName" placeholder="Last Name"
                                            name="lastName"
                                            value={registerData.lastName}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div>
                                    <label htmlFor="email">Email address: </label>
                                    <span
                                        style={{
                                            color: "#D14F4F",
                                            float: "right",
                                            fontSize: "13px",
                                        }}
                                    >
                                        {errors.email && errors.email}
                                    </span>
                                </div>
                                <div className="login-input-user">
                                    <div className="emailicon">
                                        <EmailIcon style={{ color: 'white', fontSize: '20px' }} />
                                    </div>
                                    <div className="email-input">
                                        <input type="email" className="form-control" id="email" placeholder="name@example.com"
                                            value={registerData.email}
                                            name="email"
                                            onChange={handleInputChange} required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div>
                                    <label htmlFor="password">Password: </label>
                                    <span
                                        style={{
                                            color: "#D14F4F",
                                            float: "right",
                                            fontSize: "13px",
                                        }}
                                    >
                                        {errors.password && errors.password}
                                    </span>
                                </div>
                                <div className="login-input-user">
                                    <div className="emailicon">
                                        <PasswordIcon style={{ color: 'white', fontSize: '20px' }} />
                                    </div>
                                    <div className="email-input">
                                        <input type="password" className="form-control" id="password" placeholder="Password"
                                            value={registerData.password}
                                            name="password"
                                            onChange={handleInputChange} required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div>
                                    <label htmlFor="passwordConfirm">Confirm Password: </label>
                                    <span
                                        style={{
                                            color: "#D14F4F",
                                            float: "right",
                                            fontSize: "13px",
                                        }}
                                    >
                                        {errors.passwordConfirm && errors.passwordConfirm}
                                    </span>
                                </div>
                                
                                <div className="login-input-user">
                                    <div className="emailicon">
                                        <PasswordIcon style={{ color: 'white', fontSize: '20px' }} />
                                    </div>
                                    <div className="email-input">
                                        <input type="password" className="form-control" id="passwordConfirm" placeholder="Password Confirm"
                                            value={registerData.passwordConfirm}
                                            name="passwordConfirm"
                                            onChange={handleInputChange} required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="Signbtn">
                            <button className="submit-button Fencerbtn" type="button" onClick={handleNextPage}>Next</button>
                        </div>
                    </form>
                
                </div>
            </div>
        </main>
    )
}

export default RegisterFirstPage;
