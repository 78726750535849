// import CSS from 'csstype'
import { useState } from 'react';
// import { FormRatingElement } from '../components/forms/form elements/FormRatingElement';
// import { FormTextElement } from '../components/forms/form elements/FormTextElement';
import { FormNumberElement } from '../components/forms/form elements/FormNumberElement';
import { DialogBox } from '../components/DialogBox';

// import {useState, useEffect, SyntheticEvent} from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import {Elements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js/pure";
// import { HAND_CHOICES } from '../constants/VarOptions';
// import CheckoutForm from './auth/withPaymentElement';

const stripePromise = loadStripe('pk_test_51KJ0oMGrIQRxhbL6ginXLQbVevkZ4Wo89z0jCO5sqgsW6Y1oK3HVQTxtn2NQfFfpmaaY7d1oT1yUedkpl9zo0H1V004PGw3qRH');

// import {useState} from 'react';
const FormSelectElement = ({
  setValue,
  value,
  placeholder,
  labelText,
  elementName,
  selectOptions
}: {
  setValue: React.Dispatch<React.SetStateAction<string>> | Function,
  value?: string,
  placeholder: string,
  labelText: string,
  elementName: string,
  selectOptions: { [key: string]: string }
}) => {


  // move label out of the way if the select menu is filled in
  const [className, setClassName] = useState<string>('')

  function onChange(entry: string) {
      setValue(entry)
      if (entry != '') {
          setClassName('jumped')
      } else {
          setClassName('')
      }
  }

  return (
      <div className='form-input'>
          <select id='eventLevelInput'
              onChange={e => onChange(e.target.value)}
              value={value}
              name={elementName}
          >
              {/*placeholder value to present component*/}
              <option value={placeholder}>{placeholder}</option>

              {/*rest of the select menu options*/}
              {Object.entries(selectOptions).map(([key, value]) => (
                  <option key={key} value={key}>{value}</option>
              ))}
          </select>
          <span className='arrow'></span>
          <label className={className} htmlFor='eventLevelInput'>{labelText}</label>
      </div>
  )
}

export function Test() {
    const [registerData, setRegisterData] = useState({ first: "", last: "" });
  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setRegisterData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  
    // ... rest of your component
    const HAND_CHOICES = {
      "left": "Left",
      "right": "Right"
    }
  
    return (
      <div>
        <FormSelectElement setValue={handleChange} elementName="handed" value={registerData.first} selectOptions={HAND_CHOICES} placeholder="" labelText="Handedness"/>
      </div>
    );
  }

