import axios from "axios";
import {useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";


export const PaymentSuccess = () => {

  const navigate = useNavigate();
  const [stripeParams, setStripeParams] = useSearchParams()
  const [isLoaded, setIsLoaded] = useState(false)
  const [succeeded, setSucceeded] = useState(false)
  const paymentIntent = stripeParams.get('payment_intent')
  const clientSecret = stripeParams.get('payment_intent_client_secret')

  axios.post('api/subscription/confirm-membership/', {
    payment_intent: paymentIntent,
  }).then(res => {
    setIsLoaded(true)    
    setSucceeded(true)
    console.log('success')
  }).catch(err => {
    setIsLoaded(true)
    console.log(err)
  })

  return(
    <>
        {isLoaded ?
        <>
        {succeeded ? (
          <div className="container">
            <h1>Success!</h1>
            <h3>your payment was successfully completed</h3>
            <br />
            <br />
            <button className="submit-button" onClick={() => navigate('/journal')}>Go to Journal</button>
          </ div>
          
          ):(
            <div className="container">
            <h1>Error</h1>
            <h3>There was an issue processing your payment.  If this continues please reach out to support@finalstrip.com</h3>
            <br />
            <br />
            <button className="submit-button" onClick={() => navigate('/register')}>Try Again</button>
          </ div>
        )}
        </>
        : 
        <div className="container">
          <h1>Loading...</h1>
          <br />
          <div className="spinner"/>
          <br />
          <button className="submit-button" onClick={() => navigate('/register')}>Try Again</button>
        </ div>
      }
    </>
        
  )
};
