import { SyntheticEvent, useState } from "react";
import { Navigate } from "react-router-dom";
import {useDispatch} from "react-redux";
import {setAuth} from "../../redux/authSlice";

import { LoginForm } from "../../components/forms/LoginForm";
// import { AuthenticatorForm } from "../../forms/AuthenticatorForm";


export const Login = () => {
    const dispatch = useDispatch();
    const [loginData, setLoginData] = useState<{
        id: number;
        secret?: string;
        otpauth_url?: string;
    }>({
        id: 0
    });

    const success = () => {
        dispatch(setAuth(true));
        console.log("login successfully..")
        return <Navigate to="/journal" />
    }

    let form;
    
    if (loginData?.id === 0) {
        form = <LoginForm loginData={setLoginData} success={success} />
    } else {
        // form = <AuthenticatorForm loginData={loginData} success={success} />
        // form = <h1>You are already logged in.</h1>
        form = <Navigate to="/journal" />
    }
    return (
        <main className=" loginPageWidthDiv">
            <div className="toploginpage">
                <div className="loginpage">
                    {form}
                </div>
            </div>
        </main>
    )
}