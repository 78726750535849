import React ,{ SyntheticEvent, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import swal from 'sweetalert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Password';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import { getCSRFToken } from "../../hooks/CSRFToken";
// import { CSRF } from "./form elements/CSRF";


export const LoginForm = (props: {
  loginData: Function;
  success: Function;
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [password, setPassword] = useState("");
  // const [redirect, setRedirect] = useState(false);
  const [otpfieldshow, setotpfieldshow] = useState(false);
  const [passwordshow, setpasswordshow] = useState(true);
  const [validations, setValidations] = useState(false)
  const [temp_password, setOtp] = useState("");
  const [showCookieMessage, setShowCookieMessage] = useState<boolean>(false);
  
  const navigate = useNavigate();

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(event.target.value);
  };
  
  useEffect(() => {
    window.scroll(0, 0);
    axios.defaults.headers.common['X-CSRFToken'] = getCSRFToken()
  }, [])

  //send email and password to django for JWT
  const submit = async (e: SyntheticEvent) => {

    e.preventDefault(); // prevents page from reloading.
    if ((email && password) || temp_password) {
      setValidations(false)

      try {
        if (error == "limit exceded of login try you can get your otp from your email" || otpfieldshow) {
          const { data } = await axios.post(
            "api/auth/login",
            {
              email,
              temp_password,
            },
            { withCredentials: true }
          );
          // use a type assertion to tell TypeScript that data is of type { token: string, status: number }
          const responseData = data as { token: string; status: number };

          // stores bearer token in the cookies
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${responseData.token}`;
          if (responseData.status === 200) {
            setShowCookieMessage(true);
            props.success();
            setotpfieldshow(false)
            setpasswordshow(true)
          }

          //this is duplicated in login.tsx and should be cleaned up at some point
          // if (redirect) {
          //   return <Navigate to="/" />;
          // }

          // I don't remember why this is here
          if (data == undefined) {
            return;
          }
          localStorage.setItem('cookiesdata', 'yes');
          props.loginData(data);
          setOtp("")
        
        
        } else {
          // start of normal log in process
          const { data } = await axios.post(
            "api/auth/login",
            {
              email,
              password,
            },
            { withCredentials: true }
          );
          console.log('login received new token:  ',data)
          
          // Do I really need this? I am hoping I don't
          // use a type assertion to tell TypeScript that data is of type { token: string, status: number }
          const responseData = data // as { token: string; status: number };

          // stores bearer token in the cookies
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${responseData.token}`;
          if (responseData.status === 200) {
            props.success();
            setShowCookieMessage(true);
          }

          //this is duplicated in login.tsx and should be cleaned up at some point
          // if (redirect) {
          //   return <Navigate to="/journal" />;
          // }

          // I don't remember why this is here
          if (data == undefined) {
            return;
          }

          localStorage.setItem('userData', JSON.stringify(responseData.user_data))
          localStorage.setItem('cookiesdata', 'true');
          console.log('login form complete! User data set')
          // props.loginData(data);
          return navigate('/journal')
        }

      } catch (error: any) {
        if (error?.response?.data == "limit exceded of login try you can get your otp from your email") {
          swal({
            title: "Error",
            text: error?.response?.data,
            icon: "warning",
            dangerMode: true,
          })
          setotpfieldshow(true)
          setpasswordshow(false)
          setError(error.response.data);
          const { data } = await axios.post(
            "api/auth/temppassword",
            {
              email,
            },
            { withCredentials: true }
          );
        } else {
          console.log('login error:  ', error?.response?.data)
          swal({
            title: "Error",
            text: "Please Provide Valid Login Details",
            icon: "warning",
            dangerMode: true,
          })
          setError(error.response.data);
        }
      }
    } else {
      setValidations(true)
    }
  }


  // authorizes token from Google and stores in the header
  const onSuccess = async (googleUser: any) => {
    // console.log(googleUser)
    const { status, data } = await axios.post(
      "api/auth/google-auth",
      {
        token: googleUser.credential,
      },
      { withCredentials: true }
    );

    axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;

    if (status === 200) {
      props.success();
    }
  };

  // alert to error if google button fails
  const onError = () => {
    alert("There was an error logging in with Google.");
  };
  

  return (
    <>
      <form className="auth-form" onSubmit={submit}>
        <h1 className="sgntitle"> sign in</h1>

        {/* <input
          type="hidden"
          value={getToken("csrftoken")}
          name="csrfmiddlewaretoken"
        /> */}
        {/* <CSRF/> */}

        <label htmlFor="floatingInput">Email address</label>
        <div className="login-input-user">
          <div className="emailicon">
            <AccountCircleIcon style={{ color: 'white', fontSize: '20px' }} />
          </div>
          <div className="email-input">
            <input
              type="email"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              onChange={(e) => setEmail(e.target.value)}
            />
            {!email && validations && <><p className="loginerror">Please enter Email</p></>}
          </div>

        </div>


        {passwordshow && (
          <div>
            <label htmlFor="floatingPassword">Password</label>
            <div className="login-input-user">
              <div className="emailicon">
                <PasswordIcon style={{ color: 'white', fontSize: '20px' }} />
              </div>
              <div className="email-input">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                {validations && !password && <><p className="loginerror">Please enter Password</p></>}
              </div>
            </div>
          </div>
        )}


        {otpfieldshow && (
          <div>
            <label htmlFor="floatingPassword">OTP</label>
            <div className="login-input-user">
              <div className="emailicon">
                <SendToMobileIcon style={{ color: 'white', fontSize: '20px' }} />
              </div>
              <div className="email-input">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="OTP"
                  value={temp_password}
                  onChange={handleOtpChange}
                  maxLength={5}
                  pattern="\d*"
                />
                {validations && !temp_password && <><p className="loginerror">Please enter Otp</p></>}
              </div>
            </div>
          </div>
        )}

        <div className="mb">
          <Link to={"/forgot"}>Forgot Password</Link>
        </div>
        <button className="submit-button" type="submit">
          Sign in
        </button>
      </form>

      <br />

      <div className="google-login">
        <GoogleLogin
          onSuccess={onSuccess}
          onError={onError}
          useOneTap
          theme="filled_blue"
        />
      </div>
    </>
  );
};
