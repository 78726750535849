import React, { useState, SyntheticEvent, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../redux/store";
import { FormTextElement } from "./form elements/FormTextElement";
import { FormDateElement } from "./form elements/FormDateElement";
import { FormSelectElement } from "./form elements/FormSelectElement";
import { CSRF } from "./form elements/CSRF";
import { useNavigate } from "react-router-dom";
import { TOURNAMENT_LEVEL } from "../../constants/VarOptions";
import { getNewRefreshToken } from "../../hooks/utils";
import swal from "sweetalert";

interface ChildComponentProps {
  setShowTour: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  slugProp: string;
  setSlug: React.Dispatch<React.SetStateAction<string>>;
  setDataReceived: React.Dispatch<React.SetStateAction<boolean>>;
  btnValue: string
}

export const TournamentForm = (props: ChildComponentProps) => {
  const [tournName, setTournName] = useState<string>("");
  const [tournDate, setTournDate] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [eventLevel, setEventLevel] = useState<string>("");
  const [club, setClub] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [validations, setValidations] = useState(false);


  const navigate = useNavigate();

  const auth = useSelector((state: RootState) => state.auth.value)

  // Send form to the backend to be processed
  const submit = async (e: SyntheticEvent) => {
    e.preventDefault(); // prevents page from reloading.
    if (auth) {
      if (props.slugProp) {
        if (
          tournName &&
          tournDate &&
          eventLevel
        ) {
          try {
            await getNewRefreshToken();
            setValidations(false);
            const { data } = await axios.patch(
              `api/tournaments/update/${props.slugProp}/`,
              {
                slug: props.slugProp,
                name: tournName,
                date: tournDate,
                event_level: eventLevel,
                club,
                location,
                url,
                notes,
              },
              { withCredentials: true }
            );
            swal({
              title: 'Tournament Updated',
              icon: 'success',
              timer: 3000,
            });
            setTournName("");
            props.setSlug("");
            props.setOpen(false);
            props.setDataReceived(false);
          } catch (error: any) {
            swal({
              title: "Error",
              text: error.response?.data?.detail,
              icon: "warning",
              dangerMode: true,
            });
          }
        } else {
          setValidations(true);
        }
      } else {
        if (
          tournName &&
          tournDate &&
          eventLevel
        ) {
          await getNewRefreshToken();
          setValidations(false);
          const { data } = await axios.post(
            "api/tournaments/create/",
            {
              name: tournName,
              date: tournDate,
              event_level: eventLevel,
              club,
              location,
              url,
              notes,
            },
            { withCredentials: true }
          );
          swal({
            title: 'Tournament Added',
            icon: 'success',
            timer: 3000,
          });
          props.setShowTour(false);
          props.setDataReceived(false);
          props.setSlug("");
        } else {
          setValidations(true);
        }
      }
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    const myObjectString = localStorage.getItem("userData");
    if (myObjectString !== null) {
      const myObject = JSON.parse(myObjectString);
      // use myObject as needed...
    }
    if (props.slugProp) {
      (async () => {
        const result = await axios.get(
          `api/tournaments/detail/${props.slugProp}/`,
          {
            withCredentials: true,
          }

        );
        const date = new Date(result.data.date);
        date.setDate(date.getDate());
        const formattedDate = date.toISOString().substring(0, 10);
        setTournDate(formattedDate);
        setTournName(result.data.name);
        setLocation(result.data.location);
        setClub(result.data.club);
        setEventLevel(result.data.event_level);
        setUrl(result.data.url);
        setNotes(result.data.notes);

        // setGetTournaments(result.data);
        // setOpen(true);
      })();
    }
  }, [props.slugProp]);

  return (
    <>
      <form className="tournament" onSubmit={submit}>
        <br />
        {props.slugProp ? (<>
          {/* <div className="emptydiv"></div> */}
        </>
        ) : (
          <h1 className="h1 Tournament-title">New Tournament</h1>
        )}
        <br />
        <CSRF />
        <div className="row">
          <div className="col-sm-6">
            <div className="Please-error">
              <FormTextElement
                setValue={setTournName}
                value={tournName}
                elementName="tournament"
                placeholder=""
                labelText="Tournament Name"
              />
              {validations && !tournName && <p className="testerror">Please enter tournament name</p>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="Please-error">
              <FormDateElement
                setValue={setTournDate}
                value={tournDate}
                elementName="date"
                placeholder=""
                labelText="Date"
              />
              {validations && !tournDate && <p className="testerror">Please enter date</p>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="Please-error">
              <FormTextElement
                setValue={setLocation}
                value={location}
                elementName="location"
                placeholder=""
                labelText="Location"
              />
            </div>
          </div>
          <div className="col-sm-6">
            <div className="Please-error">
              <FormTextElement
                setValue={setClub}
                value={club}
                elementName="host"
                placeholder=""
                labelText="Host Club"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <div className="Please-error">
              <FormSelectElement
                setValue={setEventLevel}
                value={eventLevel}
                selectOptions={TOURNAMENT_LEVEL}
                elementName="eventlevel"
                placeholder=""
                labelText="Event Level"
              />
              {validations && !eventLevel && <p className="testerror">Please select level</p>}
            </div>
          </div>
          <div className="col-sm-6">
              <FormTextElement
                setValue={setUrl}
                value={url}
                elementName="url"
                placeholder=""
                labelText="URL"
              />
          </div>
        </div>
        <div className="Please-error">
          <FormTextElement
            setValue={setNotes}
            value={notes}
            elementName="notes"
            placeholder=""
            labelText="Notes"
          />
        </div>
        {/* <button className="submit-button addtournamentbtn" type="button" onClick={submit}> */}
        <button className="submit-button addtournamentbtn" type="submit">
          {props.btnValue ? props.btnValue : "tournament"}
        </button>
      </form>
    </>
  );
};
