import React, { useState, useEffect } from 'react'
import { getNewRefreshToken } from "../../hooks/utils";
import { setAuth } from '../../redux/authSlice'
import { useDispatch } from 'react-redux';
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface User {
    title: string;
    description: string;
    lesson_date: string;
    
}

export const LessonDetail = () => {
    const dispatch = useDispatch()
    const { lessonSlug } = useParams();
    const [userId, setuserId] = useState<string>('');
    const [lesson, setLesson] = useState<User>({
        title: '',
        description: '',
        lesson_date: '',
    });

    const getData = async () => {
        await getNewRefreshToken();
        const response = await axios.get(`api/lessons/lessons/${lessonSlug}/`, {
            withCredentials: true,
        });
        setLesson(response.data)
    }
    useEffect(() => {
        window.scroll(0, 0);
        var myData = localStorage.getItem('userData');
        if (myData) {
            dispatch(setAuth(true));
        }
        getData();
        const id = localStorage.getItem('userId')
        if (id) {
            setuserId(id)
        }
    }, [])
    return (
        <div>
            <Link className='lessonlist-backBtn' to={`/profile/lesson-list/${userId}`}><ArrowBackIcon style={{ fontSize: '22px' }} /></Link>
            <div className='lessonlist-container'>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <h3 style={{ textTransform: 'capitalize' }} className='lessonlist-heading'>Title: {lesson.title}</h3>
                    <p style={{display:'flex'}}><CalendarMonthIcon style={{marginTop:'2px',marginRight:'3px'}}/> {lesson.lesson_date}</p>
                </div>
                <h3>Description</h3>
                {lesson.description.split('\n').map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </div>
        </div>
    )
}
