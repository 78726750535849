import { FencerForm } from "../../components/forms/FencerForm"
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react'
import { setAuth } from '../../redux/authSlice'
import { useDispatch, useSelector } from 'react-redux';
import { getNewRefreshToken } from "../../hooks/utils";


export const CreateFencer = () => {
    const dispatch = useDispatch()
    
    useEffect(() => {
        var myData = localStorage.getItem('userData');
        console.log("journal", myData)
        if (myData) {
            dispatch(setAuth(true))
        }
    }, [])

    useEffect(() => {
        getNewRefreshToken();
    }, []);

    return (
        <div className="container">
            <Link className='backBtn backbtn-createFen' to='/journal/fencers'>Back</Link>
            <h1 className="fencertext">Create Fencer</h1>
            <br />
            <FencerForm />
        </div>
    )
}