import React from 'react'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios"
import { setAuth } from '../redux/authSlice'
import { getNewRefreshToken } from "../hooks/utils";
import { RootState } from "../redux/store";
import { useState, useEffect, useRef } from "react";
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LoginIcon from '@mui/icons-material/Login';


export const NavBar = () => {
    const loginRightRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch()
    const auth = useSelector((state: RootState) => state.auth.value)
    // const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [mobileLinks, setMobileLinks] = useState(false)
    const [coach, setCoach] = useState<boolean>(false);
    const [student, setStudent] = useState<boolean>(false);
    const [isContentVisible, setIsContentVisible] = useState<boolean>(false);
    const [logoutToggle, setlogoutToggle] = useState<boolean>(false);
    const [loginToggle, setLoginToggle] = useState<boolean>(false);

    const toggleContent = () => {
        setIsContentVisible(!isContentVisible);
    };
    const handleLogout = () => {
        setlogoutToggle(!logoutToggle)
    }
    const handleLogin = () => {
        setLoginToggle(!loginToggle)
    }
    let auth_links;
    const data1 = localStorage.getItem('userData');

    useEffect(() => {
        const data = localStorage.getItem('userData');
        
        
        if (data && data != "undefined") {
            const id = JSON.parse(data);
            if (id.subscription === 'Coach') {
                setCoach(true);
            }
            if (id.subscription === 'Student') {
                setStudent(true);
            }
        } else {
            console.log('subcription not found')
        }
    }, [data1]);

    // useEffect(() => {
    //     const handleClickOutside = (event: MouseEvent) => {
    //         if (event.target instanceof Node && loginRightRef.current && loginRightRef.current.contains(event.target as Node)) {
    //             // setIsContentVisible(!isContentVisible);
    //         }
    //         setIsContentVisible(false);
    //     };

    //     window.addEventListener('mousedown', handleClickOutside);

    //     return () => {
    //         window.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);
    // should be refactored to use isAuthenticated
    // useEffect(() => {
    // (async () => {

    //this originally was wrapped in a parenthesis with an extra set at the end.  Was not a 
    // const checkAuth = async () => {
    //         try {
    //             const {data} = await axios.get('api/auth/user', {withCredentials: true});
    //             dispatch(setAuth(true))
    //             setIsLoggedIn(true)
    //             console.log('authenticated as :', data.email)
    //         } catch (e) {
    //             dispatch(setAuth(false))
    //             setIsLoggedIn(false)
    //             console.log('not authenticated')
    //         }
    //     }
    // checkAuth()


    // }, [isLoggedIn])

    // const navLinks = () => {
    //     mobileLinks ? "nav-links mobile" : "nav-links hidden"
    // }

    // const logout = async () => {
    //     await axios.post('api/auth/logout', { withCredentials: true });
    //     axios.defaults.headers.common['Authorization'] = '';
    //     localStorage.removeItem('userData');
    //     localStorage.removeItem("token")
    //     dispatch(setAuth(false));
    // }

    const logout = async () => {
        try {
            //  // Clear user data from local storage
            // localStorage.removeItem("userData");
            // localStorage.removeItem("token");
            // localStorage.clear(); //remove all data from local storage

            // // Update authentication status in Redux store
            // dispatch(setAuth(false));

            // // Clear authorization headers
            // axios.defaults.headers.common["Authorization"] = "";
            // setlogoutToggle(!logoutToggle)

            // // Redirect the user to the login page or desired location
            // window.location.href = "/login";

            // Perform logout API request
            await axios.post("api/auth/logout", { withCredentials: true });

            // Clear user data from local storage
            localStorage.removeItem("userData");
            localStorage.removeItem("token");
            localStorage.clear(); //remove all data from local storage

            // Update authentication status in Redux store
            dispatch(setAuth(false));

            // Clear authorization headers
            axios.defaults.headers.common["Authorization"] = "";
            setlogoutToggle(false)

            // Redirect the user to the login page or desired location
            window.location.href = "/login";

        } catch (error) {
            // Handle error if logout fails
            console.log("Logout error:", error);
        }
    };

    // This function only causes problems
    const [scrollClass, setScrollClass] = useState('scrolled'); //wtf is this????????
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrollClass('scrolled');
            } else {
                setScrollClass('scrolled');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // if (auth) {
    //     auth_links = <div className={mobileLinks ? 'navbar-account account-flex open-menu icon-profile' : 'navbar-account icon-profile'}>
    //         <Link className='icon-profile icon-profile-border' to='/profile/friends '>
    //             <PermIdentityIcon className='profile-logo' />{coach ? <span style={{ fontWeight: '500' }}>Coach</span>
    //                                                             : student ? <span style={{ fontWeight: '500' }}>Student</span>
    //                                                             : <span style={{ fontWeight: '500' }}>Freeium</span>}
    //         </Link>
    //         <Link
    //             to="login"
    //             className={mobileLinks ? 'navbar-account-button open-menu pop' : 'navbar-account-button pop'}
    //             onClick={logout}>
    //             Logout
    //         </Link>
    //     </div>
    // } else {
    //     auth_links = <div className={mobileLinks ? 'navbar-account open-menu' : 'navbar-account'}>
    //         <Link
    //             to="login"
    //             className={mobileLinks ? "navbar-account-button open-menu pop" : "navbar-account-button pop"}
    //             onClick={() => setMobileLinks(false)}>
    //             Login
    //         </Link>
    //         <Link
    //             to="register"
    //             className={mobileLinks ? "navbar-account-button open-menu pop" : "navbar-account-button pop"}
    //             onClick={() => setMobileLinks(false)}>
    //             Register
    //         </Link>
    //     </div>
    // }

    // return (
    //     <div className={`my-element ${scrollClass}`}>



    //         <nav className={mobileLinks ? `navbar open-menu menu ${scrollClass}` : "navbar menu"} >
    //             <div onClick={toggleContent} className="toggle">
    //                 <img src="/toggle.png" />
    //             </div>
    //             <div className="logo logo-color">FINAL<span>STRIP</span></div>
    //             <div className={mobileLinks ? "navbar-links open-menu" : "navbar-links"}>
    //                 <ul>
    //                     <li><Link to="/" className="navbar-link" onClick={() => setMobileLinks(!mobileLinks)}>Home</Link></li>
    //                     <li className="submenudiv"><Link to="/journal/" className="navbar-link dropdown" onClick={() => setMobileLinks(!mobileLinks)}>Journal</Link>
    //                         <div className="dropdown-content">
    //                             <Link className="navbar-link" onClick={() => setMobileLinks(!mobileLinks)} to="/journal/tournaments">Tournaments</Link>
    //                             <Link className="navbar-link" onClick={() => setMobileLinks(!mobileLinks)} to="/journal/lessons">Lessons</Link>
    //                             <Link className="navbar-link" onClick={() => setMobileLinks(!mobileLinks)} to="/journal/fencers">Fencer</Link>
    //                             {/* <Link to="/journal/goals">Goals</Link> */}
    //                         </div>
    //                     </li>
    //                     {/* <li><Link to="/journal/tournaments" className="navbar-link" onClick={() => setMobileLinks(!mobileLinks)}>Tournaments</Link></li>
    //                     <li><Link to="/journal/lessons" className="navbar-link" onClick={() => setMobileLinks(!mobileLinks)}>Lessons</Link></li>
    //                     <li><Link to="/journal/fencers" className="navbar-link" onClick={() => setMobileLinks(!mobileLinks)}>Fencers</Link></li> */}
    //                     <li><Link to="/contactus" className="navbar-link" onClick={() => setMobileLinks(!mobileLinks)}>Contact us</Link></li>
    //                     <li><Link to="/subscription" className="navbar-link" onClick={() => setMobileLinks(!mobileLinks)}>Subscription</Link></li>
    //                 </ul>
    //             </div>

    //             {auth_links}

    //             <a href="#" className={mobileLinks ? "hamburger open-menu" : "hamburger"} onClick={() => setMobileLinks(!mobileLinks)}>
    //                 <span className="line">1212</span>
    //                 <span className="line">3233</span>
    //                 <span className="line">565656</span>
    //             </a>
    //         </nav>



    //         {isContentVisible &&
    //             <nav className="mobldf" ref={loginRightRef} >
    //                 {/* <div className="logo">Finalstrip</div> */}

    //                 <div className={mobileLinks ? "navbar-links2 open-menu" : "navbar-links2"}>
    //                     <ul>
    //                         <li><Link to="/" className="navbar-link" onClick={() => {
    //                             setMobileLinks(!mobileLinks);
    //                             setIsContentVisible(!isContentVisible);
    //                         }}>Home</Link></li>
    //                         <li><Link to="/journal/" className="navbar-link" onClick={() => {
    //                             setMobileLinks(!mobileLinks);
    //                             setIsContentVisible(!isContentVisible);
    //                         }}>Journal</Link></li>
    //                         <li><Link to="/journal/tournaments" className="navbar-link" onClick={() => {
    //                             setMobileLinks(!mobileLinks);
    //                             setIsContentVisible(!isContentVisible);
    //                         }}>Tournaments</Link></li>
    //                         <li><Link to="/journal/lessons" className="navbar-link" onClick={() => {
    //                             setMobileLinks(!mobileLinks);
    //                             setIsContentVisible(!isContentVisible);
    //                         }}>Lessons</Link></li>
    //                         <li><Link to="/journal/fencers" className="navbar-link" onClick={() => {
    //                             setMobileLinks(!mobileLinks)
    //                             setIsContentVisible(!isContentVisible);
    //                         }}>Fencers</Link></li>
    //                         <li><Link to="/contactus" className="navbar-link" onClick={() => {
    //                             setMobileLinks(!mobileLinks);
    //                             setIsContentVisible(!isContentVisible);
    //                         }}>Contact us</Link></li>
    //                         <li><Link to="/subscription" className="navbar-link" onClick={() => {
    //                             setMobileLinks(!mobileLinks);
    //                             setIsContentVisible(!isContentVisible);
    //                         }}>Subscription</Link></li>
    //                     </ul>
    //                 </div>

    //                 {auth_links}

    //                 <a href="#" className={mobileLinks ? "hamburger open-menu" : "hamburger"} onClick={() => setMobileLinks(!mobileLinks)}>
    //                     <span className="line"></span>
    //                     <span className="line"></span>
    //                     <span className="line"></span>
    //                 </a>
    //             </nav>
    //         }
    //     </div>
    // )

    if (auth) {
        auth_links = <div style={{ position: 'relative' }}>
            <span className='profile-icon'><AccountBoxIcon onClick={handleLogout} style={{ color: 'white', fontSize: '27px' }} /></span>
            <div className={logoutToggle ? 'navbar-account account-flex icon-profile logout-display-show' : 'navbar-account icon-profile logout-display-none'}>
                <Link className=' icon-profile2 icon-profile-border marg-btm' to='/profile/friends '
                    onClick={handleLogout}>
                    <PermIdentityIcon className='profile-logo' />{coach ? <span style={{ fontWeight: '500' }}>Coach</span>
                        : student ? <span style={{ fontWeight: '500' }}>Student</span>
                            : <span style={{ fontWeight: '500' }}>Freemium</span>}
                </Link>
                <Link
                    to="login"
                    className='navbar-account-button pop'
                    onClick={logout}>
                    Logout
                </Link>
            </div>
        </div>

    } else {
        auth_links = <div style={{ position: 'relative' }}>
            <span className='profile-icon'><LoginIcon onClick={handleLogin} style={{ color: 'white', fontSize: '27px' }} /></span>
            <div className={loginToggle ? 'navbar-account icon-profile logout-display-show' : 'navbar-account icon-profile logout-display-none'}>
                <Link
                    to="login"
                    className="navbar-account-button pop marg-btm"
                    onClick={() => setLoginToggle(false)}
                >
                    Login
                </Link>
                <Link
                    to="register"
                    className="navbar-account-button pop"
                    onClick={() => setLoginToggle(false)}
                >
                    Register
                </Link>
            </div>
        </div>

    }

    return (
        <div className={`my-element ${scrollClass}`}>
        {/* <div className="my-element scrolled"> */}
            <nav className="navbarr menu" >
                <div onClick={toggleContent} className="toggle">
                    <img src="/toggle.png" />
                </div>
                <div className="logo logo-color">FINAL<span>STRIP</span></div>
                <div className="navbar-links open-menu">
                    <ul>
                        <li className="submenudiv"><Link to="/" className="navbar-link">Home</Link></li>
                        <li className="submenudiv"><Link to="/journal/" className="navbar-link dropdown" >Journal</Link>
                            <div className="dropdown-content">
                                <Link className="navbar-link" to="/journal/tournaments">Tournaments</Link>
                                <Link className="navbar-link" to="/journal/lessons">Lessons</Link>
                                <Link className="navbar-link" to="/journal/fencers">Fencer</Link>
                                {/* <Link to="/journal/goals">Goals</Link> */}
                            </div>
                        </li>
                        {/* <li><Link to="/journal/tournaments" className="navbar-link" onClick={() => setMobileLinks(!mobileLinks)}>Tournaments</Link></li>
                            <li><Link to="/journal/lessons" className="navbar-link" onClick={() => setMobileLinks(!mobileLinks)}>Lessons</Link></li>
                            <li><Link to="/journal/fencers" className="navbar-link" onClick={() => setMobileLinks(!mobileLinks)}>Fencers</Link></li> */}
                        <li className="submenudiv"><Link to="/contactus" className="navbar-link" >Contact us</Link></li>
                        {/* <li className="submenudiv"><Link to="/subscription" className="navbar-link" >Subscription</Link></li> */}
                    </ul>
                </div>

                {auth_links}

                {/* <a href="#" className="hamburger">
                    <span className="line">1212</span>
                    <span className="line">3233</span>
                    <span className="line">565656</span>
                </a> */}
            </nav>
            {isContentVisible &&
                <nav className="mobldf" ref={loginRightRef} >
                    {/* <div className="logo">Finalstrip</div> */}

                    <div className="navbar-links2">
                        <ul>
                            <li><Link to="/" className="navbar-link" onClick={() => {
                                setIsContentVisible(!isContentVisible);
                            }}>Home</Link></li>
                            <li><Link to="/journal/" className="navbar-link" onClick={() => {
                                setIsContentVisible(!isContentVisible);
                            }}>Journal</Link></li>
                            <li><Link to="/journal/tournaments" className="navbar-link" onClick={() => {
                                setIsContentVisible(!isContentVisible);
                            }}>Tournaments</Link></li>
                            <li><Link to="/journal/lessons" className="navbar-link" onClick={() => {
                                setIsContentVisible(!isContentVisible);
                            }}>Lessons</Link></li>
                            <li><Link to="/journal/fencers" className="navbar-link" onClick={() => {
                                setIsContentVisible(!isContentVisible);
                            }}>Fencers</Link></li>
                            <li><Link to="/contactus" className="navbar-link" onClick={() => {
                                setIsContentVisible(!isContentVisible);
                            }}>Contact us</Link></li>
                            <li><Link to="/subscription" className="navbar-link" onClick={() => {
                                setIsContentVisible(!isContentVisible);
                            }}>Subscription</Link></li>
                        </ul>
                    </div>

                    <a href="#" className="hamburger">
                        <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                    </a>
                </nav>
            }

            <div style={{textAlign:'center', backgroundColor: 'orange', fontSize: '16px', padding: '5px 0px'}}> <b>Still in development.  Please be patient.  If you have any questions, feel free to email me at justin@finalstrip.com</b></div>
        </div>
    )
}





