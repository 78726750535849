


export const About = () => {

    return(
        <>
            <p>On my path to becoming a top athlete I spent countless weekends at tournaments and studying opponents to decide 
                what my plan would be going into the next bout.</p>
        </>
    )
}