import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react'
import { setAuth } from '../../redux/authSlice'
import { useDispatch, useSelector } from 'react-redux';


//  In the future this may be a footer menu with icons
export const Journal = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        window.scroll(0, 0);
        var myData = localStorage.getItem('userData');
        console.log("journal", myData)
        if (myData) {
            dispatch(setAuth(true))
        }
    }, [])

    return (
        <div className="journal-page">
            <h2 className="headingerofpage journal-page-heading  ">JOURNAL</h2>
            <div className='journal-flex'>
                <div className='journal-flex-child'>
                    <img className='journal-image' src="/1.jpg" />
                    <Link to='/journal/tournaments'><button className="journal-button">Tournaments</button></Link>
                </div>
                <div className='journal-flex-child'>
                    <img className='journal-image' src="/2.jpg" />
                    <Link to='/journal/lessons'><button className="journal-button">Lessons</button></Link>
                </div>
                <div className='journal-flex-child'>
                    <img className='journal-image' src="/3.jpg" />
                    <Link to='/journal/fencers'><button className="journal-button">Fencer Dashboard</button></Link>
                </div>
                <div className='journal-flex-child'>
                    <img className='journal-image' src="/4.jpg" />
                    <Link to='/'><button className="journal-button">Goals</button></Link>
                </div>
            </div>
        </div>
    )
}