import { useEffect, useRef, useState } from "react"
import axios from "axios"
import Payment from "./Payment"
import {Elements} from "@stripe/react-stripe-js"
import {loadStripe} from "@stripe/stripe-js/pure"
import {fencerDataType} from "../../constants/VarTypes"
import { CSRF } from "../../components/forms/form elements/CSRF";


const stripePromise = loadStripe('pk_test_51KJ0oMGrIQRxhbL6ginXLQbVevkZ4Wo89z0jCO5sqgsW6Y1oK3HVQTxtn2NQfFfpmaaY7d1oT1yUedkpl9zo0H1V004PGw3qRH');


export const RegisterThirdPage = ({
    email,
    setPageNumber
    }:{
    email: string,
    setPageNumber: React.Dispatch<React.SetStateAction<number>>,

}) => {
    const [plan, setPlan] = useState<any | null>(null)
    const [ clientSecret, setClientSecret] = useState('')
    const didMount = useRef(false)

    useEffect(() => {
        if (didMount.current) {

            // now that the user should be logged in.  Update the csrf token
            axios.get('api/auth/csrf-token', {
                headers: { 'Authorization': '' },
                withCredentials: true,
            }).then(() =>
                axios.defaults.headers.common['X-CSRFToken'] = getToken('csrftoken')
            ).then(() =>
            axios.post(
                "api/subscription/create-subscription/",
                {
                  //csrf_token: getToken("csrftoken"),
                  email,
                  plan
                })
                // }, {withCredentials: true})
            .then(data => setClientSecret(data.data.clientSecret))
            .catch(err => console.log(err)))
        } else {
            didMount.current = true
        }

        // fetch("http://localhost:8000/api/subscription/create-subscription/", {
        //   method: "POST",
        // //   body: JSON.stringify({
        // //     email: ,
        // //     email: "test@example.net",
        // //     plan
        // //     })
        // })
        // .then((result) => result.json())
        // .then((data) => setClientSecret(data.clientSecret))

        // axios.post('api/subscription/create-subscription/', {
        //     payment_intent: paymentIntent,
        //   }).then(res => {
        //     setSucceeded(true)
        //     console.log('success')
        //   }).catch(err => {
        //     console.log(err)
        //   })

      }, [plan])


    // gets csrf token from cookie
    function getToken(name:string){
        var cookieValue = 'cookie not found'
        if (document.cookie && document.cookie !== ''){
                var cookies = document.cookie.split(';')
                for (var i =0; i < cookies.length; i++){
                    var cookie = cookies[i].trim()
                    if (cookie.substring(0, name.length +1) === (name + '=')){
                        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
                        break
                    }
                }
        }
        console.log('cookie: ', cookieValue)
        return cookieValue
    }

    
    return (
        <>
        <CSRF />
        <button className="submit-button Fencerbtn" type="button" onClick={() => setPageNumber(2)}>
            Previous
        </button>
        <p onClick={() => setPageNumber(2)} className='loginpage-skipnw'>Previous</p>

        <h1>React Stripe and the Payment Element</h1>
        {/* <p>{stripePromise == null ? "Loading SP..." : "Loaded stripePromise Sucessfully"}</p>
        <p>{clientSecret ? "Loading CS..." : "Loaded clientSecret Sucessfully"}</p> */}
        <h4>Select Subscription Type</h4>
        
        <div className="row">
            <div className="col-sm-12">
                <input
                type="radio"
                id="subscription-type"
                name="subscription-type"
                value="student"
                onChange={(e) => {setPlan(e.target.value)}}
                />
                <label className="" htmlFor="subscription-type">
                    <span>&nbsp; Student $10/Month</span>
                </label>
            </div>

            <div className="col-sm-12">
                <input
                type="radio"
                id="subscription-type"
                name="subscription-type"
                value="coach"
                onChange={(e) => {setPlan(e.target.value)}}
                />
                <label htmlFor="subscription-type">
                    <span>&nbsp; Coach $30/Month</span>
                </label>
            </div>
        </div>

        {plan && clientSecret && (
        <Elements stripe={stripePromise} options={{clientSecret: clientSecret}} >
            <Payment />
        </Elements>
        )}
        </>
    )
}

export default RegisterThirdPage
