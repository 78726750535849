import React, { useState, useEffect } from 'react'
import { getNewRefreshToken } from "../../hooks/utils";
import { setAuth } from '../../redux/authSlice'
import { useDispatch } from 'react-redux';
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


interface User {
    title: string;
    description: string;
    lesson_date: string;
    slug: string;
    pkid: number
}

export const LessonList = () => {
    const dispatch = useDispatch()
    const { userId } = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [lessons, setLessons] = useState<User[]>([]);
    const getData = async () => {
        await getNewRefreshToken();
        const response = await axios.get(`api/lessons/coach/${userId}/lessons/`, {
            withCredentials: true,
        });
        setLoading(false);
        setLessons(response.data);
    }
    useEffect(() => {
        window.scroll(0, 0);
        var myData = localStorage.getItem('userData');
        if (myData) {
            dispatch(setAuth(true));
        }
        getData();
        if (userId){
            localStorage.setItem('userId', userId)
        }
    }, [])

    return (
        <div>
            <div className='lessonDetail-div112'>
                <Link className='lessonDetail-backBtn' to='/profile/friends'><ArrowBackIcon style={{ fontSize: '22px' }} /></Link>
                {/* <h3>User's Lessons</h3> */}
            </div>
            {loading ? <div className="container">
                <h1>Loading ... </h1>
            </div>
                : <>
                    {lessons.length === 0 ? <div className="container" style={{ minHeight: '180px' }}>
                        <h1 className="tourtext">No lesson added yet!</h1>
                    </div>
                        : <div className='card-out'>
                            <div className='ff-card'>
                                <p className='width-title'>Title</p>
                                <p className='width-descrip'>Description</p>
                                <p className='width-title'>Date </p>
                            </div>
                            {lessons.map((item, i) => (
                                <div className='ff-card' key={i}>
                                    <p className='width-title' style={{ textTransform: 'capitalize' }}>{i + 1 + ". "} {item.title}</p>
                                    {/* <p className='width-descrip'>
                                        {item.description.split(' ').length > 15
                                            ? `${item.description.split(' ').slice(0, 15).join(' ')}.....`
                                            : item.description}
                                    </p> */}
                                    <p className='width-descrip'>
                                        {item.description.length > 15
                                            ? `${item.description.split(' ').slice(0, 15).join(' ')}.....`
                                            : item.description}
                                    </p>
                                    <p className='width-title width-title-dateLink'>{item.lesson_date}
                                        <Link className='lessdetail-link' to={`/profile/lesson-detail/${item.pkid}`}>View</Link>
                                    </p>
                                </div>
                            ))}
                        </div>}
                </>}
        </div>

    )
}
