import React, { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios"
import swal from "sweetalert";
import { registerDataType, fencerDataType } from "../../constants/VarTypes"
import RegisterFirstPage from "./RegisterFirstPage";
import RegisterSecondPage from "./RegisterSecondPage";
import RegisterThirdPage from "./RegisterThirdPage";


export const Register = () => {
    const navigate = useNavigate()
    const { step } = useParams<{ step: string }>()
    const [pageNumber, setPageNumber] = useState<number>(Number(step) || 1) // this is not working properly
    const [skip,  setSkip] = useState<boolean>(false)
    const [registerData, setRegisterData] = useState<registerDataType>({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirm: "",
    });
    const [fencerData, setFencerData] = useState<fencerDataType>({
        usaFencingInfo: "",
        firstName: "",
        lastName: "",
        club: "",
        club2: "",
        school: "",
        division: "",
        region: "",
        nationality: "",
        handed: "",
        grip: "",
        ratingEpee: "",
        ratingSabre: "",
        ratingFoil: "",
        refRatingEpee: "",
        refRatingSabre: "",
        refRatingFoil: "",
        customRating: "",
        tacticDescript: "",
        favActions: "",
        notes: "",
    })


    
    

    return (
        <main className="loginPageWidthDiv">
            <div className="toploginpage">
                <div className="loginpage Registersec">
                    
                        {pageNumber === 1 ? (
                            <RegisterFirstPage 
                            setPageNumber = {setPageNumber}
                            setRegisterData = {setRegisterData}
                            registerData = {registerData}
                            />
                        ) : pageNumber === 2 ? (
                            <RegisterSecondPage 
                            setPageNumber = {setPageNumber}
                            setSkip={setSkip}
                            setFencerData = {setFencerData}
                            fencerData = {fencerData}
                            registerData = {registerData}
                            />
                        ) : pageNumber === 3 ? (
                            <RegisterThirdPage  
                                email = {registerData.email}
                                setPageNumber = {setPageNumber}
                            />
                        ) : (
                            <div>
                                <h1>Error</h1>
                                <p>Something went wrong.</p>
                            </div>
                        )}
                </div>
            </div>
        </main>
    )
}

export default Register

