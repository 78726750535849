import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { getNewRefreshToken } from "../../hooks/utils";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../redux/authSlice";
import axios from "axios";
import swal from "sweetalert";

export const SubscriptionDetail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { num } = useParams();
    const [login, setLogin] = useState<boolean>(false);
    const [userid, setUserid] = useState<number>(0);
    const [member_id, setMember_id] = useState<number>(0);

    useEffect(() => {
        var myData = localStorage.getItem("userData");
        if (myData) {
            dispatch(setAuth(true));
            const parsedData = JSON.parse(myData);
            setUserid(parsedData.id);
        }
        var log = localStorage.getItem("login");
        if (log === "true") {
            setLogin(true)
        }
        if (num !== undefined) {
            setMember_id(parseInt(num));
        }
    }, [num]);
    
    const handleButtonClick = async (dataId: number) => {
        if (login === false) {
            navigate('/login')
        }
        await getNewRefreshToken();
        try {
            const { data } = await axios.post("/api/subscription/checkout_session/", {
                user_id: userid,
                package_type: dataId,
            },
                { withCredentials: true }
            );
            window.location.href = data?.sessionId?.url;
        } catch (error) {
            swal("An error occurred");
        }
    };

    return (
        <>
            {member_id === 2 && <div className="container">
                <h2 className="headingerofpage subscr-heading ">Subscription Detail </h2>
                <h3>Plan Type: Advanced</h3>
                <h3>Price: $ 200</h3>
                <h3>Validity: Lifetime</h3>
                <h3>Storage: 5GB</h3>
                <p>The user can upload bout video. </p>
                <h3>Enjoy other Premium content</h3>
                <button className='subscription-continueBtn' onClick={() => handleButtonClick(member_id)}>Continue</button>
            </div>}
            {member_id === 3 && <div className="container">
                <h2 className="headingerofpage subscr-heading ">Subscription Detail </h2>
                <h3>Plan Type: Premium</h3>
                <h3>Price: $ 500</h3>
                <h3>Validity: Lifetime</h3>
                <h3>Storage: 10GB</h3>
                <p>The user can upload bout video. </p>
                <h3>Enjoy other Premium content</h3>
                <button className='subscription-continueBtn' onClick={() => handleButtonClick(member_id)}>Continue</button>
            </div>}
        </>
    )
}