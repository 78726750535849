import { useEffect } from "react";
import axios from "axios";

export const CSRFToken = () => {
    
    // idk why this was suddenly necessary for registration
    // gets csrf token
  function getToken(name: string) {
    var cookieValue = "cookie not found";
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
    
  useEffect(() => {
      axios.get('api/auth/csrf-token', {
              headers: { 'Authorization': '' },
              withCredentials: true,
          }
      ).then(() => {
          // console.log('CSRF Token retrieved===>>', getToken('csrftoken'))
          axios.defaults.headers.common['X-CSRFToken'] = getToken('csrftoken');
      }).catch(() => {
          console.log('CSRF Token failed to retrieve')
          // alert('Error message.');
      });
  }, []);

    return <></>
}

export function getCSRFToken() {
  var cookieValue = "cookie not found";
  var name = 'csrftoken'
  if (document.cookie && document.cookie !== "") {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        console.log('hooks csrf: ', cookieValue)
        break;
      }
    }
  } else {
    console.log('no csrf token')
  }
  return cookieValue;
}
