import React, { useEffect, SyntheticEvent, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getNewRefreshToken } from "../../hooks/utils";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setAuth } from "../../redux/authSlice";
import { Dialog, DialogContent } from "@material-ui/core";
import { FormTextElement } from "../../components/forms/form elements/FormTextElement";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from 'sweetalert';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface Data {
    title: string;
    description: string;
    slug: string;
    tag_name: string;
    update_status: boolean;
    lesson_date: string;
    pkid:number
}
interface Item {
    id: number;
    tag_name: string;
}



export const Lessons = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data, setData] = useState<Data[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [slugg, setSlugg] = useState<string>('');
    const [authh, setAuthh] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);


    const getData = async () => {
        try {
            await getNewRefreshToken();
            const response = await axios.get(`api/lessons/all/`,
                {
                    withCredentials: true,
                });
            setData(response.data)
            setLoading(false)
        } catch (error) {
            console.error('POST API Error:', error);
        }
    }


    useEffect(() => {
        getData();
    }, [loading])

    useEffect(() => {
        window.scroll(0, 0);
        var myData = localStorage.getItem("userData")
        if (myData) {
            dispatch(setAuth(true));
            setAuthh(true)
        }
    }, []);


    const handleCreate = () => {
        if (authh) {
            navigate('/journal/lesson/create')
        }
        else {
            navigate('/login')
        }
    }

    const handleDelete = async (slug: string) => {
        try {
            await getNewRefreshToken();
            const response = await axios.delete(`api/lessons/delete/${slug}/`,
                {
                    withCredentials: true,
                });
            setLoading(true)
            getData();
        } catch (error) {
            console.error('POST API Error:', error);
            swal({
                title: 'warning',
                icon: "warning",
                text: 'Error',
                timer: 3000
            })
        }
    }

    const EditComponent = () => {
        const inputRef = useRef<HTMLInputElement | null>(null);
        const [title, setTitle] = useState<string>('');
        const [description, setDescription] = useState<string>('');
        const [status, setstatus] = useState<boolean>(true);
        const [totalTags, settotalTags] = useState<string[]>([]);
        const [inputError, setInputerror] = useState<boolean>(false);
        const [searchList, setsearchList] = useState<Item[]>([]);
        const [validations, setValidations] = useState<boolean>(false);
  
        const getEditData = async (value: string) => {
            try {
                await getNewRefreshToken();
                const response = await axios.get(`api/lessons/details/${value}/`,
                    {
                        withCredentials: true,
                    });
                setTitle(response.data.title)
                setDescription(response.data.description)
                settotalTags(response.data.lesson_tags)
                setstatus(response.data.status)
            } catch (error) {
                console.error('POST API Error:', error);
            }
        }

        useEffect(() => {
            const user = localStorage.getItem('userData')
            if (user) {
                const parsedData = JSON.parse(user);
                // setuserid(parsedData.id);
            }
            if (slugg) {
                getEditData(slugg);
            }

        }, [])

        const handleBoutTagss = async (e: React.ChangeEvent<HTMLInputElement>) => {
            setInputerror(false)
            const query = e.target.value;
            if (query !== '') {
                try {
                    await getNewRefreshToken();
                    const response = await axios.get(`api/bouts/boutname/?search=${query}`, {
                        withCredentials: true,
                    });
                    setsearchList(response.data)
                } catch (error) {
                    console.error('POST API Error:', error);
                }
            }
            if (query === '') {
                setsearchList([])
            }
        }

        const handleDivClick = (e: SyntheticEvent) => {
            e.preventDefault();
            if (inputRef.current) {
                inputRef.current.focus();
            }
        };

        const handleDel = (i: number, e: SyntheticEvent) => {
            e.preventDefault();
            const filBout = totalTags.filter((item, index) => index !== i)
            settotalTags(filBout)
        }

        const handleSelectValue = (value: any) => {
            setInputerror(false)
            if (!totalTags.includes(value)) {
                settotalTags(prevArray => [...prevArray, value]);
            }
            else {
                setInputerror(true)
            }
        }

        const handleUpdate = async (slugg: string) => {
            if (description) {
                setValidations(false)
                try {
                    await getNewRefreshToken();
                    const response = await axios.patch(`api/lessons/update/${slugg}/`, {
                        slug: slugg,
                        title,
                        description,
                        tag_name: totalTags,
                    },
                        {
                            withCredentials: true,
                        });
                    swal({
                        title: 'Success',
                        icon: 'success',
                        timer: 3000,
                        text: 'Lesson Updated',
                    })
                    setSlugg('')
                    setTitle('')
                    setDescription('')
                    settotalTags([])
                    getData();
                    setOpen(false);
                } catch (error) {
                    console.error('POST API Error:', error);
                    swal({
                        title:'warning',
                        icon: "warning",
                        text: 'Error',
                        timer: 3000
                    })
                }
            }
            else {
                setValidations(true)
                console.log('not submit')
            }
        }

        return (
            <div className='lessonCreate-container'>
                <div className='button-div122'>
                    <CloseIcon onClick={() => setOpen(false)} style={{ fontSize: '22px', position: 'absolute', right: '1px', top: '20px',cursor:'pointer' }} />
                    <h2 className="headingerofpage heading66767 ">Edit Lesson</h2>
                </div>
                <form className="fencerform lessonform">
        
                    <FormTextElement setValue={setTitle} value={title} elementName="title" placeholder="" labelText="Title" />

                    <div className="Please-error">
                        <div className='form-input'>
                            <textarea
                                className='lesson-textarea'
                                id='description'
                                name='description'
                                value={description}
                                placeholder={''}
                                onChange={e => setDescription(e.target.value)}
                            />
                            <label htmlFor='description'>Description</label>
                        </div>
                        {validations && !description && (
                            <p className="testerror" style={{bottom:'4px'}}>Description required</p>
                        )}
                    </div>

                    {/* <div className='form-input'>
                        <label htmlFor='nameInput'>Lesson Tags</label>
                        <div onClick={handleDivClick} className='input-div12'>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {totalTags.map((item, index) => (
                                    <> <span className="bout-tagg-span" key={index}>{item} <CloseIcon className='icon-212' onClick={(e) => handleDel(index, e)} /></span></>
                                ))}
                            </div>
                            <div style={{ position: 'relative' }}>
                                <input
                                    type='text'
                                    list="item-options"
                                    id="item-options"
                                    ref={inputRef}
                                    onChange={handleBoutTagss}
                                />
                                {searchList.length === 0 ? <div className="show-list1222" style={{ display: 'none' }}></div>
                                    : <div className="show-list1222">
                                        {searchList.map((item, i) => (
                                            <p onClick={() => handleSelectValue(item.tag_name)}>{item.tag_name}</p>
                                        ))}
                                    </div>}

                            </div>
                            {inputError && <span style={{ color: "red" }}>Already exits</span>}
                        </div>
                        {validations && totalTags.length === 0 && (
                            <p style={{ bottom: '-14px' }} className="testerror">Tags required</p>
                        )}
                    </div> */}

                    <div style={{width:'100%',textAlign:'center'}}>
                        <button
                            className="submit-button Fencerbtn lesson-btnMargg"
                            type="button"
                            onClick={() => handleUpdate(slugg)}
                        >
                            Update
                        </button>
                    </div>

                </form>
            </div>
        )
    }


    const handleOpen = (value: string, updateStatus: boolean) => {
        if (updateStatus) {
            setSlugg(value)
            setOpen(true)
        }
        else {
            swal({
                text: " Lesson 30 days old, You cannot update",
                icon: "warning",
                timer: 4000,
            })
        }
    }
    const lessonView = (id: number) =>{
        navigate(`/journal/lesson/${id}`)
    }



    return (
        <div className="commonContainer" style={{ position: 'relative' }}>

            <button type="button" className="lesson-btnCreate" onClick={handleCreate}> Add Lesson</button>

            <h2 className="headingerofpage">Lessons</h2>
            {authh ? loading ?
                <div className="container">
                    <h1>Loading ... </h1>
                </div>
                :
                <>
                    {data.length === 0 ? <div className="container" style={{ minHeight: '180px' }}>
                        <h1 className="tourtext">You do not have any Lesson</h1>
                    </div> :
                        <div className="">
                            <div className="card-category-1">
                                {data.map((item, i) => (
                                    <div key={i} className="basic-card basic-card-aqua">
                                        <div className="card-content" onClick={() => lessonView(item.pkid)}>
                                            <span className="card-title"></span>
                                            <div className="card-text" >
                                                <h2 onClick={()=>lessonView(item.pkid)} style={{ textTransform: 'capitalize' }}>
                                                    {item.title}
                                                </h2>
                                                <p onClick={() => lessonView(item.pkid)}>
                                                    {item.description.split(' ').length > 15
                                                        ? `${item.description.split(' ').slice(0, 15).join(' ')}.....`
                                                        : item.description}
                                                </p>
                                                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => lessonView(item.pkid)}>
                                                    <CalendarMonthIcon /><p style={{ marginBottom: '0', marginLeft: '3px' }}>
                                                        {item.lesson_date}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-link action-btn">
                                            <div className="action-btns">
                                                <a href="#" title="Delete">
                                                    <button
                                                        className="delete-btn"
                                                        onClick={() => handleDelete(item.slug)}
                                                    >
                                                        <DeleteIcon style={{ color: 'white', fontSize: '20px' }} />
                                                    </button>
                                                </a>
                                            </div>
                                            <div className="action-btns">
                                                <a href="#" title="Edit">
                                                    <button
                                                        className="edit-btn"
                                                        onClick={() => handleOpen(item.slug, item.update_status)}
                                                    >
                                                        <EditIcon style={{ color: 'white', fontSize: '20px' }} />
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>


                            <Dialog open={open}>
                                <DialogContent>

                                    <EditComponent />

                                </DialogContent>
                            </Dialog>
                        </div>}
                </>
                : <div className="container">
                    <h3>Please login to access the data</h3>
                </div>}
        </div>
    )
}