import axios from 'axios'
import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import "./App.css";

import { NavBar } from "./components/NavBar";
import { FooterPage } from "./components/Footer";

// Config
import { CSRFToken } from "./hooks/CSRFToken";

//  Admin
import { Home } from "./pages/admin/Home";
import { Contact_us } from "./pages/admin/Contact_us";

// Profile
import { Friend } from "./pages/profile/Friend";
import { LessonList } from "./pages/profile/LessonList";
import { LessonDetail } from "./pages/profile/LessonDetail";
import { Setting } from "./pages/profile/Setting";
import { Layout } from "./pages/profile/Layout";


// import { subscription_payment } from "./pages/admin/subscription";
import { Subscription_payment } from './pages/admin/Subscription';
import { SubscriptionDetail } from './pages/admin/SubscriptionDetail';
import { PaymentCancel } from './pages/admin/PaymentCancel';
import { PaymentSuccess } from './pages/admin/PaymentSuccess';

import { About } from "./pages/admin/About";
import { Pricing } from "./pages/admin/Pricing";

//  Authentication
import { Login } from "./pages/auth/Login";
import { Register } from "./pages/auth/Register";
import { RegisterSecondPage } from "./pages/auth/RegisterSecondPage";
import { Forgot } from "./pages/auth/Forgot";
import { Reset } from "./pages/auth/Reset";

//  Journal
import { Journal } from "./pages/journal/Journal";
import { Tournaments } from "./pages/journal/Touraments";
import { Events } from "./pages/journal/Events";
import { Bouts } from "./pages/journal/Bouts";
import { BoutView } from "./pages/journal/BoutView";
import { Fencers } from "./pages/journal/Fencers";
import { FencerBouts } from "./pages/journal/FencerBouts";
import { CreateFencer } from './pages/journal/CreateFencer';
// import { FencerView } from "./pages/journal/FencerView";
// import { FencerBoutsView } from "./pages/journal/FencerBoutsView";
import { Lessons } from "./pages/journal/Lessons";
import { CreateLesson } from "./pages/journal/CreateLesson";
import { LessonView } from "./pages/journal/LessonView";

//  Error
import { Error404 } from "./pages/error/Error404";
import { Test } from './pages/test';


//  possible organize links:  https://stackoverflow.com/questions/58144678/organizing-react-routes-into-separate-components

function App() {

  return (
    <GoogleOAuthProvider clientId="513042667408-adp268qr6da4lta6s0sr5pavt893u1i3.apps.googleusercontent.com"> {/* attached to Justin */}
      <BrowserRouter>
        <NavBar />
        {/* <CSRFToken /> */}

        <Routes>

          {/*  Admin  */}
          <Route path={"/"} element={<Home />} />
          <Route path={"/contactus"} element={<Contact_us />} />

          {/* Profile  */}


          <Route element={<Layout />}>
            <Route path={"/profile/friends"} element={<Friend />} />
            <Route path="/profile/lesson-list/:userId" element={<LessonList />} />
            <Route path="/profile/lesson-detail/:lessonSlug" element={<LessonDetail />} />
            <Route path={"/profile/setting"} element={<Setting />} />
          </Route>

          <Route path={"/payment-failed"} element={<PaymentCancel />} />
          <Route path={"/payment-success"} element={<PaymentSuccess />} />

          <Route path={"/subscription"} element={<Subscription_payment />} />
          <Route path={"/subscriptionDetail/:num"} element={<SubscriptionDetail />} />
          <Route path={"/about"} element={<About />} />
          <Route path={"/pricing"} element={<Pricing />} />

          {/*  Authentication  */}
          <Route path={"/login"} element={<Login />} />
          <Route path={"/register"} element={<Register />} />
          {/* <Route path={"/register-second"} element={<RegisterSecondPage />} /> */}
          <Route path={"/forgot"} element={<Forgot />} />
          <Route path={"/reset/:token"} element={<Reset />} />

          {/* Journal */}
          <Route path={"/journal"} element={<Journal />} />
          <Route path={"/journal/tournaments/"} element={<Tournaments />} />
          <Route path={"/journal/events/:tournamentSlug"} element={<Events />} />
          <Route path={"/journal/bouts/:eventSlug"} element={<Bouts />} />
          <Route path={"/journal/bout/:slug"} element={<BoutView />} />
          <Route path={"/journal/fencers"} element={<Fencers />} />
          <Route path={"/journal/fencersbouts/:boutsId"} element={<FencerBouts />} />
          {/* <Route path={"/journal/fencerboutsview/:slug"} element={<FencerBoutsView />} /> */}
          {/* <Route path="/fencerview/:slug" element={<FencerView />} /> */}
          <Route path={"/journal/fencer/create"} element={<CreateFencer />} />
          {/* <Route path={"/journal/fencer/:slug"} element={<FencerView />} /> */}
          <Route path={"/journal/lessons"} element={<Lessons />} />
          <Route path={"/journal/lesson/create"} element={<CreateLesson />} />
          <Route path={"/journal/lesson/:slug"} element={<LessonView />} />


          {/*  Error  */}
          <Route path="/404" element={<Error404 />} />
          {/* <Route path="*" element={<Navigate replace to="/404" />} /> */}
          <Route path="*" element={<Error404 />} />
          <Route path="test" element={<Test />} />

        </Routes>
        <FooterPage />
      </BrowserRouter>
    </GoogleOAuthProvider>
  )
}

export default App;
