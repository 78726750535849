import { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "../../redux/authSlice";
import axios from "axios";
import { BoutForm } from "../../components/forms/BoutForm";
import { eventType, boutType, fencerListType } from "../../constants/VarTypes";
import { getNewRefreshToken } from "../../hooks/utils";
import swal from 'sweetalert';
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
export const Bouts = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { eventSlug } = useParams();
  const boutscurrentUrl = location.pathname;
  const [dataUpdate, setDataUpdate] = useState<boolean>(false);
  const [loadingBouts, setLoadingBouts] = useState<boolean>(true);
  const [dataReceived, setDataReceived] = useState(false);
  const [slugData, setSlugData] = useState<string>("");
  const [openData, setOpenData] = useState(false);
  const [bouts, setBouts] = useState<[boutType] | []>([]);
  const [fencerupdate, setfencerupdate] = useState(false)
  const [fencerList, setFencerList] = useState<fencerListType>({
    abc: "no fencers",
  });
  const [event, setEvent] = useState<eventType>({
    id: 99,
    slug: "",
    name: "",
    date: "",
    weapon: "",
    eventLevel: "",
    notes: "",
    tourn_info: {
      name: "",
      slug: ""
    }
  });


  const getData = async () => {
    setLoadingBouts(true);
    if (dataReceived === false) {
      await getNewRefreshToken();
      const result = await axios.get("api/events/detail/" + eventSlug, {
        withCredentials: true,
      });
      setDataReceived(true);
      setEvent(result.data);
      setFencerList(
        result.data.fencers.reduce(
          (
            result: fencerListType,
            item: { slug: string; fencer_name: string }
          ) => {
            result[item.slug] = item.fencer_name;
            return result;
          },
          {}
        )
      );

      setBouts(
        result.data.bouts.map((value: any, key: number) => {
          return {
            id: key,
            slug: value.slug,
            winnerIsA: value.winner_is_a,
            fencerA: value.fencer_a,
            fencerB: value.fencer_b,
            scoreA: value.score_a,
            scoreB: value.score_b,
            fencerAHand: value.fencer__a_hand,
            fencerBHand: value.fencer_b_hand,
            fencerAYellowCard: value.fencer_a_yellow_card,
            fencerBYellowCard: value.fencer_b_yellow_card,
            fencerARedCard: value.fencer_a_red_card,
            fencerBRedCard: value.fencer_b_red_card,
            fencerABlackCarded: value.fencer_a_black_card,
            fencerBBlackCarded: value.fencer_b_black_card,
            fencerAPassivity: value.fencer_a_passivity,
            fencerBPassivity: value.fencer_b_passivity,
            fencerAMedical: value.fencer_a_medical,
            fencerBMedical: value.fencer_b_medical,
            fencerAVideoUsed: value.fencer_a_video_used,
            fencerBVideoUsed: value.fencer_b_video_used,
            fencerAFootwork: value.fencer_a_footwork,
            fencerBFootwork: value.fencer_b_footwork,
            fencerABladework: value.fencer_a_bladework,
            fencerBBladework: value.fencer_b_bladework,
            fencerADistance: value.fencer_a_distance,
            fencerBDistance: value.fencer_b_distance,
            fencerATechnique: value.fencer_a_technique,
            fencerBTechnique: value.fencer_b_technique,
            fencerAEnergy: value.fencer_a_energy,
            fencerBEnergy: value.fencer_b_energy,
            fencerANotes: value.fencer_a_notes,
            fencerBNotes: value.fencer_b_notes,
            referee: value.referee,
            boutFormat: value.bout_format,
            round: value.round,
            notes: value.notes,
            public: value.public,
            shareCoach: value.share_coach,
            deleted: value.deleted,
          };
        })
      );
    }
    setLoadingBouts(false);
  };

  useEffect(() => {
    getData();
    setDataUpdate(false)
  }, [dataUpdate]);

  function handleClickOpen(
    event: React.MouseEvent<HTMLButtonElement>,
    slug: string
  ) {
    setSlugData(slug);
    // setSlug(slug);
    setOpenData(true);
    // setShowTour(false)
  }

  const handleDeleteClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
    slug: string
  ) => {
    event.preventDefault();
    // if(auth){
    try {
      const dataDelete = await axios.delete(`api/bouts/delete/${slug}/`, {
        withCredentials: true,
      });
      setDataUpdate(true)
      setfencerupdate(true);
      setDataReceived(false);
    }
    catch (error: any) {
      swal({
        title: "Error",
        text: error.response?.data?.detail,
        icon: "warning",
        dangerMode: true,
      })
    }
  }

  // const handleClose = () => {
  //   setOpenData(false);
  //   setSlugData("");
  // };

  function eventDetail() {
    return (
      <>
        <div className="container breadcrumbs">
          <div className="backtotournament">
            <p
              className="initialclass"
              style={{ float: "left", marginRight: "5px" }}
              onClick={() => navigate("/journal/tournaments")}
            >
              Tournaments /{" "}
            </p>
            <p 
              onClick={()=>navigate(`/journal/events/${event.tourn_info.slug}`)}
              style={{ float: "left", marginRight: "5px" }}
              >
              {event.tourn_info.name} {" "}/
            </p>
            <span>
              {event.name}
            </span>
          </div>
        </div>

        <div className="boutformnew">
          <div className="container boutform">
            {event.name ? <h1 className="bouttitle">{event.name}</h1> : <></>}
            {event.date ? <p className="detail">{event.date}</p> : <></>}
            {event.weapon ? <p className="detail">{event.weapon}</p> : <></>}
            {event.eventLevel ? (
              <p className="detail">l{event.eventLevel}</p>
            ) : (
              <></>
            )}
            {event.notes ? <p className="detail">{event.notes}</p> : <></>}
          </div>
        </div>
      </>
    );
  }

  function boutList() {
    if (dataReceived === false) {
      getData()
      return (
        <div className="container">
          <h1>Loading bout information... </h1>
        </div>
      );
    }
    
    if (bouts.length === 0 && dataReceived === true) {
      return (
        <div className="container">
          <h3 className="bouttitle">
            You do not have any bouts yet. Enter an bout below!
          </h3>
        </div>
      );
    }

    return Object.entries(bouts).map(([key, value]) => (
      <div key={key} className="container">
        <div className='bout-cursorcls' onClick={()=>navigate(`/journal/bout/${value.slug}`)}>

          <h2 >
            {value.fencerA} 
            <br />
            vs. 
            <br />
            {value.fencerB}
          </h2>

          <p style={{ display: 'flex', justifyContent: 'center' }}>
            {value.scoreA}{" "}
            {value.winnerIsA && 
              <EmojiEventsIcon style={{ color: 'white', fontSize: '17px', marginTop: '2px' }} />
            }{" "}
            - {value.scoreB}{" "}
            {!value.winnerIsA && 
              <EmojiEventsIcon style={{ color: 'white', fontSize: '17px', marginTop: '2px' }} />
            }
          </p>

          <p >
            <b>Notes: </b>
            {value.notes}</p>
        </div>
        <hr className="boder-new" />
        <div className="editDeletebtn editDeletebtn-B">
          <button onClick={(event) => handleClickOpen(event, value.slug)}>
            <EditIcon style={{ color: 'white', fontSize: '20px', marginRight: '5px' }} />
          </button>
          <button onClick={(event) => handleDeleteClick(event, value.slug)}>
            <DeleteIcon style={{ color: 'white', fontSize: '20px', marginLeft: '5px' }} />
          </button>
        </div>
      </div>
    ));
  }

  return (
    <>
      {eventDetail()}
      <div className="bout-title">
        {loadingBouts ? (
          <div className="loading-content">Loading Bouts...</div>
        ) : (
          <>{boutList()}</>
        )}
      </div>
      <div className="container cartdiv-t">
        <BoutForm
          fencerList={fencerList}
          setfencerupdate={setfencerupdate}
          slugData={slugData}
          setSlugData={setSlugData}
          setOpenData={setOpenData}
          openData={openData}
          setDataUpdate={setDataUpdate}
          setDataReceived={setDataReceived}
        />
      </div>
      {/* <Dialog className="profileImgDialognew" open={openData} onClose={handleClose}>
        <DialogContent className="image_and_namesinvite">
          <span className="crossTagsDiv">
            {" "}
            <i onClick={handleClose} className="fa fa-times-circle"></i>
          </span>
          <div className="container">
            <BoutForm
              fencerList={fencerList}
              setfencerupdate={setfencerupdate}
              slugData={slugData}
              setSlugData={setSlugData}
              setOpenData={setOpenData}
              openData={openData}
            />
          </div>
        </DialogContent>
      </Dialog> */}
    </>
  );
};
