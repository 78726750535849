import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

export const ProfileSidebar = (props: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean
}) => {

  const [fullname, setFullname] = useState<string>('');
  const [tab, setTab] = useState<number>(1)

  useEffect(() => {
    const data = localStorage.getItem('userData')
    if (data) {
      const id = JSON.parse(data)
      let name = id.first_name + " " + id.last_name
      setFullname(name)
    }
  })
  const handleclick = (num: number) => {
    setTab(num);
    props.setOpen(false)
  }
  return (
    <>
      <div className='sidebar'>
        <img className='sidebar-img' src='https://images.pexels.com/photos/2007647/pexels-photo-2007647.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt='profile-img' />
        <h2 className='sidebar-h2'>{fullname}</h2>
        <hr style={{ margin: '0 35px' }} />
      </div>
      <div className="sidebar-links">
        <Link
          to='/profile/friends'
          className={tab === 1 ? 'sideTab active' : 'sideTab'}
          onClick={() => handleclick(1)}
        >
          Friends
        </Link>

        <Link
          to='/profile/setting'
          className={tab === 2 ? 'sideTab active' : 'sideTab'}
          onClick={() => handleclick(2)}
        >
          Setting
        </Link>
      </div>
    </>
  )
}

