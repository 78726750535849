import React from 'react'
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNewRefreshToken } from "../../hooks/utils";
import swal from "sweetalert";
import { setAuth } from "../../redux/authSlice";
import { RootState } from "../../redux/store";
import { getRefreshToken } from "../../interceptors/axios";
import { PushNote } from "../../components/PushNote";
import {
  Dialog,
  DialogContent,
} from "@material-ui/core";

export const Home = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("You are not Logged In");
  const dispatch = useDispatch();
  // const auth = useSelector((state: RootState) => state.auth.value);

  const [isVisible, setIsVisible] = useState(true);

  type Position = "absolute" | "fixed" | "relative" | "static" | "sticky";

  const bannerStyle: React.CSSProperties = {

    backgroundColor: "#333",
    color: "white",
    padding: "10px",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  // const [popup, setPopup] = useState(true);

  // Old code which might be important but I hope is not
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const { data } = await axios.get("api/auth/user");
  //       console.log("user data:  ", data)
  //       if (data){
  //         localStorage.setItem("userData", JSON.stringify(data));
  //         setMessage(`Hi ${data.first_name} ${data.last_name}`);
  //         localStorage.setItem("login", 'true');
  //         dispatch(setAuth(true));
  //       }
  //       // data.is_first_login && setPopup(true)
  //     } catch (e) {
  //       setMessage("You are not authenticated");
  //       dispatch(setAuth(false));
  //     }
  //   })();
  // }, [message]);

  useEffect(() => {
    window.scroll(0, 0);
    // getRefreshToken()
    let variable = localStorage.getItem("cookiesdata");
    if (variable == "true") {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, []);
  
  // const handleSub = async() => {
  //   try {
  //     await getNewRefreshToken();
  //     const { data } = await axios.get("api/auth/update-login");
  //     console.log("data.....", data)
  //   } catch (error) {
  //     console.log('error...', error)
  //   }
  //   navigate('/subscription')
  // }
  // const handleCancel = async () => {
  //   try {
  //     await getNewRefreshToken();
  //     const { data } = await axios.get("api/auth/update-login");
  //     console.log("data.....", data)
  //   } catch (error) {
  //     console.log('error...', error)
  //   }
  //   setPopup(false)
  // }

  const handleAccept = () => {
    // set a cookie to remember that the user has accepted the GDPR policy
    setIsVisible(false);
    localStorage.removeItem("cookiesdata");
    document.cookie = "gdpr_accepted=true; path=/";
  };

  return (
    <>
      <div className="backgroundimage">
        {isVisible && (
          <div>
            <div style={bannerStyle}>
              <p className="accepttext">This site uses cookies to provide you with a better browsing experience. By clicking accept, you agree to our use of cookies.</p>
              <button className="acceptbtn4" onClick={handleAccept}>Accept</button>
            </div>
          </div>
        )}

        
          <div className="center111111">
            <div className="container1">
              <h2 className="bennartext">
                  Ignite Your Fencing Journey with
                <br />
                  Finalstrip.  
                </h2>
                <br />
              <p className="bennartext">
                Whether you are a passionate beginner, or a world class athlete, Finalstrip offers a comprehensive range of features and resources to elevate your fencing journey to new heights.
              </p>
              <Link className="morebtn" to={"/register"} >Start Now</Link> 
            </div>
          </div>
        

      </div>

      <div className="home-page">
        <div>
          <p className='home-headingg'>WHY<span style={{ fontWeight: '600' }}><br />CHOOSE FINALSTRIP?</span></p>
        </div>
        <div className="home-page-flex">
          <div className="j-mr">
            <img className="home-img" src="/6.jpg" alt="image"></img>
          </div>
          <div className="j-ml spacing textAlign-center">
            <h2 className="home-heading ">Take Notes From Classes, Lessons and Tournaments</h2>
            <p className="para-justify" >
            Keep a detailed record of your bouts and lessons. Analyze your performance, track your progress, and identify areas for improvement.  
            Search through them easily and prepare for your next bout.
            </p>
          </div>
        </div>
        <div className="home-page-flex col-reverse">
          <div className='j-mr spacing textAlign-center'>
            <h2 className="home-heading">Video Upload for Analysis</h2>
            <p className="para-justify">
            Easily upload your fencing videos and share with your coaches to receive insightful feedback. Gain perspective on 
            your technique and make tactical adjustments to improve performance.
            </p>
          </div>
          <div className='j-ml'>
            <img className="home-img" src="/foil_salute.jpeg" alt="image"></img>
          </div>
        </div>
        <div className="home-page-flex">
          <div className="j-mr">
            <img className="home-img" src="/home3.jpg" alt="image"></img>
          </div>
          <div className="j-ml spacing textAlign-center">
            <h2 className="home-heading">Connect With Other Fencers</h2>
            <p className="para-justify">
            Connect with friends, fellow fencers, and coaches effortlessly. Share your notes, videos, and insights 
            to get valuable input and support from your fencing community.
            </p>
          </div>
        </div>
        <div className="home-page-flex col-reverse">
          <div className='j-mr spacing textAlign-center'>
            <h2 className="home-heading">Checklists and Guides</h2>
            <p className="para-justify">
            Finalstrip also has quick guides and checklists to prepare for competitions, fix equipment, and more!
            </p>
          </div>
          <div className='j-ml'>
            <img className="home-img" src="/5.jpg" alt="image"></img>
          </div>
        </div>
      </div>
      <div className="back-divs">
        <div className="connectFromHome">
          <h2><b>Unleash Your Full Potential</b><br></br></h2>
          <p className="text-14">
          Become part of our community of passionate and dedicated fencers. Our app 
          provides you with essential tools and expert insights to enhance your technique, 
          outsmart your opponents, and elevate your fencing career to new heights.
          </p>
          <Link to={"/register"} className="linktoconnect">
            <button type="button" className="Connectbtn">Start Now</button>
          </Link>
        </div>
      </div>
      <div className="back-div hidden">
        <div className="connectFromHome">
          <h2>JOIN<br></br>
            <strong>OUR TEAM</strong>
          </h2>
          <p className="text-14">WE ARE ALWAYS LOOKING FOR PEOPLE WHO CAN BRING ENTHUSIASM AND FRESH PERSPECTIVES
            TO OUR TEAM. IF THAT’S YOU, THEN THE CHANCES ARE YOU’D THRIVE AT FINALSTRIP AND
            WE’D LOVE TO HEAR FROM YOU.
          </p>
          <Link to={"/contactus"} className="linktoconnect">
            <button type="button" className="Connectbtn ">Connect with Us</button>
          </Link>
        </div>
      </div>
    </>
  );
};
