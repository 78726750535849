import React, { useState, useEffect, SyntheticEvent } from "react";
import { FencerForm } from "../../components/forms/FencerForm";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import { FormTextElement } from "../../components/forms/form elements/FormTextElement";
import { FormRatingElement } from "../../components/forms/form elements/FormRatingElement";
import { FormSelectElement } from "../../components/forms/form elements/FormSelectElement";
import {
  RATING_NUMBER_CHOICES,
  REGION_CHOICES,
  HAND_CHOICES,
  GRIP_CHOICES,
  REF_RATING_CHOICES,
  USAF_RATING_CHOICES,
} from "../../constants/VarOptions";
import { getNewRefreshToken } from "../../hooks/utils";
import axios from "axios";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import swal from 'sweetalert';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Brush } from 'recharts';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { Http2ServerRequest } from "http2";

interface Fencer {
  slug: string;
  first_name: string;
  last_name: string
  // Add other properties as needed
}

interface FencerViewProps {
  fencerList: Fencer[];
  onDelete: (slug: string) => void;
  onupdate: (updatevalue: string) => void;
}
interface Data {
  sort_by: string,
  my_score: number,
  opponent_score: number,
  win_percentage: number,
  total_bouts: number,
  indicator: number
  line: number
}
export const FencerView: React.FC<FencerViewProps> = ({
  fencerList,
  onDelete,
  onupdate,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedSlug, setSelectedSlug] = useState<string>("");
  const [usaFencingInfo, setUSAFencingInfo] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [club, setClub] = useState<string>("");
  const [club2, setClub2] = useState<string>("");
  const [school, setSchool] = useState<string>("");
  const [division, setDivision] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [nationality, setNationality] = useState<string>("");
  const [handed, setHanded] = useState<string>("");
  const [grip, setGrip] = useState<string>("");
  const [ratingEpee, setRatingEpee] = useState<string>("");
  const [ratingSabre, setRatingSabre] = useState<string>("");
  const [ratingFoil, setRatingFoil] = useState<string>("");
  const [refRatingEpee, setRefRatingEpee] = useState<string>("");
  const [refRatingSabre, setRefRatingSabre] = useState<string>("");
  const [refRatingFoil, setRefRatingFoil] = useState<string>("");
  const [customRating, setcustomRating] = useState<string>("");
  const [validations, setValidations] = useState(false);
  // const [timing, setTiming] = useState<string>('')
  // const [distance, setDistance] = useState<string>('')
  // const [footwork, setFootwork] = useState<string>('')
  // const [bladework, setBladework] = useState<string>('')
  // const [endurance, setEndurance] = useState<string>('')
  // const [strength, setStrength] = useState<string>('')
  const [tacticDescript, setTacticDescript] = useState<string>("");
  const [favActions, setFavActions] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const handleDelete = (slug: string) => {
    // console.log("Delete fencer with slug:", slug);
    onDelete(slug);
  };
  // console.log("fencerList--------------",fencerList);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpen = async (
    event: React.MouseEvent<HTMLButtonElement>,
    slug: string
  ) => {
    event.stopPropagation();
    setSelectedSlug(slug);

    try {
      const response = await axios.get(`api/fencers/details/${slug}/`, {
        withCredentials: true,
      });
      console.log("fencer view api .....", response)
      const result = response.data; // Access the data property of the response
      setOpen(true);
      setFirstName(result.first_name);
      setLastName(result.last_name);
      setClub(result.club);
      setClub2(result.club2);
      setSchool(result.school);
      setDivision(result.division);
      setRegion(result.region);
      setNationality(result.nationality);
      setHanded(result.handed);
      setGrip(result.primary_grip);
      setRatingEpee(result.usaf_rating_epee);
      setRatingSabre(result.usaf_rating_sabre);
      setRatingFoil(result.usaf_rating_foil);
      setRefRatingEpee(result.ref_rating_epee);
      setRefRatingSabre(result.ref_rating_sabre);
      setRefRatingFoil(result.ref_rating_foil);
      setcustomRating(result.custom_rating);
      setTacticDescript(result.tactical_description);
      setFavActions(result.favorite_actions);
      setNotes(result.notes);
    } catch (error) {
      console.log("Error fetching fencer details:", error);
    }
  };
  const handleFencerFormClose = () => {
    setSelectedSlug("");
    setOpen(false);
  };
  const submit = async (e: SyntheticEvent) => {
    e.preventDefault(); // prevents page from reloading.
    if (firstName && lastName && region) {
      try {
        await getNewRefreshToken();
        const { data } = await axios.patch(
          `api/fencers/update/${selectedSlug}/`,
          {
            first_name: firstName,
            last_name: lastName,
            club,
            club2,
            school,
            division,
            region,
            nationality,
            handed,
            primary_grip: grip,
            usaf_rating_epee: ratingEpee,
            usaf_rating_sabre: ratingSabre,
            usaf_rating_foil: ratingFoil,
            ref_rating_epee: refRatingEpee,
            ref_rating_sabre: refRatingSabre,
            ref_rating_foil: refRatingFoil,
            custom_rating: customRating,
            tactical_description: tacticDescript,
            favorite_actions: favActions,
            notes,
          },
          { withCredentials: true }
        );
        console.log(data)
        setValidations(false);
        setFirstName("");
        setLastName("");
        setClub("");
        setClub2("");
        setSchool("");
        setDivision("");
        setRegion("");
        setNationality("");
        setHanded("");
        setGrip("");
        setRatingEpee("");
        setRatingSabre("");
        setRatingFoil("");
        setRefRatingEpee("");
        setRefRatingSabre("");
        setRefRatingFoil("");
        setcustomRating("");
        setTacticDescript("");
        setFavActions("");
        setNotes("");
        setOpen(false);
        setSelectedSlug("");
        onupdate("true");
        swal({
          title: "Fencer Update",
          icon: "success",
          timer: 3000,
        })
      } catch (error: any) {
        swal({
          title: "Error",
          text: error.response?.data?.detail,
          icon: "warning",
          dangerMode: true,
        })
      }

    } else {
      setValidations(true);
    }
  };
  const fencerName = (fname: string, lname: string) => {
    localStorage.removeItem("breadcrumbsinitial");
    localStorage.setItem("breadcrumbsfencer", "Fencer");
    localStorage.setItem("breadcrumbs", fname + " " + lname);
  };
  const [month1, setMonth1] = useState<boolean>(true);
  const [year1, setYear1] = useState<boolean>(false);
  const [quarter1, setQuarter1] = useState<boolean>(false);
  const [month2, setMonth2] = useState<boolean>(true);
  const [year2, setYear2] = useState<boolean>(false);
  const [quarter2, setQuarter2] = useState<boolean>(false);
  const [month3, setMonth3] = useState<boolean>(true);
  const [year3, setYear3] = useState<boolean>(false);
  const [quarter3, setQuarter3] = useState<boolean>(false);
  const [sort1, setSort1] = useState<string>('month');
  const [sort2, setSort2] = useState<string>('month');
  const [sort3, setSort3] = useState<string>('month');
  const [dataReceived1, setdataReceived1] = useState<boolean>(false);
  const [dataReceived2, setdataReceived2] = useState<boolean>(false);
  const [dataReceived3, setdataReceived3] = useState<boolean>(false);
  const [graphData1, setGraphData1] = useState<Data[]>([]);
  const [graphData2, setGraphData2] = useState<Data[]>([]);
  const [graphData3, setGraphData3] = useState<Data[]>([]);
  const [chartWidth, setChartWidth] = useState(660);
  const [chartHeight, setChartHeight] = useState(300);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768){
        setChartWidth(330);
        setChartHeight(190);
      }
      else if (window.innerWidth <= 1170) {
        setChartWidth(390);
        setChartHeight(220);
      } 
      else {
        setChartWidth(420);
        setChartHeight(250);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fetchData = async () => {
    await getNewRefreshToken();
    if (dataReceived1 === false) {
      try {
        const result = await axios.get(`/api/metrics/bout-chart/?filter_by=${sort1}`, {
          withCredentials: true,
        });
        setGraphData1(result.data)
        setdataReceived1(true)
      } catch (error) {
        console.error("Error fetching fencers:", error);
      }
    }
    if (dataReceived2 === false) {
      try {
        // await getNewRefreshToken();
        const result = await axios.get(`/api/metrics/bout-chart/?filter_by=${sort2}`, {
          withCredentials: true,
        });
        setGraphData2(result.data)
        setdataReceived2(true)
      } catch (error) {
        console.error("Error fetching fencers:", error);
      }
    }
    if (dataReceived3 === false) {
      try {
        // await getNewRefreshToken();
        const result = await axios.get(`/api/metrics/bout-chart/?filter_by=${sort3}`, {
          withCredentials: true,
        });
        setGraphData3(result.data)
        setdataReceived3(true)
      } catch (error) {
        console.error("Error fetching fencers:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [dataReceived1, dataReceived2, dataReceived3])

  const handleSortWin = (data: string): void => {
    if (data === 'month') {
      setSort1(data);
      setMonth1(true)
      setYear1(false)
      setQuarter1(false)
    }
    else if (data === 'year') {
      setSort1(data);
      setMonth1(false)
      setYear1(true)
      setQuarter1(false)
    }
    else if (data === 'quarter') {
      setSort1(data);
      setMonth1(false)
      setYear1(false)
      setQuarter1(true)
    }
    setdataReceived1(false)
  };

  const handleSortIndi = (data: string): void => {
    if (data === 'month') {
      setSort2(data);
      setMonth2(true)
      setYear2(false)
      setQuarter2(false)
    }
    else if (data === 'year') {
      setSort2(data);
      setMonth2(false)
      setYear2(true)
      setQuarter2(false)
    }
    else if (data === 'quarter') {
      setSort2(data);
      setMonth2(false)
      setYear2(false)
      setQuarter2(true)
    }
    setdataReceived2(false)
  };

  const handleSortBouts = (data: string): void => {
    if (data === 'month') {
      setSort3(data);
      setMonth3(true)
      setYear3(false)
      setQuarter3(false)
    }
    else if (data === 'year') {
      setSort3(data);
      setMonth3(false)
      setYear3(true)
      setQuarter3(false)
    }
    else if (data === 'quarter') {
      setSort3(data);
      setMonth3(false)
      setYear3(false)
      setQuarter3(true)
    }
    setdataReceived3(false)
  };

  function fencerStats(){
    return(
      <div className="graph-section">
        <div className="graph-section1">
          <h3 style={{ textAlign: 'center' }}>Win Percentage</h3>
          {graphData1.length <= 5 ? <div className="not-enough">
            <div>There is not enough data to display</div>
          </div>
            : <div style={{marginRight:'30px'}}> <LineChart width={chartWidth} height={chartHeight} data={graphData1}>
              <XAxis dataKey="sort_by" />
              <YAxis/>
              <CartesianGrid />
              <Tooltip />
              <Line type="monotone" dataKey="win_percentage" stroke="#ed6221" />
            </LineChart>
            </div>
          }
          <div className='fencer-chart'>
            <p style={{ fontSize: '15PX', fontWeight: '600' }}>Sort By:</p>
            <button
              className={month1 ? "fencer-btns " : "fencer-btns fencer-btns-line"}
              onClick={() => handleSortWin('month')}>Month</button>
            <button
              className={year1 ? "fencer-btns " : "fencer-btns  fencer-btns-line"}
              onClick={() => handleSortWin('year')}>Year</button>
            <button
              className={quarter1 ? "fencer-btns " : "fencer-btns  fencer-btns-line"}
              onClick={() => handleSortWin('quarter')}>Quarter</button>
          </div>
        </div>

        <div className="graph-section1">
          <h3 style={{ textAlign: 'center' }}>Indicator</h3>
          {graphData2.length <= 5 ? <div className="not-enough">
            <div>There is not enough Data to display</div>
          </div>
            : <div style={{ marginRight: '30px' }}><LineChart width={chartWidth} height={chartHeight} data={graphData2}>
              <XAxis dataKey="sort_by" />
              <YAxis />
              <CartesianGrid />
              <Tooltip />
              <Line type="monotone" dataKey="indicator" stroke="#ed6221" />
            </LineChart></div>}
          <div className='fencer-chart'>
            <p style={{ fontSize: '15PX', fontWeight: '600' }}>Sort By:</p>
            <button
              className={month2 ? "fencer-btns " : "fencer-btns fencer-btns-line"}
              onClick={() => handleSortIndi('month')}>Month</button>
            <button
              className={year2 ? "fencer-btns " : "fencer-btns  fencer-btns-line"}
              onClick={() => handleSortIndi('year')}>Year</button>
            <button
              className={quarter2 ? "fencer-btns " : "fencer-btns  fencer-btns-line"}
              onClick={() => handleSortIndi('quarter')}>Quarter</button>
          </div>
        </div>

        <div className="graph-section1">
          <h3 style={{ textAlign: 'center' }}>Total Bouts</h3>
          {graphData3.length <= 5 ? <div className="not-enough">
            <div>There is not enough Data to display</div>
          </div>
            : <div style={{ marginRight: '30px' }}><LineChart width={chartWidth} height={chartHeight} data={graphData3}>
              <XAxis dataKey="sort_by" />
              <YAxis />
              <CartesianGrid />
              <Tooltip />
              <Line type="monotone" dataKey="total_bouts" stroke="#ed6221" />
            </LineChart></div>}

          <div className='fencer-chart'>
            <p style={{ fontSize: '15PX', fontWeight: '600' }}>Sort By:</p>
            <button
              className={month3 ? "fencer-btns " : "fencer-btns fencer-btns-line"}
              onClick={() => handleSortBouts('month')}>Month</button>
            <button
              className={year3 ? "fencer-btns " : "fencer-btns  fencer-btns-line"}
              onClick={() => handleSortBouts('year')}>Year</button>
            <button
              className={quarter3 ? "fencer-btns " : "fencer-btns  fencer-btns-line"}
              onClick={() => handleSortBouts('quarter')}>Quarter</button>
          </div>
        </div>
      </div>
    )
  }


  return (
    <>
      <h2 className="headingerofpage fencerHeading-marginTop">Statistics</h2>
      {graphData1.length > 5 ? 
        fencerStats(): 
        <div className="not-enough"><div>Need 5 or more events to show stats</div></div>
      }
      
      <h2 className="headingerofpage fencerHeading-marginTop">Fencers</h2>
      <div className="card-category-1">
        {fencerList.length <= 0 && (
          <div className="container">
            <h1 className="tourtext" style={{ textAlign: 'center' }}>You do not have any fencer</h1>
          </div>
        )}

        {fencerList.map((fencer, i) => (
          <div key={i} className="basic-card basic-card-aqua">
            <div className="card-content" >
              <Link to={`/journal/fencersbouts/${fencer.slug}`} className="navbar-link" >
                <h2 className="card-text fencer-heading-height" onClick={() => fencerName(fencer.first_name, fencer.last_name)}>{fencer.first_name} {fencer.last_name}</h2>
              </Link>
            </div>

            <div className="card-link action-btn">
              <div className="action-btns">
                <a href="#" title="Delete">
                  <button
                    className="delete-btn"
                    onClick={() => handleDelete(fencer.slug)}
                  >
                    <DeleteIcon style={{ color: 'white', fontSize: '20px' }} />
                  </button>
                </a>
              </div>

              <div className="action-btns">
                <a href="#" title="Edit">
                  <button
                    className="edit-btn"
                    onClick={(event) => handleClickOpen(event, fencer.slug)}
                  >
                    <EditIcon style={{ color: 'white', fontSize: '20px' }} />
                  </button>
                </a>
              </div>
              
            </div>
          </div>
        ))}
      </div>

      <Dialog open={open} onClose={handleClose}>
        <h1 className="h1 eventtitle1">Edit Fencer<span className="crossTagsDiv2">
          {" "}
          <CloseIcon onClick={handleClose} className="icon-pointer" style={{ fontSize: '25px' }} />
        </span></h1>

        <DialogContent>
          <DialogContentText>
            <form className="fencerviewdiv" onSubmit={submit}>
              <div className="Please-error">
                <FormTextElement setValue={setFirstName} value={firstName} elementName="first" placeholder="" labelText="First Name" />
                {validations && !firstName && <p className="testerror">Please enter First name</p>}
              </div>
              <div className="Please-error">
                <FormTextElement setValue={setLastName} value={lastName} elementName="last" placeholder="" labelText="Last Name" />
                {validations && !lastName && <p className="testerror11">Please enter Last name</p>}
              </div>
              <FormSelectElement setValue={setHanded} elementName="handed" value={handed} selectOptions={HAND_CHOICES} placeholder="" labelText="Handedness" />
              <FormSelectElement setValue={setGrip} elementName="grip" value={grip} selectOptions={GRIP_CHOICES} placeholder="" labelText="Grip Preference" />
              <FormTextElement setValue={setClub} elementName="club" value={club} placeholder="" labelText="Club" />
              <FormTextElement setValue={setClub2} elementName="club2" value={club2} placeholder="" labelText="Secondary Club" />
              <FormTextElement setValue={setSchool} elementName="school" value={school} placeholder="" labelText="School" />
              <FormTextElement setValue={setDivision} elementName="division" value={division} placeholder="" labelText="Division" />
              <div className="Please-error">
                <FormSelectElement setValue={setRegion} elementName="region" value={region} selectOptions={REGION_CHOICES} placeholder="" labelText="Region" />
                {validations && !region && <p className="testerror1">Please Select Region</p>}
              </div>
              <FormTextElement setValue={setNationality} value={nationality} elementName="nationality" placeholder="" labelText="Nationality" />
              <FormSelectElement setValue={setRatingEpee} value={ratingEpee} elementName="ratingEpee" selectOptions={USAF_RATING_CHOICES} placeholder="" labelText="Epee Rating" />
              <FormSelectElement setValue={setRatingSabre} value={ratingSabre} elementName="ratingSabre" selectOptions={USAF_RATING_CHOICES} placeholder="" labelText="Sabre Rating" />
              <FormSelectElement setValue={setRatingFoil} value={ratingFoil} elementName="ratingFoil" selectOptions={USAF_RATING_CHOICES} placeholder="" labelText="Foil Rating" />
              <FormSelectElement setValue={setRefRatingEpee} value={refRatingEpee} elementName="refRatingEpee" selectOptions={REF_RATING_CHOICES} placeholder="" labelText="Epee Referee Rating" />
              <FormSelectElement setValue={setRefRatingSabre} value={refRatingSabre} elementName="refRatingSabre" selectOptions={REF_RATING_CHOICES} placeholder="" labelText="Sabre Referee Rating" />
              <FormSelectElement setValue={setRefRatingFoil} value={refRatingFoil} elementName="refRatingFoil" selectOptions={REF_RATING_CHOICES} placeholder="" labelText="Foil Referee Rating" />
              <FormTextElement setValue={setcustomRating} value={customRating} elementName="customRating" placeholder="" labelText="Custom Rating" />
              {/* <FormRatingElement setValue={setTiming} elementName='timing' labelText='Timing'/>
                <FormRatingElement setValue={setDistance} elementName='distance' labelText='Distance'/>
                <FormRatingElement setValue={setFootwork} elementName='footwork' labelText='Footwork' />
                <FormRatingElement setValue={setBladework} elementName='bladework' labelText='Bladework' />
                <FormRatingElement setValue={setStrength} elementName='strength' labelText='Strength' /> */}
              <FormTextElement setValue={setTacticDescript} value={tacticDescript} elementName="tacticalDescription" placeholder="" labelText="Tactical Description" />
              <FormTextElement setValue={setFavActions} value={favActions} elementName="favActions" placeholder="" labelText="Favorite Actions" />
              <FormTextElement setValue={setNotes} value={notes} elementName="notes" placeholder="" labelText="Notes" />
              
              <button className="submit-button" type="button" onClick={submit}>
                Update Fencer
              </button>

            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
