import { useState, SyntheticEvent } from "react";
import { Navigate, useParams } from "react-router-dom";
import axios from "axios";
import PasswordIcon from '@mui/icons-material/Password';


export const Reset = () => {
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [redirect, setRedirect] = useState(false);
    const {token} = useParams();


    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();

        await axios.post('auth/reset', {
            token,
            password,
            password_confirm: passwordConfirm
        });
        setRedirect(true)
    }

    if(redirect){
        return <Navigate to="auth/login" />
    }
    
    return (
        <main className="loginPageWidthDiv">
              <div className="toploginpage">
              <div className="loginpage">
            <form onSubmit={submit}>
            <h1 className="h1 mb-3 fw-bold">Reset your password</h1>

            
            <label htmlFor="floatingPassword">Password</label>
            <div className="login-input-user">
            <div className="emailicon">
            <PasswordIcon style={{color:'white',fontSize:'20px'}}/>
                    </div>
                    <div className="email-input">
                <input type="password" className="form-control" id="floatingPassword" placeholder="Password"
                    onChange={e => setPassword(e.target.value)}
                />
               
            </div>
            </div>
            <label htmlFor="floatingPassword">Change Password</label>
            <div className="login-input-user">
            <div className="emailicon">
            <PasswordIcon style={{color:'white',fontSize:'20px'}}/>
                    </div>
                    <div className="email-input">
                <input type="password" className="form-control" id="floatingPasswordConfirm" placeholder="Password Confirm"
                    onChange={e => setPasswordConfirm(e.target.value)}
                />
               </div>
            </div>
            
            <button className="w-100 btn btn-lg btn-primary" type="submit">Submit Email</button>

            </form>
            </div>
            </div>
        </main>
    )
}