import { useState } from 'react';

export const FormCheckBoxElement = ({
  setValue,
  value,
  labelText,
}: {
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
  value: boolean;
  labelText: string;
}) => {
  // const [isChecked, setIsChecked] = useState(value);

  // const checkHandler = () => {
  //   const newValue = !isChecked;
  //   setIsChecked(newValue);
  //   setValue(newValue);
  // };
  const handleC = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked);
  };
  return (
    // <div className="toggleall">
    //   <label className="toggle">
    //     <span className="toggle-label">{labelText}</span>
    //     <input
    //       className="toggle-checkbox"
    //       type="checkbox"
    //       checked={isChecked}
    //       onChange={checkHandler}
    //     />
    //     <div className="toggle-switch" />
    //   </label>
    // </div>
    <div style={{display:'flex',alignItems:'center'}}>
      <input
        type="checkbox"
        onChange={handleC}
        checked={value}
      />
      <label style={{marginBottom:'-5px',marginLeft:'4px'}}>
        {labelText}
      </label>
    </div>

  );
};
