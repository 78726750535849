import React, { useState, useEffect } from 'react'
import { setAuth } from '../../redux/authSlice'
import { useDispatch } from 'react-redux';
import axios from "axios";
import { getNewRefreshToken } from "../../hooks/utils";
import { getRefreshToken } from "../../interceptors/axios";
import swal from 'sweetalert';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from "react-router-dom"
import CloseIcon from '@mui/icons-material/Close';
import { CSRF } from "../../components/forms/form elements/CSRF";
import { FormTextElement } from '../../components/forms/form elements/FormTextElement'
import { FormSelectElement } from "../../components/forms/form elements/FormSelectElement";
import { resourceLimits } from 'worker_threads';
import {
    RATING_NUMBER_CHOICES,
    REGION_CHOICES,
    HAND_CHOICES,
    GRIP_CHOICES,
    REF_RATING_CHOICES,
    USAF_RATING_CHOICES,
} from "../../constants/VarOptions";
import {
    Dialog,
    DialogContent,
    Collapse
} from "@material-ui/core";
import PortraitIcon from '@mui/icons-material/Portrait';

interface Subscription {
    first_name: string,
    last_name: string,
    subscription: string
}
interface RegisterData {
    club: string,
    club2: string,
    custom_rating: string,
    division: string,
    favorite_actions: string,
    first_name: string,
    handed: string,
    last_name: string,
    nationality: string,
    notes: string,
    primary_grip: string,
    ref_rating_epee: string,
    ref_rating_foil: string,
    ref_rating_sabre: string,
    region: number,
    school: string,
    shareable: string,
    slug: string,
    tactical_description: string,
    usaf_rating_epee: string,
    usaf_rating_foil: string,
    usaf_rating_sabre: string,
    usaf_num: string,
}
interface ProfileTab {
    first_name: string,
    last_name: string,
    email: string
}
export const Setting = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [userid, setuserid] = useState<number>()
    const [getOption, setGetOption] = useState('');
    const [constOptions, setconstOptions] = useState('');
    const [id, setid] = useState<number>(0)
    const [subscriptionOpen, setSubscriptionOpen] = useState(true);
    const [usafOpen, setusafOpen] = useState(false);
    const [privacyOpen, setPrivacyOpen] = useState(false);
    const [profiletab, setprofiletab] = useState(false);
    const [regiOpen, setRegiOpen] = useState(false);
    const [isfreeium, setIsfreeium] = useState<boolean>(false);
    const [fencer_me, setfencer_me] = useState<boolean>(false);
    const [is_custom, setIs_custom] = useState<boolean>(false);
    const [checked, setChecked] = useState(false);
    const [open, setopen] = useState(true);
    const [club, setClub] = useState<string>("");
    const [club2, setClub2] = useState<string>("");
    const [school, setSchool] = useState<string>("");
    const [division, setDivision] = useState<string>("");
    const [region, setRegion] = useState<string>("");
    const [nationality, setNationality] = useState<string>("");
    const [handed, setHanded] = useState<string>("");
    const [grip, setGrip] = useState<string>("");
    const [ratingEpee, setRatingEpee] = useState<string>("");
    const [ratingSabre, setRatingSabre] = useState<string>("");
    const [ratingFoil, setRatingFoil] = useState<string>("");
    const [refRatingEpee, setRefRatingEpee] = useState<string>("");
    const [refRatingSabre, setRefRatingSabre] = useState<string>("");
    const [refRatingFoil, setRefRatingFoil] = useState<string>("");
    const [customRating, setcustomRating] = useState<string>("");
    const [validations, setValidations] = useState(false);
    const [tacticDescript, setTacticDescript] = useState<string>("");
    const [favActions, setFavActions] = useState<string>("");
    const [notes, setNotes] = useState<string>("");
    const [usafNumber, setUsafNumber] = useState<number>(0);
    const [handedd, setHandedd] = useState<string>("");
    const [gripp, setGripp] = useState<string>("");
    const [validations_usaf, setValidations_usaf] = useState(false);

    const [subscriptionData, setsubscriptionData] = useState<Subscription>({
        first_name: '',
        last_name: '',
        subscription: '',
    });
    const [profileData, setProfileData] = useState<ProfileTab>({
        first_name: '',
        last_name: '',
        email: ''
    });
    const [registerData, setRegisterData] = useState<RegisterData>({
        club: '',
        club2: '',
        custom_rating: '',
        division: '',
        favorite_actions: '',
        first_name: '',
        handed: '',
        last_name: '',
        nationality: '',
        notes: '',
        primary_grip: '',
        ref_rating_epee: '',
        ref_rating_foil: '',
        ref_rating_sabre: '',
        region: 0,
        school: '',
        shareable: '',
        slug: '',
        tactical_description: '',
        usaf_rating_epee: '',
        usaf_rating_foil: '',
        usaf_rating_sabre: '',
        usaf_num: ''
    });

    useEffect(() => {
        window.scroll(0, 0);
        getRefreshToken();
        var myData = localStorage.getItem('userData');
        if (myData) {
            dispatch(setAuth(true));
        }
        const data = localStorage.getItem('userData');
        if (data) {
            const id = JSON.parse(data);
            setuserid(id.id)
        }
    }, [])

    const getData = async () => {
        try {
            await getNewRefreshToken();
            const response = await axios.get(`api/profiles/profile/?user=${userid}`, {
                withCredentials: true,
            })
            console.log('response', response.data)
            setGetOption(response.data[0].shareable || '')
            setconstOptions(response.data[0].shareable)
            setid(response.data[0].id)
        } catch (error) {
            console.error('API setting Error:', error);
        }
    }
    useEffect(() => {
        getData();
    }, [userid])

    const handleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        setGetOption(event.target.value)
    };

    const savePrivacy = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        try {
            await getNewRefreshToken();
            const response = await axios.put(`api/profiles/profile/${id}/`, {
                user: userid,
                shareable: getOption
            },
                { withCredentials: true })
            console.log('response', response)
            getData();
            swal({
                title: 'Success',
                timer: 3000,
                text: 'Privacy setting updated successfully',
                icon: 'success',
            })
        } catch (error) {
            console.error('API setting Error:', error);
        }
    }

    const fetchData = async () => {
        try {
            await getNewRefreshToken();
            const responseData = await axios.get('api/subscription/user-subscription/', {
                withCredentials: true,
            });
            setsubscriptionData(responseData?.data[0])
            console.log('responseData', responseData);
        } catch (error) {
            console.error('API setting Error:', error);
        }
    };
    const handleClickSubs = () => {
        navigate(`/subscription`)
    }

    const fetchData2 = async () => {
        try {
            await getNewRefreshToken();
            const result = await axios.get("api/fencers/fencer-usa/", {
                withCredentials: true,
            });
            setRegisterData(result.data)
            setProfileData(result.data)
            setfencer_me(result.data.fencer_is_me)
        } catch (error) {
            console.error('API setting Error:', error);
        }
    };
    useEffect(() => {
        const data = localStorage.getItem('userData');
        if (data) {
            const id = JSON.parse(data);
            if (id.subscription === '') {
                setIsfreeium(true)
            }
        }
        fetchData();
        fetchData2();
    }, []);

    const handleOpen = () => {
        setRegiOpen(true);
    };

    const handleClose = () => {
        setRegiOpen(false);
    };
    const submit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        await getNewRefreshToken();
        if (usafNumber && open && gripp && handedd) {
            try {
                const response = await axios.post(
                    "/api/fencers/create/",
                    {
                        // first_name: firstName,
                        // last_name: lastName,
                        first_name: 'coach',
                        last_name: '1',
                        usfa_membership: usafNumber,
                        handed: handedd,
                        primary_grip: gripp,
                        is_custom,
                        fencer_is_me: true,
                    },
                    { withCredentials: true }
                );
                console.log('response', response)
                swal({
                    title: "Success",
                    text: response?.data?.message,
                    icon: "success",
                    timer: 1000,
                });
                setUsafNumber(0)
                setHandedd("");
                setGripp("");
                setValidations_usaf(false)
                setRegiOpen(false);
                fetchData2();
            } catch (error: any) {
                console.log('error.....', error)
                if (error.response?.data[0]) {
                    swal({
                        text: error.response?.data[0],
                        className: "icon-popup"
                    })
                }
                if (error.response?.data?.email[0]) {
                    swal({
                        text: error.response?.data?.email[0],
                        className: "icon-popup"
                    })
                }

            }
        } else if (handed && grip && region && checked) {
            try {
                const response = await axios.post(
                    "/api/fencers/create/",
                    {
                        first_name: 'coach',//missing in api
                        last_name: 'plus1',//missing in api
                        club,
                        club2,
                        school,
                        division,
                        region,
                        nationality,
                        handed,
                        primary_grip: grip,
                        usaf_rating_epee: ratingEpee,
                        usaf_rating_sabre: ratingSabre,
                        usaf_rating_foil: ratingFoil,
                        ref_rating_epee: refRatingEpee,
                        ref_rating_sabre: refRatingSabre,
                        ref_rating_foil: refRatingFoil,
                        custom_rating: customRating,
                        tactical_description: tacticDescript,
                        favorite_actions: favActions,
                        notes,
                        fencer_is_me: true,
                    },
                    { withCredentials: true }
                );
                swal({
                    title: "Success",
                    text: "Fencer register",
                    // text:'register',
                    icon: "success",
                    timer: 1000,
                });
                setClub("");
                setClub2("");
                setSchool("");
                setDivision("");
                setRegion("");
                setNationality("");
                setHanded("");
                setGrip("");
                setRatingEpee("");
                setRatingSabre("");
                setRatingFoil("");
                setRefRatingEpee("");
                setRefRatingSabre("");
                setRefRatingFoil("");
                setcustomRating("");
                setTacticDescript("");
                setFavActions("");
                setNotes("");
                setValidations(false);
                setRegiOpen(false); // Move this line to after the reset of form fields
                fetchData2();
            } catch (error: any) {
                swal({
                    title: error.response?.data?.email[0],
                    text: 'Please change the email',
                    className: "icon-popup"
                }).then(async (confirmed) => {
                    if (confirmed) {
                        navigate(`/register`);
                    }
                });
            }
        } else {
            setValidations(true);
            setValidations_usaf(true)
        }
    };

    const handleAlreadyFencerClick = () => {
        setChecked(false)
        setopen(true)
        setIs_custom(false)
    }
    const handleCreateFencerClick = () => {
        setopen(false)
        setUsafNumber(0);
        setIs_custom(true)
        setChecked(true)

    }
    const handleTabClick = (tabName: string) => {
        switch (tabName) {
            case 'subscription':
                setSubscriptionOpen(true);
                setusafOpen(false);
                setPrivacyOpen(false);
                setprofiletab(false);
                break;
            case 'usaf':
                setusafOpen(true);
                setSubscriptionOpen(false);
                setPrivacyOpen(false);
                setprofiletab(false);
                break;
            case 'privacy':
                setPrivacyOpen(true);
                setSubscriptionOpen(false);
                setusafOpen(false);
                setprofiletab(false);
                break;
            case 'profile':
                setprofiletab(true);
                setPrivacyOpen(false);
                setSubscriptionOpen(false);
                setusafOpen(false);
                break;

            default:
                break;
        }
    };
    const subscribeT = () => {
        return (
            subscriptionOpen && (
                <div>
                    < div className="subscription-cls">
                        <div className="subsc-width">
                            <h3>Subscriber</h3>
                            <p>{subscriptionData.first_name} {subscriptionData.last_name}</p>
                        </div>
                        <div className="subsc-width">
                            <h3>Subscription</h3>
                            <p>{subscriptionData.subscription}</p>
                        </div>
                        <div className="subsc-width">
                            <h3>Upgrade Subscription</h3>
                            <button className="btnSave" onClick={handleClickSubs}>Click here</button>
                        </div>
                    </div>
                </div>
            )
        )
    }
    const usafT = () => {
        return (
            <>
                {usafOpen && (
                    fencer_me
                        ? <div className="subscription-cls-tab2">
                            <div className="fencer-detail-flex">
                                {/* <p>USAF number : {registerData.usaf_num}</p> */}
                                <p>Club : {registerData.club}</p>
                                <p>Secondary Club : {registerData.club2}</p>
                                <p>School : {registerData.school}</p>
                                <p>Nationality : {registerData.nationality} </p>
                            </div>
                            <div className="fencer-detail-flex">
                                <p>Division : {registerData.division}</p>
                                <p>Region : {registerData.region}</p>
                                <p>Handedness : {registerData.handed}</p>
                                <p>Grip Preference : {registerData.primary_grip}</p>
                                <p>Notes : {registerData.notes}</p>
                            </div>
                            <div className="fencer-detail-flex">
                                <p>Favorite Actions : {registerData.favorite_actions}</p>
                                <p>Epee Rating : {registerData.usaf_rating_epee} </p>
                                <p>sabre Rating : {registerData.usaf_rating_sabre}</p>
                                <p>Foil Rating : {registerData.usaf_rating_foil} </p>
                                <p>Custom Rating : {registerData.custom_rating}</p>
                            </div>
                            <div className="fencer-detail-flex">
                                <p>Tactical Description : {registerData.tactical_description}</p>
                                <p>Epee Referee Rating : {registerData.ref_rating_epee}</p>
                                <p>Sabre Referee Rating : {registerData.ref_rating_sabre}</p>
                                <p>Foil Referee Rating : {registerData.ref_rating_foil}</p>
                            </div>
                        </div>
                        : <div className='regi-asFen'>
                            <p>You are not registered as fencer</p>
                            <button className="btnSave" onClick={handleOpen}>Register Here</button>
                        </div>
                )}

                <Dialog open={regiOpen}>
                    <div>
                        <CloseIcon onClick={handleClose} className="icon-pointer crossTagsDiv-1" style={{ fontSize: '25px' }} />
                    </div>
                    <DialogContent>
                        <main className="loginPageWidthDiv regi-fencerpage">
                            <div className="toploginpage">
                                <div className="loginpage regi-fencerpage">
                                    <h2 className="Fencertitle">
                                        <span
                                            onClick={handleAlreadyFencerClick}
                                            style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }}
                                        >
                                            USA Fencing Number <PortraitIcon style={{ fontSize: '25px', marginLeft: '10px', marginTop: '2px' }} />
                                        </span>
                                    </h2>
                                    <br />

                                    {open ? <div className="">
                                        <form className="fencerform regi-fencerpage" onSubmit={submit}>

                                            <div className="Please-error">
                                                <div className='form-input'>
                                                    <input
                                                        id='nameInput'
                                                        name='usafNum'
                                                        type='number'
                                                        placeholder="1000000000"
                                                        onChange={e => setUsafNumber(e.target.valueAsNumber)}
                                                    // value={inputValue}
                                                    />
                                                    <label htmlFor='nameInput'>USAF Number</label>
                                                </div>
                                                {validations_usaf && !usafNumber && (
                                                    <p className="testerror11">Please enter Usaf Number</p>
                                                )}
                                            </div>
                                            <div className="Please-error">
                                                <FormSelectElement
                                                    setValue={setHandedd}
                                                    elementName="handed"
                                                    value={handedd}
                                                    selectOptions={HAND_CHOICES}
                                                    placeholder=""
                                                    labelText="Handedness"
                                                />
                                                {validations_usaf && !handedd && (
                                                    <p className="testerror11">Please enter Handedness</p>
                                                )}
                                            </div>
                                            <div className="Please-error">
                                                <FormSelectElement
                                                    setValue={setGripp}
                                                    elementName="grip"
                                                    value={gripp}
                                                    selectOptions={GRIP_CHOICES}
                                                    placeholder=""
                                                    labelText="Grip Preference"
                                                />
                                                {validations_usaf && !gripp && (
                                                    <p className="testerror11">Please enter Grip Preference </p>
                                                )}
                                            </div>
                                        </form>
                                    </div> : null}

                                    <form className='auth-form'>
                                        <p style={{ fontWeight: '600' }}>OR</p>
                                        <h1 className="Fencertitle">
                                            <span
                                                onClick={handleCreateFencerClick}
                                                style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }}
                                            >
                                                Create Fencer Profile <PortraitIcon style={{ fontSize: '25px', marginLeft: '10px', marginTop: '2px' }} />
                                            </span>
                                        </h1>
                                        <br />
                                        <>
                                            <Collapse in={checked}>
                                                <form className="fencerform regi-fencerpage" onSubmit={submit}>
                                                    <CSRF />
                                                    <div className="Please-error">
                                                        <FormSelectElement
                                                            setValue={setHanded}
                                                            elementName="handed"
                                                            value={handed}
                                                            selectOptions={HAND_CHOICES}
                                                            placeholder=""
                                                            labelText="Handedness"
                                                        />
                                                        {validations && !handed && (
                                                            <p className="testerror11">Please enter Handedness</p>
                                                        )}
                                                    </div>
                                                    <div className="Please-error">
                                                        <FormSelectElement
                                                            setValue={setGrip}
                                                            elementName="grip"
                                                            value={grip}
                                                            selectOptions={GRIP_CHOICES}
                                                            placeholder=""
                                                            labelText="Grip Preference"
                                                        />
                                                        {validations && !grip && (
                                                            <p className="testerror11">Please enter Grip Preference </p>
                                                        )}
                                                    </div>
                                                    <FormTextElement
                                                        setValue={setClub}
                                                        elementName="club"
                                                        value={club}
                                                        placeholder=""
                                                        labelText="Club"
                                                    />
                                                    <FormTextElement
                                                        setValue={setClub2}
                                                        elementName="club2"
                                                        value={club2}
                                                        placeholder=""
                                                        labelText="Secondary Club"
                                                    />
                                                    <FormTextElement
                                                        setValue={setSchool}
                                                        elementName="school"
                                                        value={school}
                                                        placeholder=""
                                                        labelText="School"
                                                    />
                                                    <FormTextElement
                                                        setValue={setDivision}
                                                        elementName="division"
                                                        value={division}
                                                        placeholder=""
                                                        labelText="Division"
                                                    />
                                                    <div className="Please-error">
                                                        <FormSelectElement
                                                            setValue={setRegion}
                                                            elementName="region"
                                                            value={region}
                                                            selectOptions={REGION_CHOICES}
                                                            placeholder=""
                                                            labelText="Region"
                                                        />
                                                        {validations && !region && (
                                                            <p className="testerror1">Please Select Region</p>
                                                        )}
                                                    </div>
                                                    <FormTextElement
                                                        setValue={setNationality}
                                                        value={nationality}
                                                        elementName="nationality"
                                                        placeholder=""
                                                        labelText="Nationality"
                                                    />
                                                    <FormSelectElement
                                                        setValue={setRatingEpee}
                                                        value={ratingEpee}
                                                        elementName="ratingEpee"
                                                        selectOptions={USAF_RATING_CHOICES}
                                                        placeholder=""
                                                        labelText="Epee Rating"
                                                    />
                                                    <FormSelectElement
                                                        setValue={setRatingSabre}
                                                        value={ratingSabre}
                                                        elementName="ratingSabre"
                                                        selectOptions={USAF_RATING_CHOICES}
                                                        placeholder=""
                                                        labelText="Sabre Rating"
                                                    />
                                                    <FormSelectElement
                                                        setValue={setRatingFoil}
                                                        value={ratingFoil}
                                                        elementName="ratingFoil"
                                                        selectOptions={USAF_RATING_CHOICES}
                                                        placeholder=""
                                                        labelText="Foil Rating"
                                                    />
                                                    <FormSelectElement
                                                        setValue={setRefRatingEpee}
                                                        value={refRatingEpee}
                                                        elementName="refRatingEpee"
                                                        selectOptions={REF_RATING_CHOICES}
                                                        placeholder=""
                                                        labelText="Epee Referee Rating"
                                                    />
                                                    <FormSelectElement
                                                        setValue={setRefRatingSabre}
                                                        value={refRatingSabre}
                                                        elementName="refRatingSabre"
                                                        selectOptions={REF_RATING_CHOICES}
                                                        placeholder=""
                                                        labelText="Sabre Referee Rating"
                                                    />
                                                    <FormSelectElement
                                                        setValue={setRefRatingFoil}
                                                        value={refRatingFoil}
                                                        elementName="refRatingFoil"
                                                        selectOptions={REF_RATING_CHOICES}
                                                        placeholder=""
                                                        labelText="Foil Referee Rating"
                                                    />
                                                    <FormTextElement
                                                        setValue={setcustomRating}
                                                        value={customRating}
                                                        elementName="customRating"
                                                        placeholder=""
                                                        labelText="Custom Rating"
                                                    />
                                                    <FormTextElement
                                                        setValue={setTacticDescript}
                                                        value={tacticDescript}
                                                        elementName="tacticalDescription"
                                                        placeholder=""
                                                        labelText="Tactical Description"
                                                    />
                                                    <FormTextElement
                                                        setValue={setFavActions}
                                                        value={favActions}
                                                        elementName="favActions"
                                                        placeholder=""
                                                        labelText="Favorite Actions"
                                                    />
                                                    <FormTextElement
                                                        setValue={setNotes}
                                                        value={notes}
                                                        elementName="notes"
                                                        placeholder=""
                                                        labelText="Notes"
                                                    />


                                                </form>
                                            </Collapse>
                                        </>
                                        <hr />
                                        <button
                                            className="submit-button Fencerbtn"
                                            type="button"
                                            onClick={submit}
                                        >
                                            Register
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </main>
                    </DialogContent>
                </Dialog>
            </>
        )
    }

    const privacyT = () => {
        return (
            privacyOpen &&
            <div className="subscription-cls">
                <label className='label-cls' >Pemissions</label>
                <select className='privacy-width input-margin' id="selcted" name="" value={getOption} onChange={handleChange}>
                    <option key={'Everone'} value={'everyone'}>
                        Everyone
                    </option>
                    <option key={'Friends'} value="friends">
                        Friends
                    </option>
                    <option key={'Club'} value="club members">
                        Club
                    </option>
                    <option key={'Coach'} value="my coaches">
                        Coach
                    </option>
                    <option key={'Private'} value="Private">
                        Private
                    </option>
                </select>
                <button className={constOptions === getOption ? "btnSave btn-opacity btnP-margin" : "btnSave btnP-margin"} onClick={savePrivacy} disabled={constOptions === getOption ? true : false}>Save</button>
            </div>
        )
    }
    const profileT = () => {
        return (
            profiletab && (
                <div className="subscription-cls-tab2">
                    <div className="fencer-detail-flex">
                        <p>First name : {profileData.first_name}</p>
                    </div>
                    <div className="fencer-detail-flex">
                        <p>Last name : {profileData.last_name}</p>
                    </div>
                    <div className="fencer-detail-flex">
                        <p>Email : {profileData.email}</p>
                    </div>
                </div>
            )
        )
    }

    return (
        isfreeium ? <div className="loader"><p>Please buy subscription </p>
            <button className="btnSave" onClick={handleClickSubs}>Click here</button></div>
            : <div style={{ padding: '0 20px' }}>
                <div className='setting-heading-section'>
                    <SettingsIcon className='setting-icon' />
                    <h2 className="setting-heading">Profile Settings</h2>
                </div>
                <div className='desktop-screen'>
                    <div className="setting-flex setting-flex-border">
                        <div className={subscriptionOpen ? 'setting-tabs setting-background' : 'setting-tabs'} onClick={() => handleTabClick('subscription')}>
                            <h4>Subscription</h4>{subscriptionOpen ? <KeyboardArrowUpIcon className="setting-tabIcon" /> : <KeyboardArrowDownIcon className="setting-tabIcon" />}
                        </div>
                        <div className={usafOpen ? 'setting-tabs setting-background' : ' setting-tabs'} onClick={() => handleTabClick('usaf')}>
                            <h4>USAF Data</h4>{usafOpen ? <KeyboardArrowUpIcon className="setting-tabIcon" /> : <KeyboardArrowDownIcon className="setting-tabIcon" />}
                        </div>
                        <div className={privacyOpen ? 'setting-tabs setting-background' : ' setting-tabs '} onClick={() => handleTabClick('privacy')}>
                            <h4>Privacy Setting</h4>{privacyOpen ? <KeyboardArrowUpIcon className="setting-tabIcon" /> : <KeyboardArrowDownIcon className="setting-tabIcon" />}
                        </div>
                        <div className={profiletab ? 'setting-tabs setting-background' : ' setting-tabs '} onClick={() => handleTabClick('profile')}>
                            <h4>Profile </h4>{profiletab ? <KeyboardArrowUpIcon className="setting-tabIcon" /> : <KeyboardArrowDownIcon className="setting-tabIcon" />}
                        </div>
                    </div>

                    {subscribeT()}
                    {usafT()}
                    {privacyT()}
                    {profileT()}
                </div>

                <div className='mobile-screen'>
                    <div className="setting-flex">
                        <div className={subscriptionOpen ? 'setting-tabs setting-background' : 'setting-tabs'} onClick={() => handleTabClick('subscription')}>
                            <h4>Subscription</h4>{subscriptionOpen ? <KeyboardArrowUpIcon className="setting-tabIcon" /> : <KeyboardArrowDownIcon className="setting-tabIcon" />}
                        </div>
                        {subscribeT()}
                        <div className={usafOpen ? 'setting-tabs setting-background' : ' setting-tabs'} onClick={() => handleTabClick('usaf')}>
                            <h4>USAF Data</h4>{usafOpen ? <KeyboardArrowUpIcon className="setting-tabIcon" /> : <KeyboardArrowDownIcon className="setting-tabIcon" />}
                        </div>
                        {usafT()}
                        <div className={privacyOpen ? 'setting-tabs setting-background' : ' setting-tabs '} onClick={() => handleTabClick('privacy')}>
                            <h4>Privacy setting</h4>{privacyOpen ? <KeyboardArrowUpIcon className="setting-tabIcon" /> : <KeyboardArrowDownIcon className="setting-tabIcon" />}
                        </div>
                        {privacyT()}
                        <div className={profiletab ? 'setting-tabs setting-background' : ' setting-tabs '} onClick={() => handleTabClick('profile')}>
                            <h4>Profile </h4>{profiletab ? <KeyboardArrowUpIcon className="setting-tabIcon" /> : <KeyboardArrowDownIcon className="setting-tabIcon" />}
                        </div>
                        {profileT()}
                    </div>
                </div>
            </div>
    )
}

