import axios from "axios";
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { setAuth } from "../../redux/authSlice";
import { RootState } from "../../redux/store";
import { getRefreshToken } from "../../interceptors/axios";
import { PushNote } from "../../components/PushNote";
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';

export const PaymentCancel = () => {
  const [price, setPrice] = useState<number>(0);

  useEffect(() => {
    // Fetch the price from the backend API
    axios.get("/api/price")
      .then(response => {
        setPrice(response.data.price);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);


  const handleButtonClick = async () => {
    try {
      // Make API request
      const { data } = await axios.get("api/subscription/payment");
      
      // Redirect to API URL
      window.location.href = data.apiURL;
    } catch (error) {
      // Handle error
      swal('An error occurred');
    }
  };
  

  useEffect(() => {
    getRefreshToken()
    }, []);
    
  return (
    <div className="container">
      <div className="subscriptionpageRed">
      <h1>
          <CancelIcon style={{fontSize:'27px'}}/>
      </h1>
      Subscription Payment <br/>Failed
      </div>
    </div>
  );
};