import { useState, useEffect, Dispatch, SetStateAction } from 'react'


export const FormNumberElement = ({ 
    setValue,
    value,
    labelText,
    elementName,
    }:{ 
        // setValue: (value: number | null) => void, 
        setValue: Dispatch<SetStateAction<number | null>> 
        // value:number,
        // labelText: string,
        // elementName: string,
        // setValue: (value: number | null) => void, 
        value: number | null,
        labelText: string,
        elementName: string,
    }) => {

    
    // keep label from covering the input when filled in
    const [className, setClassName]  = useState<string>('')
    // const [inputValue, setInputValue] = useState<string>(startingValue ? startingValue : '')

    // update the child component if the startingValue changes
    // useEffect(() => { setInputValue(startingValue ? startingValue : '') }, [startingValue]);

    function onChange(entry:number) {
        setValue(entry)
        // setInputValue(entry)
        if (entry != 0) {
            setClassName('jumped')
        }else{
            setClassName('')
        }
    }

    return (
        <div className='form-input'>
            <input 
                id='nameInput' 
                value={value ?? 0}
                name={elementName}
                type='number' 
                // placeholder={placeholder}
                onChange={e => onChange(e.target.valueAsNumber)}
                // value={inputValue}
            />
            <label className={className} htmlFor='nameInput'>{labelText}</label>
        </div>
    )
}