import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { FormEvent, useState } from "react";

import { DOMAIN_NAME } from "../../constants/VarOptions";



export const Payment = () => {
    const [error, setError] = useState<string | undefined>();
    const [email, setEmail] = useState('')
    const stripe = useStripe();
    const elements = useElements();

    // Handle real-time validation errors from the CardElement.
    const handleChange = (event: any) => {
        if (event.error) {
            setError(event.error.message);
        } else {
            setError(undefined);
        }
    }

    // Handle form submission.
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        if (!elements) {
            console.log("elements not found")
            return
        }

        elements.submit();
        const paymentElement = elements.getElement(PaymentElement)

        if (!stripe || !paymentElement){
            console.log("Error: Payment not found")
            return
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              // Make sure to change this to your payment completion page
              return_url: DOMAIN_NAME + "/payment-success",
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            setError(error.message);
        } else {
            setError("An unexpected error occurred.");
        }
    };


    return (
        <form onSubmit={handleSubmit} className="stripe-form">
            <div className="form-row">
                <PaymentElement onChange={handleChange}/>
                <div className="card-errors" role="alert">{error}</div>
            </div>

            <button type="submit" className="submit-button">
                Submit Payment
            </button>
        </form>
    );
}
export default Payment;