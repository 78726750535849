




export const Pricing = () => {

    return(
        <>
        </>
    )
}