import axios from "axios";
import { useNavigate } from 'react-router-dom';
import React, { ChangeEvent, FormEvent } from 'react';
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { PushNote } from "../../components/PushNote";


export const Contact_us = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [emailError, setEmailError] = useState<string>("");
  const [descriptionError, setDescriptionError] = useState<boolean>(false);

  type Position = "absolute" | "fixed" | "relative" | "static" | "sticky";

  const bannerStyle: React.CSSProperties = {
    backgroundColor: "gray",
    color: "white",
    padding: "10px",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  useEffect(() => {
    window.scroll(0, 0);
    let variable = localStorage.getItem("cookiesdata");
    console.log(variable);
    if (variable == "true") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  const handleAccept = () => {
    setIsVisible(false);
    localStorage.removeItem("cookiesdata");
    // set a cookie to remember that the user has accepted the GDPR policy
    document.cookie = "gdpr_accepted=true; path=/";
  };

  const [formData, setFormData] = useState({
    subject: '',
    email: '',
    description: ''
  });
  

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const validateEmail = (value: string) => {
    if (!value) return "*Required";
    if (!value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
      return "Email is not valid";

    return "";
  };
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
      
      // const subjectError = subjecte(formData.subject);
      setDescriptionError(formData.description !== '')
      setEmailError(validateEmail(formData.email));

      if (
        // subjectError === '' &&
        emailError === '' &&
        descriptionError
        ) {


        const data = axios.post(
          "api/auth/contact-us",
          {
            email: formData.email,
            subject: formData.subject,
            description: formData.description
          },
          { withCredentials: true }
        );
        setFormData({
          email: '',
          subject: '',
          description: ''
        });
      }
  };

  return (
    <div className="backgroundimage">
      <div className="backgroundimagemaindiv">
        <br />
        <br />
        <div className="contactHead">
          <div className="contacttext-div">
            <h1 className="headingContact">Contact <br></br> <strong>Finalstrip</strong></h1>
          </div>
        </div>

        <div className="Formsec">
          <div className="contactForm">
            <h2>Contact Form</h2>
            <form onSubmit={handleSubmit}>
              <div className="roww">
                <div className="roww-flex">
                  <div className="col">
                    {emailError && <p className="testerror" style={{ top: '-16px', right: '18px' }}>{emailError}</p>}
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="roww-flex">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="roww-flex">
                  <div className="col">
                    {descriptionError && <p className="testerror" style={{ top: '-16px', right: '18px' }}>Required</p>}
                    <textarea
                      placeholder="Description"
                      className="form-control contactdes"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                    >
                    </textarea>
                  </div>
                </div>
              </div>
              <br />
              <div>
                <button className="submitbtn" type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};


