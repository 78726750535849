import { Link } from "react-router-dom";
import React from "react";
import EmailIcon from '@mui/icons-material/Email';

export const FooterPage = () => {
    return (
        <div className="footer-page">
            <h2 className="headdd">FINAL<span className="heading">STRIP</span></h2>
            <hr className="footer-divider" />
            <div className="footer-page-grid">
                <div className="footer-page-grid1">
                    <p>Discover how you can improve your fencing with finalstrip,
                        the premier platform dedicated to empowering fencers of all levels.
                    </p>
                </div>
                <div className="footer-page-grid2">
                    <p className="heading-color">EXPLORE</p>
                    <div className="grid2-flex">
                        <div className="footer-menu">
                            <li><Link className='linklink' to='/'>Home</Link></li>
                            <li><Link className='linklink' to='/journal'>Journal</Link></li>
                            <li><Link className='linklink' to='/journal/tournaments'>Tournaments</Link></li>
                            <li><Link className='linklink' to='/journal/lessons'>Lessons</Link></li>
                            {/* <Link className='linklink' to='/'><span>Home</span></Link> <br/>
                            <Link className='linklink' to='/journal'><span>Journal</span></Link> <br/>
                            <Link className='linklink' to='/journal/tournaments'> <span>Tournaments</span></Link><br/>
                            <Link className='linklink' to='/journal/lessons'><span>Lessons</span></Link> */}
                        </div>
                        <div className="footer-menu">
                            <li><Link className='linklink' to='/journal/fencers'>Fencers</Link></li>
                            <li><Link className='linklink' to='/contactus'>Contact us</Link></li>
                            {/* <li><Link className='linklink' to='/subscription'>Subscription</Link></li> */}
                        </div>
                    </div>
                </div>
                <div className="footer-page-grid3">
                    <p className="heading-color">EMAIL ADDRESS</p>
                    <div className='footer-icon-div'>
                        <EmailIcon className='mail-icon' />
                        <a className='mail-link'  href="mailto:Demo1233@mail.com">info@finalstrip.com</a>
                    </div>
                </div>

            </div>
            <hr className="footer-divider" />
            <p>Copyright @ 2024 Finalstrip | All Rights Reserved.</p>
        </div>
    );
};

