import { useState } from 'react';

export function FormRatingElement({
    setValue,
    value,
    labelText,
  }: {
    setValue: React.Dispatch<React.SetStateAction<number>>;
    value: number;
    labelText: string;
  }) {
    const ratingObject: { [key: number]: string } = {
      0: 'None',
      1: 'Poor',
      2: 'Fair',
      3: 'Good',
      4: 'Great',
      5: 'Excellent',
    };
  
    function onClick(entry: number): void {
      setValue(entry);
    }
  
    function onReset() {
      setValue(0);
    }
  
    return (
      <div>
        <div className="rating-label">
          {labelText}: {ratingObject[value]}
        </div>
        <div className="rating">
          {[...Array(5)].map((star, index) => {
            index += 1;
            return (
              <button
                key={index}
                type="button"
                value={value}
                className={
                  index <= value ? 'rating-button active' : 'rating-button inactive'
                }
                onClick={() => onClick(index)}
              >
                <div className="star" />
              </button>
            );
          })}
          <button type="button" className="reset-rating" onClick={onReset}>
            reset
          </button>
        </div>
      </div>
    );
  }