import { useState, SyntheticEvent, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FormTextElement } from "./form elements/FormTextElement";
import { FormNumberElement } from "./form elements/FormNumberElement";
import { FormRatingElement } from "./form elements/FormRatingElement";
import { FormSelectElement } from "./form elements/FormSelectElement";
import { FormCheckBoxElement } from "./form elements/FormCheckBoxElement";
import PortraitIcon from '@mui/icons-material/Portrait';
import { DialogBox } from "../DialogBox";
import { HAND_CHOICES, SHAREABLE_CHOICES, Bout_Type_Options } from "../../constants/VarOptions";
import { fencerListType } from "../../constants/VarTypes";
import { CSRF } from "./form elements/CSRF";
import { getNewRefreshToken } from "../../hooks/utils";
import { SearchUSAFencing } from "./form elements/SearchUSAFencing";
import { ReactComponent as EditIcon } from "../../icons/edit_icon.svg";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import {
  REGION_CHOICES,
  GRIP_CHOICES,
  REF_RATING_CHOICES,
  USAF_RATING_CHOICES,
} from "../../constants/VarOptions";
import { Dialog, DialogContent } from "@material-ui/core";
import Collapse from "@mui/material/Collapse";
import { setAuth } from '../../redux/authSlice'
import CloseIcon from '@mui/icons-material/Close';

interface Item {
  id: number;
  tag_name: string;
}
interface ChildComponentProps {
  fencerList: fencerListType;
  setfencerupdate: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenData: React.Dispatch<React.SetStateAction<boolean>>;
  slugData: string;
  openData?: boolean | undefined;
  setSlugData: React.Dispatch<React.SetStateAction<string>>;
  setDataUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setDataReceived: React.Dispatch<React.SetStateAction<boolean>>;
}
// export function BoutForm (props: {fencerList: fencerListType,}){
export function BoutForm(props: ChildComponentProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { eventSlug } = useParams();
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  // const [winnerIsA, setWinnerIsA] = useState<null | boolean>(null);
  const winnerIsA = useRef<null | boolean>(null);

  // console.log("props.openData---------------", props.openData);
  const [validations2, setValidation2] = useState<boolean>(false);
  const [fencerA, setFencerA] = useState<string>("");
  const [scoreA, setScoreA] = useState<number | null>(null);
  const [fencerAHand, setFencerAHand] = useState("");
  const [fencerAYellowCard, setFencerAYellowCard] = useState<boolean>(false);
  const [fencerARedCard, setFencerARedCard] = useState<boolean>(false);
  const [fencerABlackCard, setFencerABlackCard] = useState<boolean>(false);
  const [fencerAPassivity, setFencerAPassivity] = useState<boolean>(false);
  const [fencerAMedical, setFencerAMedical] = useState<boolean>(false);
  const [fencerAVideoUsed, setFencerAVideoUsed] = useState<number | null>(0);
  const [fencerAFootwork, setFencerAFootwork] = useState(0);
  const [fencerABladework, setFencerABladework] = useState(0);
  const [fencerATechnique, setFencerATechnique] = useState(0);
  const [fencerADistance, setFencerADistance] = useState(0);
  const [fencerAEnergy, setFencerAEnergy] = useState(0);
  const [fencerANotes, setFencerANotes] = useState("");
  const [checked, setChecked] = useState<boolean>(false);

  const [fencerB, setFencerB] = useState<string>("");
  const [scoreB, setScoreB] = useState<number | null>(null);
  const [fencerBHand, setFencerBHand] = useState<string>("");
  const [fencerBYellowCard, setFencerBYellowCard] = useState<boolean>(false);
  const [fencerBRedCard, setFencerBRedCard] = useState<boolean>(false);
  const [fencerBBlackCard, setFencerBBlackCard] = useState<boolean>(false);
  const [fencerBPassivity, setFencerBPassivity] = useState<boolean>(false);
  const [fencerBMedical, setFencerBMedical] = useState<boolean>(false);
  const [fencerBVideoUsed, setFencerBVideoUsed] = useState<number | null>(0);
  const [fencerBFootwork, setFencerBFootwork] = useState(0);
  const [fencerBBladework, setFencerBBladework] = useState(0);
  const [fencerBTechnique, setFencerBTechnique] = useState(0);
  const [fencerBDistance, setFencerBDistance] = useState(0);
  const [fencerBEnergy, setFencerBEnergy] = useState(0);
  const [fencerBNotes, setFencerBNotes] = useState<string>("");

  const [referee, setReferee] = useState<string>("");
  const [round, setRound] = useState<number | null>(0);
  const [boutNotes, setBoutNotes] = useState<string>("");
  const [boutFormat, setBoutFormat] = useState<string>("");
  const [boutType, setBoutType] = useState<string>("");
  const [sharable, setShareable] = useState<string>("");
  const [tags, setTags] = useState<string>('');
  const [totalTags, settotalTags] = useState<string[]>([]);
  const [inputError, setInputerror] = useState<boolean>(false);
  const [searchList, setsearchList] = useState<Item[]>([]);

  const [showDialogBox, setShowDialogBox] = useState<boolean>(false);
  const [fencerOptions, setFencerOptions] = useState<fencerListType>({
    "999": "Error: No Fencers Found",
    "998": "Error: No Fencers Found",
  });

  useEffect(() => {
    var myData = localStorage.getItem('userData');
    // console.log("journal", myData)
    if (myData) {
      dispatch(setAuth(true))
    }
  }, [])

  useEffect(() => {
    // console.log("props: ", props.fencerList);
    setFencerOptions(props.fencerList);
  }, [props]);

  // async function getFencers(){
  //     const {data} = await axios.get('/api/fencers/all')
  //     const processData = (data: any) => {
  //         setFencerOptions(data.response.map((fencer: any) => {
  //             return {
  //                 [fencer.slug]: fencer.first_name +', '+ fencer.last_name
  //         }}))
  //     }
  // }
  // getFencers()
  async function sendData() {
    await getNewRefreshToken();
    const { data } = await axios.post(
      "api/bouts/create/",
      {
        event: eventSlug,
        winner_is_a: winnerIsA.current,
        fencer_a: fencerA,
        score_a: scoreA,
        fencer_a_hand: fencerAHand,
        fencer_a_yellow_card: fencerAYellowCard,
        fencer_a_red_card: fencerARedCard,
        fencer_a_black_card: fencerABlackCard,
        fencer_a_passivity: fencerAPassivity,
        fencer_a_medical: fencerAMedical,
        fencer_a_video_used: fencerAVideoUsed,
        fencer_a_footwork: fencerAFootwork,
        fencer_a_bladework: fencerABladework,
        fencer_a_technique: fencerATechnique,
        fencer_a_distance: fencerADistance,
        fencer_a_energy: fencerAEnergy,
        fencer_a_notes: fencerANotes,
        fencer_b: fencerB,
        score_b: scoreB,
        fencer_b_hand: fencerBHand,
        fencer_b_yellow_card: fencerBYellowCard,
        fencer_b_red_card: fencerBRedCard,
        fencer_b_black_card: fencerBBlackCard,
        fencer_b_passivity: fencerBPassivity,
        fencer_b_medical: fencerBMedical,
        fencer_b_video_used: fencerBVideoUsed,
        fencer_b_footwork: fencerBFootwork,
        fencer_b_bladework: fencerBBladework,
        fencer_b_technique: fencerBTechnique,
        fencer_b_distance: fencerBDistance,
        fencer_b_energy: fencerBEnergy,
        fencer_b_notes: fencerBNotes,
        referee: referee,
        round: round,
        bout_notes: boutNotes,
        tag_name: totalTags,
        bout_format: boutFormat,
        bout_type: boutType,
        sharable: sharable,
      },
      { withCredentials: true }
    );
    setFencerA("")
    setScoreA(0)
    setFencerAHand("")
    setFencerAVideoUsed(0)
    setFencerAMedical(false)
    setFencerANotes("")
    setFencerAFootwork(0)
    setFencerABladework(0)
    setFencerATechnique(0)
    setFencerADistance(0)
    setFencerAEnergy(0)
    setFencerAYellowCard(false)
    setFencerARedCard(false)
    setFencerABlackCard(false)
    setFencerAPassivity(false)
    setFencerB("")
    setScoreB(0)
    setFencerBHand("")
    setFencerBVideoUsed(0)
    setFencerBMedical(false)
    setFencerBNotes("")
    setFencerBFootwork(0)
    setFencerBBladework(0)
    setFencerBTechnique(0)
    setFencerBDistance(0)
    setFencerBEnergy(0)
    setFencerBYellowCard(false)
    setFencerBRedCard(false)
    setFencerBBlackCard(false)
    setFencerBPassivity(false)
    setRound(0)
    setBoutFormat("")
    setBoutType("")
    setReferee("")
    setBoutNotes("")
    settotalTags([])
    setShareable("")
    props.setDataReceived(false)
    props.setDataUpdate(true)
    winnerIsA.current = true
    swal({
      title: 'Bout Added',
      icon: 'success',
      timer: 3000,
    });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    // if (scoreA == null || scoreB == null) {
    //   alert("score is required");
    //   return;
    // }
    
    if (boutType && fencerA && scoreA && fencerB && scoreB) {
      setValidation2(false)
      
      if (scoreA > scoreB) {
        const data = axios.post(
          "api/bouts/create/",
          {
            event: eventSlug,
            winner_is_a: true,
            fencer_a: fencerA,
            score_a: scoreA,
            fencer_a_hand: fencerAHand,
            fencer_a_yellow_card: fencerAYellowCard,
            fencer_a_red_card: fencerARedCard,
            fencer_a_black_card: fencerABlackCard,
            fencer_a_passivity: fencerAPassivity,
            fencer_a_medical: fencerAMedical,
            fencer_a_video_used: fencerAVideoUsed,
            fencer_a_footwork: fencerAFootwork,
            fencer_a_bladework: fencerABladework,
            fencer_a_technique: fencerATechnique,
            fencer_a_distance: fencerADistance,
            fencer_a_energy: fencerAEnergy,
            fencer_a_notes: fencerANotes,
            fencer_b: fencerB,
            score_b: scoreB,
            fencer_b_hand: fencerBHand,
            fencer_b_yellow_card: fencerBYellowCard,
            fencer_b_red_card: fencerBRedCard,
            fencer_b_black_card: fencerBBlackCard,
            fencer_b_passivity: fencerBPassivity,
            fencer_b_medical: fencerBMedical,
            fencer_b_video_used: fencerBVideoUsed,
            fencer_b_footwork: fencerBFootwork,
            fencer_b_bladework: fencerBBladework,
            fencer_b_technique: fencerBTechnique,
            fencer_b_distance: fencerBDistance,
            fencer_b_energy: fencerBEnergy,
            fencer_b_notes: fencerBNotes,
            referee: referee,
            round: round,
            notes: boutNotes,
            tag_name: totalTags,
            bout_format: boutFormat,
            bout_type: boutType,
            sharable: sharable,
          },
          { withCredentials: true }
        )
        setFencerA("")
        setScoreA(0)
        setFencerAHand("")
        setFencerAVideoUsed(0)
        setFencerAMedical(false)
        setFencerANotes("")
        setFencerAFootwork(0)
        setFencerABladework(0)
        setFencerATechnique(0)
        setFencerADistance(0)
        setFencerAEnergy(0)
        setFencerAYellowCard(false)
        setFencerARedCard(false)
        setFencerABlackCard(false)
        setFencerAPassivity(false)
        setFencerB("")
        setScoreB(0)
        setFencerBHand("")
        setFencerBVideoUsed(0)
        setFencerBMedical(false)
        setFencerBNotes("")
        setFencerBFootwork(0)
        setFencerBBladework(0)
        setFencerBTechnique(0)
        setFencerBDistance(0)
        setFencerBEnergy(0)
        setFencerBYellowCard(false)
        setFencerBRedCard(false)
        setFencerBBlackCard(false)
        setFencerBPassivity(false)
        setRound(0)
        setBoutFormat("")
        setBoutType("")
        setReferee("")
        setBoutNotes("")
        settotalTags([])
        setShareable("")
        props.setDataReceived(false)
        props.setDataUpdate(true)
        winnerIsA.current = true
        swal({
          title: 'Bout Added',
          icon: 'success',
          timer: 3000,
        });
        // setWinnerIsA(true);
      }
      else if (scoreA < scoreB) {
        const data = axios.post(
          "api/bouts/create/",
          {
            event: eventSlug,
            winner_is_a: false,
            fencer_a: fencerA,
            score_a: scoreA,
            fencer_a_hand: fencerAHand,
            fencer_a_yellow_card: fencerAYellowCard,
            fencer_a_red_card: fencerARedCard,
            fencer_a_black_card: fencerABlackCard,
            fencer_a_passivity: fencerAPassivity,
            fencer_a_medical: fencerAMedical,
            fencer_a_video_used: fencerAVideoUsed,
            fencer_a_footwork: fencerAFootwork,
            fencer_a_bladework: fencerABladework,
            fencer_a_technique: fencerATechnique,
            fencer_a_distance: fencerADistance,
            fencer_a_energy: fencerAEnergy,
            fencer_a_notes: fencerANotes,
            fencer_b: fencerB,
            score_b: scoreB,
            fencer_b_hand: fencerBHand,
            fencer_b_yellow_card: fencerBYellowCard,
            fencer_b_red_card: fencerBRedCard,
            fencer_b_black_card: fencerBBlackCard,
            fencer_b_passivity: fencerBPassivity,
            fencer_b_medical: fencerBMedical,
            fencer_b_video_used: fencerBVideoUsed,
            fencer_b_footwork: fencerBFootwork,
            fencer_b_bladework: fencerBBladework,
            fencer_b_technique: fencerBTechnique,
            fencer_b_distance: fencerBDistance,
            fencer_b_energy: fencerBEnergy,
            fencer_b_notes: fencerBNotes,
            referee: referee,
            round: round,
            notes: boutNotes,
            tag_name: totalTags,
            bout_format: boutFormat,
            bout_type: boutType,
            sharable: sharable,
          },
          { withCredentials: true }
        )
        setFencerA("")
        setScoreA(0)
        setFencerAHand("")
        setFencerAVideoUsed(0)
        setFencerAMedical(false)
        setFencerANotes("")
        setFencerAFootwork(0)
        setFencerABladework(0)
        setFencerATechnique(0)
        setFencerADistance(0)
        setFencerAEnergy(0)
        setFencerAYellowCard(false)
        setFencerARedCard(false)
        setFencerABlackCard(false)
        setFencerAPassivity(false)
        setFencerB("")
        setScoreB(0)
        setFencerBHand("")
        setFencerBVideoUsed(0)
        setFencerBMedical(false)
        setFencerBNotes("")
        setFencerBFootwork(0)
        setFencerBBladework(0)
        setFencerBTechnique(0)
        setFencerBDistance(0)
        setFencerBEnergy(0)
        setFencerBYellowCard(false)
        setFencerBRedCard(false)
        setFencerBBlackCard(false)
        setFencerBPassivity(false)
        setRound(0)
        setBoutFormat("")
        setBoutType("")
        setReferee("")
        setBoutNotes("")
        settotalTags([])
        setShareable("")
        props.setDataReceived(false)
        props.setDataUpdate(true)
        winnerIsA.current = false
        swal({
          title: 'Bout Added',
          icon: 'success',
          timer: 3000,
        });
      }
      else if (scoreA === scoreB && winnerIsA.current === null) {
        setShowDialogBox(true);
        // console.log("setting showDialogBox: ", showDialogBox);
        window. location. reload();
      }
    }
    else {
      console.log('validation fail')
      setValidation2(true)
    }
    // console.log("sending data to backend...");
    props.setDataUpdate(false)
    props.setDataReceived(false)
  };

  const handleUpdate = async () => {
    if (scoreA == null || scoreB == null) {
      alert("Score is required");
      return;
    }
    if (scoreA == scoreB && winnerIsA.current === null) {
      setShowDialogBox(true);
      // console.log("setting showDialogBox: ", showDialogBox);
      return;
    }
    console.log('sending to update endpoint')
    try {
      await getNewRefreshToken();
      // assign winner
      if (scoreA > scoreB) {
        const data = axios.patch(
          `api/bouts/update/${props.slugData}/`,
          {
            event: eventSlug,
            winner_is_a: true,
            fencer_a: fencerA,
            score_a: scoreA,
            fencer_a_hand: fencerAHand,
            fencer_a_yellow_card: fencerAYellowCard,
            fencer_a_red_card: fencerARedCard,
            fencer_a_black_card: fencerABlackCard,
            fencer_a_passivity: fencerAPassivity,
            fencer_a_medical: fencerAMedical,
            fencer_a_video_used: fencerAVideoUsed,
            fencer_a_footwork: fencerAFootwork,
            fencer_a_bladework: fencerABladework,
            fencer_a_technique: fencerATechnique,
            fencer_a_distance: fencerADistance,
            fencer_a_energy: fencerAEnergy,
            fencer_a_notes: fencerANotes,
            fencer_b: fencerB,
            score_b: scoreB,
            fencer_b_hand: fencerBHand,
            fencer_b_yellow_card: fencerBYellowCard,
            fencer_b_red_card: fencerBRedCard,
            fencer_b_black_card: fencerBBlackCard,
            fencer_b_passivity: fencerBPassivity,
            fencer_b_medical: fencerBMedical,
            fencer_b_video_used: fencerBVideoUsed,
            fencer_b_footwork: fencerBFootwork,
            fencer_b_bladework: fencerBBladework,
            fencer_b_technique: fencerBTechnique,
            fencer_b_distance: fencerBDistance,
            fencer_b_energy: fencerBEnergy,
            fencer_b_notes: fencerBNotes,
            referee: referee,
            round: round,
            notes: boutNotes,
            tag_name: totalTags,
            bout_format: boutFormat,
            bout_type: boutType,
            sharable: sharable,
          },
          { withCredentials: true }
        )
        setFencerA("")
        setScoreA(0)
        setFencerAHand("")
        setFencerAVideoUsed(0)
        setFencerAMedical(false)
        setFencerANotes("")
        setFencerAFootwork(0)
        setFencerABladework(0)
        setFencerATechnique(0)
        setFencerADistance(0)
        setFencerAEnergy(0)
        setFencerAYellowCard(false)
        setFencerARedCard(false)
        setFencerABlackCard(false)
        setFencerAPassivity(false)
        setFencerB("")
        setScoreB(0)
        setFencerBHand("")
        setFencerBVideoUsed(0)
        setFencerBMedical(false)
        setFencerBNotes("")
        setFencerBFootwork(0)
        setFencerBBladework(0)
        setFencerBTechnique(0)
        setFencerBDistance(0)
        setFencerBEnergy(0)
        setFencerBYellowCard(false)
        setFencerBRedCard(false)
        setFencerBBlackCard(false)
        setFencerBPassivity(false)
        setRound(0)
        setBoutFormat("")
        setBoutType("")
        setReferee("")
        setBoutNotes("")
        settotalTags([])
        setShareable("")
        props.setDataReceived(false)
        props.setDataUpdate(true)
        // console.log('player A ', data)
        winnerIsA.current = true
        // swal({
        //   title: 'Bout Update',
        //   icon: 'success',
        //   timer: 3000,
        // });
        // setWinnerIsA(true);
      }
      // if(scoreA < scoreB) {
      else {
        console.log("setting winnerIsA to false");
        const data = axios.patch(
          `api/bouts/update/${props.slugData}/`,
          {
            event: eventSlug,
            winner_is_a: false,
            fencer_a: fencerA,
            score_a: scoreA,
            fencer_a_hand: fencerAHand,
            fencer_a_yellow_card: fencerAYellowCard,
            fencer_a_red_card: fencerARedCard,
            fencer_a_black_card: fencerABlackCard,
            fencer_a_passivity: fencerAPassivity,
            fencer_a_medical: fencerAMedical,
            fencer_a_video_used: fencerAVideoUsed,
            fencer_a_footwork: fencerAFootwork,
            fencer_a_bladework: fencerABladework,
            fencer_a_technique: fencerATechnique,
            fencer_a_distance: fencerADistance,
            fencer_a_energy: fencerAEnergy,
            fencer_a_notes: fencerANotes,
            fencer_b: fencerB,
            score_b: scoreB,
            fencer_b_hand: fencerBHand,
            fencer_b_yellow_card: fencerBYellowCard,
            fencer_b_red_card: fencerBRedCard,
            fencer_b_black_card: fencerBBlackCard,
            fencer_b_passivity: fencerBPassivity,
            fencer_b_medical: fencerBMedical,
            fencer_b_video_used: fencerBVideoUsed,
            fencer_b_footwork: fencerBFootwork,
            fencer_b_bladework: fencerBBladework,
            fencer_b_technique: fencerBTechnique,
            fencer_b_distance: fencerBDistance,
            fencer_b_energy: fencerBEnergy,
            fencer_b_notes: fencerBNotes,
            referee: referee,
            round: round,
            notes: boutNotes,
            tag_name: totalTags,
            bout_format: boutFormat,
            bout_type: boutType,
            sharable: sharable,
          },
          { withCredentials: true }
        )
        setFencerA("")
        setScoreA(0)
        setFencerAHand("")
        setFencerAVideoUsed(0)
        setFencerAMedical(false)
        setFencerANotes("")
        setFencerAFootwork(0)
        setFencerABladework(0)
        setFencerATechnique(0)
        setFencerADistance(0)
        setFencerAEnergy(0)
        setFencerAYellowCard(false)
        setFencerARedCard(false)
        setFencerABlackCard(false)
        setFencerAPassivity(false)
        setFencerB("")
        setScoreB(0)
        setFencerBHand("")
        setFencerBVideoUsed(0)
        setFencerBMedical(false)
        setFencerBNotes("")
        setFencerBFootwork(0)
        setFencerBBladework(0)
        setFencerBTechnique(0)
        setFencerBDistance(0)
        setFencerBEnergy(0)
        setFencerBYellowCard(false)
        setFencerBRedCard(false)
        setFencerBBlackCard(false)
        setFencerBPassivity(false)
        setRound(0)
        setBoutFormat("")
        setBoutType("")
        setReferee("")
        setBoutNotes("")
        settotalTags([])
        setShareable("")
        props.setDataReceived(false)
        props.setDataUpdate(true)
        winnerIsA.current = false
        // console.log('player B', data)
        // setWinnerIsA(false);
        // swal({
        //   title: 'Bout Update',
        //   icon: 'success',
        //   timer: 3000,
        // });
      }
    } catch (error: any) {
      swal({
        title: "Error",
        text: error.response?.data?.detail,
        icon: "warning",
        dangerMode: true,
      })
    }
    props.setOpenData(false);
    props.setSlugData("");
  }

  const [usaFencingInfo, setUSAFencingInfo] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [club, setClub] = useState<string>("");
  const [club2, setClub2] = useState<string>("");
  const [school, setSchool] = useState<string>("");
  const [division, setDivision] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [nationality, setNationality] = useState<string>("");
  const [handed, setHanded] = useState<string>("");
  const [grip, setGrip] = useState<string>("");
  const [ratingEpee, setRatingEpee] = useState<string>("");
  const [ratingSabre, setRatingSabre] = useState<string>("");
  const [ratingFoil, setRatingFoil] = useState<string>("");
  const [refRatingEpee, setRefRatingEpee] = useState<string>("");
  const [refRatingSabre, setRefRatingSabre] = useState<string>("");
  const [refRatingFoil, setRefRatingFoil] = useState<string>("");
  const [customRating, setcustomRating] = useState<string>("");
  const [validations, setValidations] = useState(false);
  const [dataReceived, setDataReceived] = useState(false);
  // const [timing, setTiming] = useState<string>('')
  // const [distance, setDistance] = useState<string>('')
  // const [footwork, setFootwork] = useState<string>('')
  // const [bladework, setBladework] = useState<string>('')
  // const [endurance, setEndurance] = useState<string>('')
  // const [strength, setStrength] = useState<string>('')
  const [tacticDescript, setTacticDescript] = useState<string>("");
  const [favActions, setFavActions] = useState<string>("");
  const [notes, setNotes] = useState<string>("");

  // Send form to the backend to be processed
  const submit = async (e: SyntheticEvent) => {
    e.preventDefault(); // prevents page from reloading.
    if (firstName && lastName && region) {
      await getNewRefreshToken();
      const { data } = await axios.post(
        "/api/fencers/create/",
        {
          first_name: firstName,
          last_name: lastName,
          club,
          club2,
          school,
          division,
          region,
          nationality,
          handed,
          primary_grip: grip,
          usaf_rating_epee: ratingEpee,
          usaf_rating_sabre: ratingSabre,
          usaf_rating_foil: ratingFoil,
          ref_rating_epee: refRatingEpee,
          ref_rating_sabre: refRatingSabre,
          ref_rating_foil: refRatingFoil,
          custom_rating: customRating,
          tactical_description: tacticDescript,
          favorite_actions: favActions,
          notes,
        },
        { withCredentials: true }
      );
      setValidations(false);
      setFirstName("");
      setLastName("");
      setClub("");
      setClub2("");
      setSchool("");
      setDivision("");
      setRegion("");
      setNationality("");
      setHanded("");
      setGrip("");
      setRatingEpee("");
      setRatingSabre("");
      setRatingFoil("");
      setRefRatingEpee("");
      setRefRatingSabre("");
      setRefRatingFoil("");
      setcustomRating("");
      setTacticDescript("");
      setFavActions("");
      setNotes("");
      setOpen(false);
      props.setfencerupdate(true);
    } else {
      setValidations(true);
    }
  };
  
  const getData = async () => {
    if (dataReceived === false) {
      const result = await axios.get("api/bouts/detail/" + props.slugData, {
        withCredentials: true,
      });
      setDataReceived(true);
      setRound(result.data.round);
      setBoutFormat(result.data.bout_format);
      setBoutType(result.data.bout_type);
      setReferee(result.data.referee);
      setBoutNotes(result.data.notes);
      settotalTags(result.data.bout_tags)
      setShareable(result.data.shareable);
      setFencerA(result.data.fencer_a);
      setScoreA(result.data.score_a);
      setFencerAHand(result.data.fencer_a_hand);
      setFencerAVideoUsed(result.data.fencer_a_video_used);
      setFencerAMedical(result.data.fencer_a_medical);
      setFencerANotes(result.data.fencer_a_notes);
      setFencerAYellowCard(result.data.fencer_a_yellow_card);
      setFencerARedCard(result.data.fencer_a_red_card);
      setFencerABlackCard(result.data.fencer_a_black_card);
      setFencerAPassivity(result.data.fencer_a_passivity);
      setFencerAFootwork(result.data.fencer_a_footwork);
      setFencerABladework(result.data.fencer_a_bladework);
      setFencerATechnique(result.data.fencer_a_technique);
      setFencerADistance(result.data.fencer_a_distance);
      setFencerAEnergy(result.data.fencer_a_energy);
      setFencerB(result.data.fencer_b)
      setScoreB(result.data.score_b);
      setFencerBHand(result.data.fencer_b_hand);
      setFencerBVideoUsed(result.data.fencer_b_video_used);
      setFencerBMedical(result.data.fencer_b_medical);
      setFencerBNotes(result.data.fencer_b_notes);
      setFencerBYellowCard(result.data.fencer_b_yellow_card);
      setFencerBRedCard(result.data.fencer_b_red_card);
      setFencerBBlackCard(result.data.fencer_b_black_card);
      setFencerBPassivity(result.data.fencer_b_passivity);
      setFencerBFootwork(result.data.fencer_b_footwork);
      setFencerBBladework(result.data.fencer_b_bladework);
      setFencerBTechnique(result.data.fencer_b_technique);
      setFencerBDistance(result.data.fencer_b_distance);
      setFencerBEnergy(result.data.fencer_b_energy);
      // setBout(result.data)
      // setBout({
      //         id: result.data.id,
      //         slug: result.data.slug,
      //         winnerIsA: result.data.winner_is_a,
      //         fencerA: result.data.fencer_a,
      //         fencerB: result.data.fencer_b,
      //         scoreA: result.data.score_a,
      //         scoreB: result.data.score_b,
      //         fencerAHand: result.data.fencer__a_hand,
      //         fencerBHand: result.data.fencer_b_hand,
      //         fencerAYellowCard: result.data.fencer_a_yellow_card,
      //         fencerBYellowCard: result.data.fencer_b_yellow_card,
      //         fencerARedCard: result.data.fencer_a_red_card,
      //         fencerBRedCard: result.data.fencer_b_red_card,
      //         fencerABlackCard: result.data.fencer_a_black_card,
      //         fencerBBlackCard: result.data.fencer_b_black_card,
      //         fencerAPassivity: result.data.fencer_a_passivity,
      //         fencerBPassivity: result.data.fencer_b_passivity,
      //         fencerAMedical: result.data.fencer_a_medical,
      //         fencerBMedical: result.data.fencer_b_medical,
      //         fencerAVideoUsed: result.data.fencer_a_video_used,
      //         fencerBVideoUsed: result.data.fencer_b_video_used,
      //         fencerAFootwork: result.data.fencer_a_footwork,
      //         fencerBFootwork: result.data.fencer_b_footwork,
      //         fencerABladework: result.data.fencer_a_bladework,
      //         fencerBBladework: result.data.fencer_b_bladework,
      //         fencerADistance: result.data.fencer_a_distance,
      //         fencerBDistance: result.data.fencer_b_distance,
      //         fencerATechnique: result.data.fencer_a_technique,
      //         fencerBTechnique: result.data.fencer_b_technique,
      //         fencerAEnergy: result.data.fencer_a_energy,
      //         fencerBEnergy: result.data.fencer_b_energy,
      //         fencerANotes: result.data.fencer_a_notes,
      //         fencerBNotes: result.data.fencer_b_notes,
      //         referee: result.data.referee,
      //         boutFormat: result.data.bout_format,
      //         round: result.data.round,
      //         notes: result.data.notes,
      //         shareCoach: result.data.share_coach,
      //         deleted: result.data.deleted
      // })
    }
  };

  useEffect(() => {
    setDataReceived(false);
    getData();
  }, [props.slugData]);

  const handleCloseDailog = () => {
    props.setOpenData(false);
    props.setSlugData("");
    setFencerA("")
    setScoreA(0)
    setFencerAHand("")
    setFencerAVideoUsed(0)
    setFencerAMedical(false)
    setFencerANotes("")
    setFencerAFootwork(0)
    setFencerABladework(0)
    setFencerATechnique(0)
    setFencerADistance(0)
    setFencerAEnergy(0)
    setFencerAYellowCard(false)
    setFencerARedCard(false)
    setFencerABlackCard(false)
    setFencerAPassivity(false)
    setFencerB("")
    setScoreB(0)
    setFencerBHand("")
    setFencerBVideoUsed(0)
    setFencerBMedical(false)
    setFencerBNotes("")
    setFencerBFootwork(0)
    setFencerBBladework(0)
    setFencerBTechnique(0)
    setFencerBDistance(0)
    setFencerBEnergy(0)
    setFencerBYellowCard(false)
    setFencerBRedCard(false)
    setFencerBBlackCard(false)
    setFencerBPassivity(false)
    setRound(0)
    setBoutFormat("")
    setBoutType("")
    setReferee("")
    setBoutNotes("")
    settotalTags([])
    setShareable("")
  };

  function fencerADetail() {
    return (
      <section className="bout-tab-content">
        <div>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Add New Fencer Profile
          </Button>
          <Dialog className="dialog-div-N" open={open} onClose={handleClose}>
            <h1 className="h1 Fencertoptitle">New Fencer <span className="closebtn"> <CloseIcon onClick={handleClose} style={{ fontSize: '25px' }} /></span></h1>
            <div className="c-cl-t">
              <br />
              <SearchUSAFencing />
              <hr />
              <p style={{ textAlign: 'center' }}>or</p>

              <h1 className="Fencertitle">
                <span
                  onClick={() => setChecked(!checked)}
                  style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }}
                >
                  Custom Profile<PortraitIcon style={{ fontSize: '25px', marginLeft: '10px', marginTop: '2px' }} />
                </span>
              </h1>
              <br />
              <Collapse in={checked}>
                <form className="Profileform" onSubmit={submit}>
                  <CSRF />
                  <div className="Please-error">
                    <FormTextElement setValue={setFirstName} value={firstName} elementName="first" placeholder="" labelText="First Name"/>
                    {validations && !firstName && <p className="testerror">Please enter First name</p>}
                  </div>
                  <div className="Please-error">
                    <FormTextElement setValue={setLastName} value={lastName} elementName="last" placeholder="" labelText="Last Name"/>
                    {validations && !lastName && <p className="testerror">Please enter Last name</p>}
                  </div>
                  <FormSelectElement setValue={setHanded} value={handed} placeholder="" elementName="handed" selectOptions={HAND_CHOICES} labelText="Handedness" />
                  {validations && !lastName && <p className="testerror">Please enter Last name</p>}
                  <FormSelectElement setValue={setGrip} elementName="grip" value={grip} selectOptions={GRIP_CHOICES} placeholder="" labelText="Grip Preference"/>
                  <FormTextElement setValue={setClub} elementName="club" value={club} placeholder="" labelText="Club" />
                  <FormTextElement setValue={setClub2} elementName="club2" value={club2} placeholder="" labelText="Secondary Club" />
                  <FormTextElement setValue={setSchool} elementName="school" value={school} placeholder="" labelText="School" />
                  <FormTextElement setValue={setDivision} elementName="division" value={division} placeholder="" labelText="Division" />
                  <FormSelectElement setValue={setRegion} elementName="region" value={region} selectOptions={REGION_CHOICES} placeholder="" labelText="Region" />
                  <FormTextElement setValue={setNationality} value={nationality} elementName="nationality" placeholder="" labelText="Nationality" />
                  <FormSelectElement setValue={setRatingEpee} value={ratingEpee} elementName="ratingEpee" selectOptions={USAF_RATING_CHOICES} placeholder="" labelText="Epee Rating" />
                  <FormSelectElement setValue={setRatingSabre} value={ratingSabre} elementName="ratingSabre" selectOptions={USAF_RATING_CHOICES} placeholder="" labelText="Sabre Rating" />
                  <FormSelectElement setValue={setRatingFoil} value={ratingFoil} elementName="ratingFoil" selectOptions={USAF_RATING_CHOICES} placeholder="" labelText="Foil Rating" />
                  <FormSelectElement setValue={setRefRatingEpee} value={refRatingEpee} elementName="refRatingEpee" selectOptions={REF_RATING_CHOICES} placeholder="" labelText="Epee Referee Rating" />
                  <FormSelectElement setValue={setRefRatingSabre} value={refRatingSabre} elementName="refRatingSabre" selectOptions={REF_RATING_CHOICES} placeholder="" labelText="Sabre Referee Rating"/>
                  <FormSelectElement setValue={setRefRatingFoil} value={refRatingFoil} elementName="refRatingFoil" selectOptions={REF_RATING_CHOICES} placeholder="" labelText="Foil Referee Rating" />
                  <FormTextElement setValue={setcustomRating} value={customRating} elementName="customRating" placeholder="" labelText="Custom Rating"/>
                  {/* <FormRatingElement setValue={setTiming} elementName='timing' labelText='Timing'/>
                  <FormRatingElement setValue={setDistance} elementName='distance' labelText='Distance'/>
                  <FormRatingElement setValue={setFootwork} elementName='footwork' labelText='Footwork' />
                  <FormRatingElement setValue={setBladework} elementName='bladework' labelText='Bladework' />
                  <FormRatingElement setValue={setStrength} elementName='strength' labelText='Strength' /> */}
                  <FormTextElement setValue={setTacticDescript} value={tacticDescript} elementName="tacticalDescription" placeholder="" labelText="Tactical Description" />
                  <FormTextElement setValue={setFavActions} value={favActions} elementName="favActions" placeholder="" labelText="Favorite Actions" />
                  <FormTextElement setValue={setNotes} value={notes} elementName="notes" placeholder="" labelText="Notes" />
                  <button className="submit-button Fenceradd" type="button" onClick={submit}>
                    Add New Fencer
                  </button>
                </form>
              </Collapse>
            </div>
          </Dialog>
        </div>
        
        <h2>Fencer A</h2>
        <div className="Please-error">
          <FormSelectElement setValue={setFencerA} value={fencerA} placeholder="" elementName="fencer-a" labelText="Select Fencer" selectOptions={fencerOptions}/>
          {validations2 && !fencerA && <p className="testerror">Please enter fencer A</p>}
        </div>
        <div className="Please-error">
          <FormNumberElement setValue={setScoreA} value={scoreA} elementName="score-a" labelText="Score A" />
          {validations2 && !scoreA && <p className="testerror">Please enter score </p>}
        </div>
        {/* <div className="Please-error"> */}
          <FormSelectElement setValue={setFencerAHand} value={fencerAHand} placeholder="" elementName="fencer-a-hand" labelText="Select Hand" selectOptions={HAND_CHOICES} />
          {/* {validations2 && !fencerAHand && <p className="testerror">Please select hand </p>}
        </div> */}
        {/* <div className="Please-error"> */}
          <FormNumberElement setValue={setFencerAVideoUsed} value={fencerAVideoUsed} elementName="fencer-a-video-used" labelText="Video Used" />
          {/* {validations2 && !fencerAVideoUsed && <p className="testerror">Please enter video used</p>}
        </div> */}
        {/* <div className="toggle1">
        <FormCheckBoxElement setValue={setFencerAMedical} value={fencerAMedical} labelText="Medical Timeout" />
        </div> */}
        {/* <div className="Please-error"> */}
          <FormTextElement setValue={setFencerANotes} value={fencerANotes} elementName="fencer-a-notes" placeholder="" labelText="Notes" />
          {/* {validations2 && !fencerANotes && <p className="testerror">Please enter notes </p>}
        </div> */}

        <h2>Cards:</h2>
        <FormCheckBoxElement setValue={setFencerAMedical} value={fencerAMedical} labelText="Medical Timeout" />
        <FormCheckBoxElement setValue={setFencerAYellowCard} value={fencerAYellowCard} labelText="Yellow Card" />
        <FormCheckBoxElement setValue={setFencerARedCard} value={fencerARedCard} labelText="Red Card" />
        <FormCheckBoxElement setValue={setFencerABlackCard} value={fencerABlackCard} labelText="Black Card" />
        <FormCheckBoxElement setValue={setFencerAPassivity} value={fencerAPassivity} labelText="Passivity" />

        <h2>Fencer Ratings:</h2>
        <FormRatingElement setValue={setFencerAFootwork} value={fencerAFootwork} labelText="Footwork" />
        <FormRatingElement setValue={setFencerABladework} value={fencerABladework} labelText="Bladework" />
        <FormRatingElement setValue={setFencerATechnique} value={fencerATechnique} labelText="Technique" />
        <FormRatingElement setValue={setFencerADistance} value={fencerADistance} labelText="Distance"/>
        <FormRatingElement setValue={setFencerAEnergy} value={fencerAEnergy} labelText="Energy" />
      </section>
    );
  }

  function fencerBDetail() {
    return (
      <section className="bout-tab-content">
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Add New Fencer Profile
        </Button>

        <h2>Fencer B</h2>
        <div className="Please-error">
          <FormSelectElement setValue={setFencerB} value={fencerB} placeholder="" elementName="fencer-b" labelText="Select Fencer" selectOptions={fencerOptions} />
          {validations2 && !fencerB && <p className="testerror">Please enter fencer B</p>}
        </div>
        <div className="Please-error">
          <FormNumberElement setValue={setScoreB} value={scoreB} elementName="score-b" labelText="Score B" />
          {validations2 && !scoreB && <p className="testerror">Please enter score </p>}
        </div>
        {/* <div className="Please-error"> */}
          <FormSelectElement setValue={setFencerBHand} value={fencerBHand} placeholder="" elementName="fencer-b-hand" labelText="Select Hand" selectOptions={HAND_CHOICES} />
          {/* {validations2 && !fencerBHand && <p className="testerror">Please select hand </p>}
        </div> */}
        {/* <div className="Please-error"> */}
          <FormNumberElement setValue={setFencerBVideoUsed} value={fencerBVideoUsed} elementName="fencer-b-video-used" labelText="Video Used" />
          {/* {validations2 && !fencerBVideoUsed && <p className="testerror">Please enter video used</p>}
        </div> */}
        {/* <div className="Please-error"> */}
          <FormTextElement setValue={setFencerBNotes} value={fencerBNotes} elementName="fencer-b-notes" placeholder="" labelText="Notes" />
          {/* {validations2 && !fencerBNotes && <p className="testerror">Please enter notes </p>}
        </div> */}

        <h2>Cards:</h2>
        <FormCheckBoxElement setValue={setFencerBMedical} value={fencerBMedical} labelText="Medical Timeout" />
        <FormCheckBoxElement setValue={setFencerBYellowCard} value={fencerBYellowCard} labelText="Yellow Card" />
        <FormCheckBoxElement setValue={setFencerBRedCard} value={fencerBRedCard} labelText="Red Card" />
        <FormCheckBoxElement setValue={setFencerBBlackCard} value={fencerBBlackCard} labelText="Black Card" />
        <FormCheckBoxElement setValue={setFencerBPassivity} value={fencerBPassivity} labelText="Passivity" />

        <h2>Fencer Ratings:</h2>
        <FormRatingElement setValue={setFencerBFootwork} value={fencerBFootwork} labelText="Footwork" />
        <FormRatingElement setValue={setFencerBBladework} value={fencerBBladework} labelText="Bladework" />
        <FormRatingElement setValue={setFencerBTechnique} value={fencerBTechnique} labelText="Technique" />
        <FormRatingElement setValue={setFencerBDistance} value={fencerBDistance} labelText="Distance" />
        <FormRatingElement setValue={setFencerBEnergy} value={fencerBEnergy} labelText="Energy" />
      </section>
    );
  }

  const handleBoutTagss = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputerror(false)
    setTags(e.target.value)
    const query = e.target.value;
    if (query !== '') {
      try {
        await getNewRefreshToken();
        const response = await axios.get(`api/bouts/boutname/?search=${query}`, {
          withCredentials: true,
        });
        setsearchList(response.data)
      } catch (error) {
        console.error('POST API Error:', error);
      }
    }
    if (query === '') {
      setsearchList([])
    }
  }

  const handleBoutTags = (e: React.KeyboardEvent) => {
    if (e.key == 'Enter' && tags.trim() !== '') {
      if (!totalTags.includes(tags)) {
        settotalTags(prevArray => [...prevArray, tags]);
        setTags('')
        setsearchList([])
      }
      else {
        setInputerror(true)
      }
    }
  }

  const handleDivClick = (e: SyntheticEvent) => {
    e.preventDefault();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  
  const handleDel = (i: number, e: SyntheticEvent) => {
    e.preventDefault();
    const filBout = totalTags.filter((item, index) => index !== i)
    settotalTags(filBout)
  }

  const handleSelectValue = (value: string) => {
    setTags(value)
    setInputerror(false)
  }

  function boutOverallDetail() {
    return (
      <section className="bout-tab-content">
        <h2>Overall Data: </h2>
        <div className="Please-error">
          <FormNumberElement setValue={setRound} value={round} elementName="round" labelText="Round" />
          {validations2 && !round && <p className="testerror">Please enter rounds </p>}
        </div>
        {/* What is this???? */}
        {/* <div className="Please-error">
          <FormSelectElement setValue={setBoutFormat} value={boutFormat} placeholder="" elementName="bout-format" labelText="Bout Format" selectOptions={HAND_CHOICES} />
          {validations2 && !boutFormat && <p className="testerror">Please select bout format </p>}
        </div> */}
        <div className="Please-error">
          <FormSelectElement setValue={setBoutType} value={boutType} placeholder="" elementName="bout-type" labelText="Bout Type" selectOptions={Bout_Type_Options} />
          {validations2 && !boutType && <p className="testerror">Please select bout type </p>}
        </div>
        {/* <div className="Please-error"> */}
          <FormTextElement setValue={setReferee} value={referee} elementName="referee" placeholder="" labelText="Referee" />
          {/* {validations2 && !referee && <p className="testerror"> Please enter referee </p>}
        </div> */}
        {/* <div className="Please-error"> */}
          <FormTextElement setValue={setBoutNotes} value={boutNotes} elementName="bout-notes" placeholder="" labelText="Overall Notes"/>
          {/* {validations2 && !boutNotes && <p className="testerror"> Please enter bout notes </p>}
        </div> */}

        {/* Tags Field */}
        {/* <div className="Please-error">
          <div className='form-input'> 
          <label htmlFor='nameInput'>Bout Tags</label> 
          <div onClick={handleDivClick} className='input-div12'>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {totalTags.map((item, index) => (
                  <span className="bout-tagg-span" key={index}>{item} <CloseIcon className='icon-212' onClick={(e) => handleDel(index, e)} /></span>
                ))}
              </div>
              <div style={{ position: 'relative' }}>
                <input
                  type='text'
                  list="item-options"
                  id="item-options"
                  value={tags}
                  ref={inputRef}
                  onChange={handleBoutTagss}
                  onKeyDown={handleBoutTags}
                />
                {searchList.length === 0 ? <div className="show-list1222" style={{ display: 'none' }}></div>
                  : <div className="show-list1222">
                    {searchList.map((item, i) => (
                      <p onClick={() => handleSelectValue(item.tag_name)}>{item.tag_name}</p>
                    ))}
                  </div>}
              </div>
              {inputError && <span style={{ color: "red" }}>Already exits</span>}
            </div>
          </div>
          {validations2 && totalTags.length === 0 && <p className="testerror">Please enter bout tags </p>}
        </div> */}

        <div className="Please-error">
          <FormSelectElement setValue={setShareable} value={sharable} placeholder="" elementName="shareable" labelText="Shareable" selectOptions={SHAREABLE_CHOICES} />
          {validations2 && !sharable && <p className="testerror"> Please select sharable </p>}
        </div>
      </section >
    );
  }

  function setWinnerIsAFn(value: any) {
    winnerIsA.current = value
  }

  return (
    <>
      {showDialogBox ? (
        <DialogBox
          title={"Tied Score"}
          message={"Please select a winner."}
          textActionA={"Player A"}
          textActionB={"Player B"}
          actionA={() => setWinnerIsAFn(true)}
          actionB={() => {
            setWinnerIsAFn(false);
          }}
          cancelAction={() => setShowDialogBox(false)}
          submit={sendData}
        />
      ) : null}
      <form className="newbout">
        <CSRF />
        <div className="bout-tabs bout-tabs-padding">
          <input
            className="bout-tab-radio"
            type="radio"
            id="overall"
            name="bout-info"
            defaultChecked
          />
          <label className="bout-tab-radio" htmlFor="overall">
            {/* <i className="fa fa-pencil font-20" /> */}
            <EditIcon />
            <br />
            <span>Overall</span>
          </label>
          {boutOverallDetail()}

          <input
            className="bout-tab-radio"
            type="radio"
            id="fencer-a"
            name="bout-info"
          />
          <label className="bout-tab-radio" htmlFor="fencer-a">
            {/* <i className="fa fa-pencil font-20" /> */}
            <EditIcon />
            <br />
            <span>Fencer A</span>
          </label>
          {fencerADetail()}

          <input
            className="bout-tab-radio"
            type="radio"
            id="fencer-b"
            name="bout-info"
          />
          <label className="bout-tab-radio" htmlFor="fencer-b">
            {/* <i className="fa fa-pencil font-20" /> */}
            <EditIcon />
            <br />
            <span>Fencer B</span>
          </label>
          {fencerBDetail()}

        </div>
        <button
          className="submit-button Fenceradd"
          type="button"
          onClick={() => handleClick()}
        >
          Add Bout
        </button>
        {/* <button className="winnerbtn" type="button" onClick={() => setWinnerIsA(null)}>
          reset winner_is_a
        </button> */}
      </form>

      <Dialog
        className="profileImgDialognew profilediv1"
        open={typeof props.openData === "boolean" ? props.openData : false}
        onClose={handleCloseDailog}
      >
        <h1 className="h1 Bouttitle">Edit Bout</h1>
        <span className="crossTagsDiv">
          {" "}
          <CloseIcon onClick={handleCloseDailog} style={{ fontSize: '20px', marginTop: '13px' }} />
        </span>

        <DialogContent className="image_and_namesinvite_new">

          <div>
            {/* <BoutForm
              fencerList={fencerList}
              setfencerupdate={setfencerupdate}
              slugData={slugData}
              setSlugData={setSlugData}
              setOpenData={setOpenData}
              openData={openData}
            /> */}
            {/* <SecondBoutForm
              fencerList={props.fencerList}
              setfencerupdate={props.setfencerupdate}
              setOpenData={props.setOpenData}
              slugData={props.slugData}
              setSlugData={props.setSlugData}
            /> */}

            <form>
              <CSRF />
              <div className="bout-tabs">
                <input
                  className="bout-tab-radio"
                  type="radio"
                  id="overallsec"
                  name="bout-info"
                  defaultChecked
                />
                <label className="bout-tab-radio" htmlFor="overallsec">
                  <i className="fa fa-pencil font-20" />
                  <br />
                  <span>Overall</span>
                </label>
                {boutOverallDetail()}

                <input
                  className="bout-tab-radio"
                  type="radio"
                  id="fencer-asec"
                  name="bout-info"
                />
                <label className="bout-tab-radio" htmlFor="fencer-asec">
                  <i className="fa fa-pencil font-20" />
                  <br />
                  <span>Fencer A</span>
                </label>
                {fencerADetail()}

                <input
                  className="bout-tab-radio"
                  type="radio"
                  id="fencer-bsec"
                  name="bout-info"
                />
                <label className="bout-tab-radio" htmlFor="fencer-bsec">
                  <i className="fa fa-pencil font-20" />
                  <br />
                  <span>Fencer B</span>
                </label>
                {fencerBDetail()}
              </div>
              <button
                className="submit-button Fenceradd"
                type="button"
                onClick={() => handleUpdate()}
              >
                Update
              </button>
              {/* <button className="winnerbtn" type="button" onClick={() => setWinnerIsA(null)}>
                reset winner_is_a
              </button> */}
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
