import React from 'react'
import { useState, SyntheticEvent, useEffect } from "react";
import axios from "axios"
import {useDispatch} from "react-redux";
import {setAuth} from "../../redux/authSlice";
// import { SearchUSAFencing } from './form elements/SearchUSAFencing';
import { FormTextElement } from "../../components/forms/form elements/FormTextElement";
import PortraitIcon from '@mui/icons-material/Portrait';
import { FormSelectElement } from "../../components/forms/form elements/FormSelectElement";
import {
    RATING_NUMBER_CHOICES,
    REGION_CHOICES,
    HAND_CHOICES,
    GRIP_CHOICES,
    REF_RATING_CHOICES,
    USAF_RATING_CHOICES,
} from "../../constants/VarOptions";
import {fencerDataType, registerDataType} from "../../constants/VarTypes";
import { CSRF } from "../../components/forms/form elements/CSRF";
import Collapse from "@mui/material/Collapse";
import swal from "sweetalert";


export const RegisterSecondPage = ({
    setPageNumber,
    setSkip,
    setFencerData,
    fencerData,
    registerData
}:{
    setPageNumber: React.Dispatch<React.SetStateAction<number>>,
    setSkip: React.Dispatch<React.SetStateAction<boolean>>,
    setFencerData: React.Dispatch<React.SetStateAction<fencerDataType>>,
    fencerData: fencerDataType
    registerData: registerDataType
}) => {
    const dispatch = useDispatch();
    const [validations_usaf, setValidations_usaf] = useState(false);
    const [checked, setChecked] = useState(false);
    const [open, setopen] = useState(true);
    

    // no required fields
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log('event: ', e.target)
        const { name, value } = e.target;
        setFencerData((prevData: any) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // custom function used to meet subcomponent requirements for select
    // not sure why the above does not work
    const setGrip = (value: string) => {
        setFencerData((prevData) => ({
          ...prevData,
          grip: value,
        }));
      };
      const setHanded = (value: string) => {
        setFencerData((prevData) => ({
          ...prevData,
          handed: value,
        }));
      };
      const setRegion = (value: string) => {
        setFencerData((prevData) => ({
          ...prevData,
          region: value,
        }));
      };
      const setRatingEpee = (value: string) => {
        setFencerData((prevData) => ({
          ...prevData,
          ratingEpee: value,
        }));
      };
      const setRatingFoil = (value: string) => {
        setFencerData((prevData) => ({
          ...prevData,
          ratingFoil: value,
        }));
      };
      const setRatingSabre = (value: string) => {
        setFencerData((prevData) => ({
          ...prevData,
          ratingSabre: value,
        }));
      };
      const setRefRatingEpee = (value: string) => {
        setFencerData((prevData) => ({
          ...prevData,
          refRatingEpee: value,
        }));
      };
      const setRefRatingFoil = (value: string) => {
        setFencerData((prevData) => ({
          ...prevData,
          refRatingFoil: value,
        }));
      };
      const setRefRatingSabre = (value: string) => {
        setFencerData((prevData) => ({
          ...prevData,
          refRatingSabre: value,
        }));
      };


    // setopen and setChecked handle is custom form is open or not
    // idk why there are different vars for opened and checked and I don't care right now
    const handleCreateFencerClick = () => {
        setopen(false)
        setChecked(true)
        setFencerData((prevData) => {
            return {
                ...prevData,
                usaFencingInfo: "",
            }
        })
    }

    const handleAlreadyFencerClick = () => {
        setChecked(false)
        setopen(true)
    }

    const createUser = () => {
        axios.post(
            'api/auth/register', 
            {
                user: {
                    first_name: registerData.firstName,
                    last_name: registerData.lastName,
                    email: registerData.email,
                    password: registerData.password,
                    password_confirm: registerData.passwordConfirm
                },
                fencer: {
                    first_name: registerData.firstName,
                    last_name: registerData.lastName,
                    usa_fencing_info: fencerData.usaFencingInfo,
                    club: fencerData.club,
                    club2: fencerData.club2,
                    school: fencerData.school,
                    division: fencerData.division,
                    region: fencerData.region,
                    nationality: fencerData.nationality,
                    handed: fencerData.handed,
                    primary_grip: fencerData.grip,
                    usfa_rating_epee: fencerData.ratingEpee,
                    usfa_rating_foil: fencerData.ratingFoil,
                    usfa_rating_sabre: fencerData.ratingSabre,
                    ref_rating_epee: fencerData.refRatingEpee,
                    ref_rating_foil: fencerData.refRatingFoil,
                    ref_rating_sabre: fencerData.refRatingSabre,
                    custom_rating: fencerData.customRating,
                    tactical_description: fencerData.tacticDescript,
                    favorite_actions: fencerData.favActions,
                    notes: fencerData.notes
                }
            }, { withCredentials: true}
        ).then(
            (response) => {
                dispatch(setAuth(true));
                // stores bearer token in the cookies
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${response.data.token}`;
                console.log("login successfully..")
            })
    }

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        if (fencerData.usaFencingInfo.length !== 10) {
            setValidations_usaf(true)
            return
        }
        // make sure that the creation goes through before moving on
        try{
            createUser()
        } catch (error) {
            alert("Error creating user")
            console.log(error)
            return
        }
        setPageNumber(3)
        setSkip(false)
    };


    const handleSkip = async () => {
        swal({
            title: "Do you want to skip the step",
            className: "icon-popup",
            buttons: ['Cancel', 'Skip'],
        }).then( () => {
            try{
                createUser()
            } catch (error) {
                alert("Error creating user")
                console.log(error)
                return
            }
            setSkip(true)
            setPageNumber(3)
        });
    }

    return (
                <>
                    <p onClick={handleSkip} className='loginpage-skipnw'>Skip for Now</p>
                    
                    <h1 className="Fencertitle">
                        <span onClick={handleAlreadyFencerClick} style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }}>
                            USA Fencing ID <PortraitIcon style={{ fontSize: '25px', marginLeft: '10px', marginTop: '2px' }} />
                        </span>
                    </h1>

                    <br />

                    {open ? <div className="">
                        <form className="fencerform" onSubmit={submit}>
                            <div className="Please-error">
                                <div className='form-input'>
                                    <input id='nameInput' name='usaFencingInfo' type='numeric' placeholder="1000000000" onChange={handleInputChange}/> 
                                    <label htmlFor='nameInput'>USA Fencing ID</label>
                                </div>
                                {validations_usaf && (
                                    <p className="testerror11">Please enter all 10 digits of your USA Fencing Membership ID</p>
                                )}
                            </div>
                            <FormSelectElement setValue={setHanded} elementName="handed" value={fencerData.handed} selectOptions={HAND_CHOICES} placeholder="" labelText="Handedness"/>
                            <FormSelectElement setValue={setGrip} elementName="grip" value={fencerData.grip} selectOptions={GRIP_CHOICES} placeholder="" labelText="Grip Preference"/>
                            {/* <FormSelectElement setValue={(e)=>{fencerData.grip = e.target}} elementName="grip" value={fencerData.grip} selectOptions={GRIP_CHOICES} placeholder="" labelText="Grip Preference"/> */}
                        </form>
                    </div> : null}

                    <form className='auth-form'>
                        <p style={{ fontWeight: '600' }}>OR</p>
                        <h1 className="Fencertitle">
                            <span onClick={handleCreateFencerClick} style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }}>
                                Create Fencer Profile <PortraitIcon style={{ fontSize: '25px', marginLeft: '10px', marginTop: '2px' }} />
                            </span>
                        </h1>

                        <br />

                        <Collapse in={checked}>
                                <CSRF />
                                <FormSelectElement setValue={setGrip} elementName="handed" value={fencerData.handed} selectOptions={HAND_CHOICES} placeholder="" labelText="Handedness"/>
                                <FormSelectElement setValue={setHanded} elementName="grip" value={fencerData.grip} selectOptions={GRIP_CHOICES} placeholder="" labelText="Grip Preference"/>
                                <FormTextElement setValue={handleInputChange} elementName="club"  value={fencerData.club} placeholder="" labelText="Club"/>
                                <FormTextElement  setValue={handleInputChange} elementName="club2"  value={fencerData.club2} placeholder="" labelText="Secondary Club" />
                                <FormTextElement setValue={handleInputChange}  elementName="school" value={fencerData.school} placeholder="" labelText="School"/>
                                <FormTextElement setValue={handleInputChange} elementName="division" value={fencerData.division} placeholder=""  labelText="Division"/>
                                <FormSelectElement setValue={setRegion} elementName="region" value={fencerData.region} selectOptions={REGION_CHOICES} placeholder="" labelText="Region"/>
                                <FormTextElement setValue={handleInputChange} value={fencerData.nationality} elementName="nationality" placeholder="" labelText="Nationality"/>
                                <FormSelectElement setValue={setRatingEpee} value={fencerData.ratingEpee} elementName="ratingEpee" selectOptions={USAF_RATING_CHOICES} placeholder="" labelText="Epee Rating"/>
                                <FormSelectElement setValue={setRatingFoil}  value={fencerData.ratingFoil} elementName="ratingFoil" selectOptions={USAF_RATING_CHOICES} placeholder="" labelText="Foil Rating"/>
                                <FormSelectElement setValue={setRatingSabre} value={fencerData.ratingSabre} elementName="ratingSabre" selectOptions={USAF_RATING_CHOICES} placeholder="" labelText="Sabre Rating"/>
                                <FormSelectElement setValue={setRefRatingEpee} value={fencerData.refRatingEpee} elementName="refRatingEpee" selectOptions={REF_RATING_CHOICES} placeholder="" labelText="Epee Referee Rating"/>
                                <FormSelectElement setValue={setRefRatingFoil}  value={fencerData.refRatingFoil} elementName="refRatingFoil" selectOptions={REF_RATING_CHOICES} placeholder="" labelText="Foil Referee Rating" />
                                <FormSelectElement setValue={setRefRatingSabre} value={fencerData.refRatingSabre} elementName="refRatingSabre" selectOptions={REF_RATING_CHOICES} placeholder=""  labelText="Sabre Referee Rating"/>
                                <FormTextElement setValue={handleInputChange} value={fencerData.customRating} elementName="customRating"  placeholder=""  labelText="Custom Rating" />
                                <FormTextElement setValue={handleInputChange} value={fencerData.tacticDescript} elementName="tacticalDescript" placeholder="" labelText="Tactical Description"/>
                                <FormTextElement setValue={handleInputChange} value={fencerData.favActions} elementName="favActions" placeholder="" labelText="Favorite Actions"/>
                                <FormTextElement setValue={handleInputChange} value={fencerData.notes} elementName="notes" placeholder="" labelText="Notes"/>
                        </Collapse>

                        <hr />

                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <button className="submit-button Fencerbtn" type="button" onClick={() => setPageNumber(1)}>
                                Previous
                            </button>
                            <button className="submit-button Fencerbtn"type="button" onClick={submit}>
                                Create
                            </button>
                        </div>

                    </form>
                </>
    )
}

export default RegisterSecondPage;

