import { useEffect, useState } from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
// import { MouseEvent as ReactMouseEvent } from 'react';
import { MouseEvent } from "react";
import { EventForm } from "../../components/forms/EventForm";
import { tournamentType, eventType } from "../../constants/VarTypes";
import { useConfirmAuth } from "../../hooks/utils";
import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import swal from 'sweetalert';
import { getNewRefreshToken } from "../../hooks/utils";
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

export const Events = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth.value);
  const { tournamentSlug } = useParams();
  const [events, setEvents] = useState<[eventType] | []>([]);
  const [tournament, setTournament] = useState<tournamentType>({
    id: 99,
    slug: "",
    name: "",
    location: "",
    date: "",
    url: "",
    notes: "",
  });
  const [dataReceived, setDataReceived] = useState<boolean>(false);
  const [dataUpdate, setDataUpdate] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [slugData, setSlugData] = useState<string>("");


  const getData = async () => {
    if (dataReceived === false) {
      await getNewRefreshToken();
      const result = await axios.get(
        "api/tournaments/detail/" + tournamentSlug,
        { withCredentials: true }
      );
      setDataReceived(true);
      setTournament(result.data);
      setEvents(
        result.data.events.map((value: any, key: number) => {
          return {
            id: key,
            slug: value.slug,
            name: value.name,
            date: value.date,
            weapon: value.weapon,
            eventLevel: value.event_type,
            notes: value.notes,
          };
        })
      );
    }
  };

  useEffect(() => {
    getData();
    setDataUpdate(false)
  }, [dataUpdate]);

  // this was giving me a state warning before but seems better now... be suspicious
  // hopefully use Query is better by the time I have to fix this
  useConfirmAuth(dataReceived);

  function handleClickOpen(
    event: React.MouseEvent<HTMLButtonElement>,
    slug: string
  ) {
    setSlugData(slug);
    setOpen(true);
    // setShowTour(false)
  }

  // console.log("slugData...", slugData)
  const handleDelete = async (
    event: React.MouseEvent<HTMLButtonElement>,
    slug: string
   ) => {
    event.preventDefault();
    if (auth) {
      try {
        const data = await axios.delete(`api/events/delete/${slug}/`, {
          withCredentials: true,
        });
        // console.log(data, 'freergegeg')
        setDataReceived(false);
        setDataUpdate(true);
        // use a type assertion to tell TypeScript that data is of type { token: string, status: number 

        //this is duplicated in login.tsx and should be cleaned up at some point

        // I don't remember why this is here
        if (data == undefined) {
          return;
        }

      } catch (error: any) {
        swal({
          title: "Error",
          text: error.response?.data?.detail,
          icon: "warning",
          dangerMode: true,
        })
      }
    } else {
      navigate("/login");
    }

  };

  const handleClose = () => {
    setOpen(false);
    setSlugData("");
  };


  function tournamentDetail() {
    return (
      <>
        <div className="container breadcrumbs">
          <div className="backtotournament">
            <p
              className="initialclass"
              style={{ float: "left", marginRight: "5px" }}
              onClick={() => navigate("/journal/tournaments")}
            >
              Tournaments /{" "}
            </p>
         
              <span>
                  {tournament.name}
              </span>
       
          </div>
        </div>
        {tournament.name ? (
          <div className="container">
            {tournament.name && <h1 className="nametitle">{tournament.name}</h1>}
            {tournament.location && <p className="detail">{tournament.location}</p>}
            {tournament.date && <p className="detail">{tournament.date}</p>}
            {tournament.url && <p className="detail">{tournament.url}</p>}
            {tournament.notes && <p className="detail">{tournament.notes}</p>}
          </div>
        ) : null}
      </>
    );
  }


  function eventList() {
    if (dataReceived === false) {
      return (
        <div className="container">
          <h1>Loading ... </h1>
        </div>
      );
    }

    if (events.length === 0 && dataReceived === true) {
      return (
        <div className="container">
          <h3 className="evntnttitle">You do not have any events yet. Enter an event below!</h3>
        </div>
      );
    }

    // console.log("events...",events)
    return (
      <div className="allevents">
        {Object.entries(events).map(([key, value]) => {
          if (value) {
            return (
              <div key={key} className="evant1">

                <div className="eventspage" onClick={() => navigate(`/journal/bouts/${value.slug}`)} >
   
                  <h2 >{value.name}</h2>
                  <p><b >Date: </b>{value.date}</p>
                  <p><b>Weapon: </b>{value.weapon}</p>
                  <p><b>Event Type: </b>{value.eventLevel}</p>
                  <p><b>Notes: </b>
                  <br />
                  {value.notes}</p>
                  {/* <p >{value.notes}</p> */}
                </div>

                <hr />

                <div className="editDeletebtn editDeletebtn1">
                  <button onClick={(event) => handleClickOpen(event, value.slug)}>
                    <EditIcon style={{ color: 'white', fontSize: '20px' }} />
                  </button>
                  <button onClick={(event) => handleDelete(event, value.slug)}>
                    <DeleteIcon style={{ color: 'white', fontSize: '20px' }} />
                  </button>
                </div>

              </div>

            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }


  return (
    <>
      {tournamentDetail()}

      {eventList()}

      <div className="container">
        <EventForm slugData={slugData}
          setSlugData={setSlugData}
          setDataUpdate={setDataUpdate}
          setOpen={setOpen}
          setDataReceived={setDataReceived}
          btnValue={'Add Event'} />
      </div>

      <Dialog className="profileImgDialognew eventsdialog" open={open} onClose={handleClose}>

        <h1 className="h1 eventtitle1">
          Edit Event  
          <span className="crossTagsDiv2">
          {" "}
          <CloseIcon className="icon-pointer" onClick={handleClose} style={{ fontSize: '25px' }} />
          </span>
        </h1>

        <DialogContent className="image_and_namesinvite1">

          <div>
            <EventForm slugData={slugData}
              setSlugData={setSlugData}
              setDataUpdate={setDataUpdate}
              setOpen={setOpen}
              setDataReceived={setDataReceived}
              btnValue={'Update Event'} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
