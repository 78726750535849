import React, { useState, useEffect } from 'react'
import { setAuth } from '../../redux/authSlice'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom"
import axios from "axios";
import { getNewRefreshToken } from "../../hooks/utils";
import { getRefreshToken } from "../../interceptors/axios";
import swal from 'sweetalert';
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';


interface User {
  id: number;
  idd: number;
  first_name: string;
  last_name: string;
  user: number;
  from_student: number;
  from_coach: number;
  is_request: boolean;
  subscription: string;
  coach: number;
  student: number;
}

export const Friend = () => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const navigate = useNavigate()
  const [userid, setuserid] = useState<number>(0)
  const [friendsugg, setFriendsugg] = useState<User[]>([]);
  const [friendreq, setFriendreq] = useState<User[]>([]);
  const [friendreq2, setFriendreq2] = useState<User[]>([]);
  const [coachList, setcoachList] = useState<User[]>([]);
  const [coachCount, setCoachCount] = useState<number>(0)
  const [studentList, setStudentList] = useState<User[]>([]);
  const [studentCount, setStudentCount] = useState<number>(0)
  const [loadingFriendList, setLoadingFriendList] = useState<boolean>(true);
  const [loadingFriendreq, setLoadingFriendreq] = useState<boolean>(true);
  const [loadingFriendsugg, setLoadingFriendsugg] = useState<boolean>(true);
  const [datareceivesugg, setDatareceivesugg] = useState<boolean>(false);
  const [datareceivereq, setDatareceivereq] = useState<boolean>(false);
  const [datareceivelist, setDatareceivelist] = useState<boolean>(false);
  const [searchList, setSearchList] = useState<User[]>([]);
  const [openList, setOpenList] = useState<boolean>(false);
  const [isCoach, setIsCoach] = useState<boolean>(false);
  const [isfreeium, setIsfreeium] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [findfriend, setFindfriend] = useState<string>('');
  const [coachOpen, setCoachOpen] = useState<boolean>(true);
  const [studentOpen, setStudentOpen] = useState<boolean>(false);
  const dispatch = useDispatch()

  useEffect(() => {
    window.scroll(0, 0);
    var myData = localStorage.getItem('userData');
    if (myData) {
      dispatch(setAuth(true));
    }
    const data = localStorage.getItem('userData');
    console.log('useEffect data: ', data)
    if (data) {
      const userData = JSON.parse(data);
      setuserid(userData.id)
      if (userData.subscription === 'Premium') {
        setIsCoach(true);
      }
      if (userData.subscription === '') {
        setIsfreeium(true)
      }
    }
  }, []);

  // useEffect(() => {
  //   getRefreshToken();
  // }, []);

  const getfriendsugg = async () => {
    if (datareceivesugg === false) {
      try {
        await getNewRefreshToken();
        
        const response = await axios.get('api/profiles/connections-list', {
          withCredentials: true,
        });
        setFriendsugg(response.data)
        setDatareceivesugg(true)
        setLoadingFriendsugg(false)
        // console.log('API suggestion list:', response);
      } catch (error) {
        console.error('API Error in friendsugg :', error);
      }
    }
  };

  useEffect(() => {
    getfriendsugg();
  }, [loadingFriendsugg])

  const getfriendlist = async () => {
    if (datareceivelist === false) {
      try {
        await getNewRefreshToken();
        
        const response = await axios.get('api/profiles/friend-list/', {
          withCredentials: true,
        });
        setcoachList(response.data)
        setCoachCount(response.data.length)
        const res = await axios.get('api/profiles/student-friend-list/', {
          withCredentials: true,
        });
        setStudentList(res.data)
        setStudentCount(res.data.length)
        setLoadingFriendList(false)
        setDatareceivelist(true)
      } catch (error) {
        console.error('API Error in friendlist:', error);
      }
    }
  };

  const getfriendreq = async () => {
    if (datareceivereq === false) {
      try {
        await getNewRefreshToken();
        const res = await axios.get('api/profiles/add-request/', {
          withCredentials: true,
        });
        setFriendreq(res.data)

        const response = await axios.get('api/profiles/coach-request/', {
          withCredentials: true,
        });
        setFriendreq2(response.data)
        setDatareceivereq(true)
        setLoadingFriendreq(false)
      } catch (error) {
        console.error('API Error in friendreq:', error);
      }
    }
  };

  useEffect(() => {
    getfriendreq();
  }, [loadingFriendreq])

  useEffect(() => {
    getfriendlist();
  }, [loadingFriendList])

  const handleStudentAdd = async (id: number) => {
    swal({
      title: 'Confirmation',
      text: 'Are you want to add student?',
      icon: '/questionMark.png',
      buttons: ['Cancel', 'Add'],
      className: "icon-popup"
    }).then(async (confirmed) => {
      if (confirmed) {
        try {
          await getNewRefreshToken();
          const data = await axios.post(
            "api/profiles/send-coach-request/",
            {
              from_: userid,
              to_: id
            },
            { withCredentials: true }
          );
          swal({
            title: 'Success',
            timer: 3000,
            text: 'Request sent successfully',
            
            icon: 'success',
          })
          console.log("data post, response ", data);
          setDatareceivesugg(false)
          setLoadingFriendsugg(true)
        } catch (error) {
          console.error('API Error in handleStudentAdd:', error);
        }
      }
    });
  };

  const handleCoachAdd = (id: number) => {
    swal({
      title: 'Confirmation',
      text: `Are you want to add coach ?`,
      icon: '/questionMark.png',
      buttons: ['Cancel', 'Add'],
      className: "icon-popup"
    }).then(async (confirmed) => {
      if (confirmed) {
        try {
          await getNewRefreshToken();
          const data = await axios.post(
            "api/profiles/send-request/",
            {
              from_: userid,
              to_: id
            },
            { withCredentials: true }
          );
          swal({
            title: 'Success',
            timer: 3000,
            text: 'Request sent successfully',
            icon: 'success',
          })
          console.log("data post-------- response ", data);
          setDatareceivesugg(false)
          setLoadingFriendsugg(true)
        } catch (error) {
          console.error('API Error in handleStudentAdd:', error);
        }
      }
    });
  }

  const handleCoachReq = async (id: number) => {
    swal({
      title: 'Confirmation',
      text: 'Are you want to accept request of coach?',
      icon: '/questionMark.png',
      buttons: ['Cancel', 'Accept'],
      className: "icon-popup",
    }).then(async (confirmed) => {
      if (confirmed) {
        try {
          const data = await axios.post(
            "api/profiles/add-request/",
            {
              student: userid,
              coach: id
            },
            { withCredentials: true }
          );
          console.log("data post, response coach", data);
          swal({
            title: 'Success',
            timer: 3000,
            text: 'Request accept successfully',
            icon: 'success',
          })
          setDatareceivereq(false)
          setLoadingFriendreq(true)
          setDatareceivelist(false)
          setLoadingFriendList(true)
        } catch (error) {
          console.error('API Error:', error);
        }
      }
    });
  };

  const delCoachReq = async (id: number) => {
    swal({
      title: 'Confirmation',
      text: 'Are you want to delete the request of coach?',
      icon: '/questionMark.png',
      buttons: ['No', 'Yes'],
      className: "icon-popup",
    }).then(async (confirmed) => {
      if (confirmed) {
        try {
          const data = await axios.delete(
            `api/profiles/delete-student-request/?request_id=${id}`,
            // { withCredentials: true }
          );
          console.log("data post, response coach", data);
          swal({
            title: 'Success',
            timer: 3000,
            text: 'Coach request delete',
            icon: 'success',
          })
          setDatareceivereq(false)
          setLoadingFriendreq(true)
        } catch (error) {
          console.error('API Error:', error);
        }
      }
    });
  }

  const handleStudentReq = async (id: number) => {
    swal({
      title: 'Confirmation',
      text: 'Are you want to accept request of student?',
      icon: '/questionMark.png',
      buttons: ['Cancel', 'Accept'],
      className: "icon-popup",
    }).then(async (confirmed) => {
      if (confirmed) {
        try {

          const data = await axios.post(
            "api/profiles/add-request/",
            {
              coach: userid,
              student: id
            },
            { withCredentials: true }
          );
          console.log("data post, response coach", data);
          swal({
            title: 'Success',
            timer: 3000,
            text: 'Request accept successfully',
            icon: 'success',
          })
          setDatareceivereq(false)
          setLoadingFriendreq(true)
          setDatareceivelist(false)
          setLoadingFriendList(true)
        } catch (error) {
          console.error('API Error:', error);
        }
      }
    });
  };

  const delStudentReq = async (id: number) => {
    swal({
      title: 'Confirmation',
      text: 'Are you want to delete the request of student?',
      icon: '/questionMark.png',
      buttons: ['No', 'Yes'],
      className: "icon-popup",
    }).then(async (confirmed) => {
      if (confirmed) {
        try {
          const data = await axios.delete(
            `api/profiles/delete-coach-request/?request_id=${id}`,
            { withCredentials: true }
          );
          console.log("delete....", data);
          swal({
            title: 'Success',
            timer: 3000,
            text: 'Student Request delete',
            icon: 'success',
          })
          setDatareceivereq(false)
          setLoadingFriendreq(true)
        } catch (error) {
          console.error('API Error:', error);
        }
      }
    });
  }

  const friendDelete = async (id: number) => {
    swal({
      title: 'Confirmation',
      text: 'Are you sure you want to remove the friend?',
      icon: '/questionMark.png',
      buttons: ['No', 'Yes'],
      className: "icon-popup",
    }).then(async (confirmed) => {
      if (confirmed) {
        try {
          await getNewRefreshToken();
          const response = await axios.delete(`api/profiles/friend-list?connection_id=${id}`, {
            withCredentials: true,
          });
          swal({
            title: 'Success',
            timer: 3000,
            text: 'Friend remove',
            icon: 'success',
          })
          setDatareceivelist(false)
          setLoadingFriendList(true)
        } catch (error) {
          console.error('Delete API Error :', error);
        }
      }
    });
  }

  const handleInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setOpenList(false)
    }
    const query = e.target.value.trim();
    setFindfriend(query);
    if (query !== '') {
      try {
        await getNewRefreshToken();
        const response = await axios.get(`api/profiles/search-friend/?search=${query}`, {
          withCredentials: true,
        });

        setSearchList(response.data)
        setOpenList(true)
        console.log('API search list.........:', response.data);
      } catch (error) {
        console.error('API Error:', error);
      }
    } else {
      setSearchList([]);
    }
  }

  const closeList = () => {
    setOpenList(false)
    setFindfriend('')
  }
  const handleClickSubs = () => {
    navigate(`/subscription`)
  }

  const filteredcoachList = coachList.filter((friend) =>
    friend.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
    friend.last_name.toLowerCase().includes(searchText.toLowerCase())
  );

  const filteredstudentList = studentList.filter((friend) =>
    friend.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
    friend.last_name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleTabClick = (tabName: string) => {
    switch (tabName) {
      case 'Coach':
        setCoachOpen(true);
        setStudentOpen(false);
        break;
      case 'Student':
        setStudentOpen(true);
        setCoachOpen(false);
        break;
      default:
        break;
    }
  };

  const searchBox = () => {
    return (
      <div className="search-box-divv">
        <SearchIcon className="magnify" />
        <input className='search-box' type='search' onChange={handleInput} placeholder='Find Friend' value={findfriend}></input>
        <CloseIcon className='close-iconn' onClick={closeList} />
        {openList ? <div className='search-list'>
          {searchList.length === 0 ? <div>No match found</div> : null}
          {searchList.map((item,i) => (
            <div className='searchbar-list' key={i} >
              <div>{item.first_name} {item.last_name}</div>
              <div> {item.is_request ? null
                : item.subscription === 'Premium'
                  ? <><button className="searchbar-btnn " onClick={() => handleCoachAdd(item.user)}>Add coach</button>
                    <button className="searchbar-btnn " onClick={() => handleStudentAdd(item.user)}>Add student</button></>
                  : <button className="searchbar-btnn " onClick={() => handleStudentAdd(item.user)}>Add student</button>}
              </div>
            </div>
          ))}
        </div> : null}
      </div>
    )
  }

  const coachSection = () => {
    return (
      <div className='card-out card-out-margin'>
        {coachList.length === 0 ? (
          <div className='after-loading'>Donot have coach</div>
        ) : (
          <MDBTable>
            <MDBTableHead>
              <div className='input-input'>
                <SearchIcon className='magnify-2' />
                <input
                  type='text'
                  className='search-box2'
                  placeholder='Search by name...'
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </MDBTableHead>
            <MDBTableBody>
              {filteredcoachList.length === 0 ?
                <div className='after-loading' style={{ paddingBottom: '10px' }}>No matching coach found</div> : ""}
              {filteredcoachList.map((item,i) => (
                <div className='ff-card' key={i}>
                  <div> {item.first_name} {item.last_name}</div>
                  <div style={{display:'flex',alignItems:'center'}}>
                    <Link className='lessdetail-link' to={`/profile/lesson-list/${item.coach}`}>View Lessons</Link>
                    <DeleteIcon style={{ fontSize: '15px', cursor: 'pointer',marginTop:'2px',marginLeft:'10px' }} onClick={() => friendDelete(item.id)} />
                  </div>
                </div>
              ))}
            </MDBTableBody>
          </MDBTable>
        )}
      </div>
    )
  }

  const studentSection = () => {
    return (
      <div className='card-out card-out-margin'>
        {studentList.length === 0 ? (
          <div className='after-loading'>Donot have student</div>
        ) : (
          <MDBTable>
            <MDBTableHead>
              <div className='input-input'>
                <SearchIcon className='magnify-2' />
                <input
                  type='text'
                  className='search-box2'
                  placeholder='Search by name...'
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </MDBTableHead>
            <MDBTableBody>
              {filteredstudentList.length === 0 ?
                <div className='after-loading' style={{ paddingBottom: '10px' }}>No matching student found</div> : ""}
              {filteredstudentList.map((item,i) => (
                <div className='ff-card' key={i}>
                  <div> {item.first_name} {item.last_name} </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Link className='lessdetail-link' to={`/profile/lesson-list/${item.student}`}>View Lessons</Link>
                    <DeleteIcon style={{ fontSize: '15px', cursor: 'pointer', marginLeft:'10px',marginTop:'2px' }} onClick={() => friendDelete(item.id)} />
                  </div>
                </div>
              ))}
            </MDBTableBody>
          </MDBTable>
        )}
      </div>
    )
  }

  const coachRequest = () => {
    return (
      <div className='card-out'>
        {friendreq.length === 0 ? <div className='after-loading'>No Request here</div>
          : friendreq.length > 0 && friendreq.map((item,i) => (
            <div className='ff-card' key={i}>
              <div>
                <p>{item.first_name} {item.last_name}</p>
              </div>
              <div>
                <button className="flex-btnn btn-margin" onClick={() => handleCoachReq(item.from_coach)}>Accept</button>
                <button className="flex-btnn " onClick={() => delCoachReq(item.id)}>Delete</button>
              </div>
            </div>
          )
          )}
      </div>
    )
  }

  const studentRequest = () => {
    return (
      <div className='card-out'>
        {friendreq2.length === 0 ? <div className='after-loading'>No Request</div>
          : friendreq2.map((item,i) => (
            <div className='ff-card' key={i}>
              <div style={{ marginTop: '7px !important' }}>
                <p>{item.first_name} {item.last_name}</p>
              </div>
              <div>
                <button className="flex-btnn btn-margin" onClick={() => handleStudentReq(item.from_student)}>Accept</button>
                <button className="flex-btnn " onClick={() => delStudentReq(item.id)}>Delete</button>
              </div>
            </div>
          )
          )}
      </div>
    )
  }

  const friendSuggest = () => {
    return (
      <div className='card-out'>
        {friendsugg.length === 0 ? <div className='after-loading'>No suggestions</div> : ""}
        {friendsugg.map((item,i) => (
          <div className='ff-card' key={i}>
            <div>
              <p>{item.first_name} {item.last_name}</p>
            </div>
            <div>
              {isCoach ? item.subscription === 'Premium' ?
                <><button className="flex-btnn btn-margin" onClick={() => handleCoachAdd(item.user)}>Add Coach</button>
                  <button className="flex-btnn" onClick={() => handleStudentAdd(item.user)}>Add Student</button></>
                : <button className="flex-btnn" onClick={() => handleStudentAdd(item.user)}>Add Student</button>
                : <button className="flex-btnn " onClick={() => handleCoachAdd(item.user)}>Add Coach</button>}
            </div>
          </div>
        )
        )}
      </div>
    )
  }



  return (
    <>
     <div>
        {searchBox()}

        <div className='ff-container'>
          <div className='ff-1'>
            <div className="tab-section">
              {isCoach ? 
                <>
                  <h3 className='tabs-ss' onClick={() => handleTabClick('Coach')}>Coaches ({coachCount ? coachCount : 0}) <div className={coachOpen ? 'borderbottom' : ' '}></div></h3>
                  <h3 className='tabs-ss' onClick={() => handleTabClick('Student')}>Students ({studentCount ? studentCount : 0}) <div className={studentOpen ? 'borderbottom' : ' '}></div></h3>
                </>
                : <h3 className='tabs-ss' onClick={() => handleTabClick('Coach')}>Coaches ({coachCount ? coachCount : 0}) </h3>}
            </div>

            {coachOpen && (
              loadingFriendList ? (
                <div className='data-loading'>Data Loading....</div>
              ) : <>{coachSection()}</>
            )}

            {studentOpen && (
              loadingFriendList ? (
                <div className='data-loading'>Data Loading....</div>
              ) : <>{studentSection()}</>
            )}
          </div>

          <div className='ff-2'>
            <div className='ff-2-bottom-margin'>
              <p className='ff-para12'>Coaches' Requests</p>
              {loadingFriendreq ? <div className='data-loading'>Data Loading....</div>
                : <>{coachRequest()} </>
              }
            </div>

            {isCoach ? <div className='ff-2-bottom-margin'>
              <p className='ff-para12'>Students' Requests</p>
              {loadingFriendreq ? <div className='data-loading'>Data Loading....</div>
                : <>{studentRequest()}</>
              }
            </div>
              : null}

            <div className='ff-2-bottom-margin'>
              <p className='ff-para12'>People you may know</p>
              {loadingFriendsugg ? <div className='data-loading'>Data Loading....</div>
                : <>{friendSuggest()}</>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}