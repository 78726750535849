import { useState, SyntheticEvent, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { SearchUSAFencing } from "./form elements/SearchUSAFencing";
import { FormTextElement } from "./form elements/FormTextElement";
import PortraitIcon from '@mui/icons-material/Portrait';
import { FormSelectElement } from "./form elements/FormSelectElement";
import {
  RATING_NUMBER_CHOICES,
  REGION_CHOICES,
  HAND_CHOICES,
  GRIP_CHOICES,
  REF_RATING_CHOICES,
  USAF_RATING_CHOICES,
} from "../../constants/VarOptions";
import { CSRF } from "./form elements/CSRF";
import { getNewRefreshToken } from "../../hooks/utils";
import Collapse from "@mui/material/Collapse";
import swal from "sweetalert";

export const FencerForm = () => {
  const navigate = useNavigate();
  const [validations, setValidations] = useState(false);
  const [checked, setChecked] = useState(false);
  const [usaFencingInfo, setUSAFencingInfo] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [club, setClub] = useState<string>("");
  const [club2, setClub2] = useState<string>("");
  const [school, setSchool] = useState<string>("");
  const [division, setDivision] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [nationality, setNationality] = useState<string>("");
  const [handed, setHanded] = useState<string>("");
  const [grip, setGrip] = useState<string>("");
  const [ratingEpee, setRatingEpee] = useState<string>("");
  const [ratingSabre, setRatingSabre] = useState<string>("");
  const [ratingFoil, setRatingFoil] = useState<string>("");
  const [refRatingEpee, setRefRatingEpee] = useState<string>("");
  const [refRatingSabre, setRefRatingSabre] = useState<string>("");
  const [refRatingFoil, setRefRatingFoil] = useState<string>("");
  const [customRating, setcustomRating] = useState<string>("");
  const [tacticDescript, setTacticDescript] = useState<string>("");
  const [favActions, setFavActions] = useState<string>("");
  const [notes, setNotes] = useState<string>("");

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault(); // prevents page from reloading.
    if (firstName && lastName && region) {
      await getNewRefreshToken();

      await axios
        .post(
          "/api/fencers/create/",
          {
            first_name: firstName,
            last_name: lastName,
            club,
            club2,
            school,
            division,
            region,
            nationality,
            handed,
            primary_grip: grip,
            usaf_rating_epee: ratingEpee,
            usaf_rating_sabre: ratingSabre,
            usaf_rating_foil: ratingFoil,
            ref_rating_epee: refRatingEpee,
            ref_rating_sabre: refRatingSabre,
            ref_rating_foil: refRatingFoil,
            custom_rating: customRating,
            tactical_description: tacticDescript,
            favorite_actions: favActions,
            notes,
          },
          { withCredentials: true }
        )
        .then((res) => {
          swal({
            title: "Success",
            text: res?.data?.message,
            icon: "success",
            timer: 1000,
            // buttons: "",
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
      setValidations(false);
      setFirstName("");
      setLastName("");
      setClub("");
      setClub2("");
      setSchool("");
      setDivision("");
      setRegion("");
      setNationality("");
      setHanded("");
      setGrip("");
      setRatingEpee("");
      setRatingSabre("");
      setRatingFoil("");
      setRefRatingEpee("");
      setRefRatingSabre("");
      setRefRatingFoil("");
      setcustomRating("");
      setTacticDescript("");
      setFavActions("");
      setNotes("");
      navigate(`/journal/fencers`);
    } else {
      setValidations(true);
    }
  };

  return (
    <>
      <h1 className="h1 Fencertitle">New Fencer</h1>
      <SearchUSAFencing />
      
      <hr />

      <p style={{ fontWeight: '600' }}>OR</p>

      <h1 className="Fencertitle">
        <span
          onClick={() => setChecked(!checked)}
          style={{ cursor: "pointer", display: 'flex', justifyContent: 'center' }}>
          Custom Profile <PortraitIcon style={{ fontSize: '25px', marginLeft: '10px', marginTop: '2px' }} />
        </span>
      </h1>

      <br />

      <Collapse in={checked}>
        <form className="fencerform" onSubmit={submit}>
          <CSRF />
          <div className="Please-error">
            <FormTextElement setValue={setFirstName} value={firstName} elementName="first" placeholder="" labelText="First Name"/>
            {validations && !firstName && (
              <p className="testerror">Please enter First name</p>
            )}
          </div>
          <div className="Please-error">
            <FormTextElement setValue={setLastName} value={lastName} elementName="last" placeholder="" labelText="Last Name"/>
            {validations && !lastName && (
              <p className="testerror11">Please enter Last name</p>
            )}
          </div>
          <FormSelectElement setValue={setHanded} elementName="handed" value={handed} selectOptions={HAND_CHOICES} placeholder="" labelText="Handedness"/>
          <FormSelectElement setValue={setGrip} elementName="grip" value={grip} selectOptions={GRIP_CHOICES} placeholder="" labelText="Grip Preference"/>
          <FormTextElement setValue={setClub} elementName="club" value={club} placeholder="" labelText="Club"/>
          <FormTextElement setValue={setClub2} elementName="club2" value={club2} placeholder="" labelText="Secondary Club"/>
          <FormTextElement setValue={setSchool} elementName="school" value={school} placeholder="" labelText="School"/>
          <FormTextElement setValue={setDivision} elementName="division" value={division} placeholder="" labelText="Division"/>
          <div className="Please-error">
            <FormSelectElement setValue={setRegion} elementName="region" value={region} selectOptions={REGION_CHOICES} placeholder="" labelText="Region"/>
            {validations && !region && (
              <p className="testerror1">Please Select Region</p>
            )}
          </div>
          <FormTextElement setValue={setNationality} value={nationality} elementName="nationality" placeholder="" labelText="Nationality"/>
          <FormSelectElement setValue={setRatingEpee} value={ratingEpee} elementName="ratingEpee" selectOptions={USAF_RATING_CHOICES} placeholder="" labelText="Epee Rating"/>
          <FormSelectElement setValue={setRatingSabre} value={ratingSabre} elementName="ratingSabre" selectOptions={USAF_RATING_CHOICES} placeholder="" labelText="Sabre Rating"/>
          <FormSelectElement setValue={setRatingFoil} value={ratingFoil} elementName="ratingFoil" selectOptions={USAF_RATING_CHOICES} placeholder="" labelText="Foil Rating"/>
          <FormSelectElement setValue={setRefRatingEpee} value={refRatingEpee} elementName="refRatingEpee" selectOptions={REF_RATING_CHOICES} placeholder="" labelText="Epee Referee Rating"/>
          <FormSelectElement setValue={setRefRatingSabre} value={refRatingSabre} elementName="refRatingSabre" selectOptions={REF_RATING_CHOICES} placeholder="" labelText="Sabre Referee Rating"/>
          <FormSelectElement setValue={setRefRatingFoil} value={refRatingFoil} elementName="refRatingFoil" selectOptions={REF_RATING_CHOICES} placeholder="" labelText="Foil Referee Rating"/>
          <FormTextElement setValue={setcustomRating} value={customRating} elementName="customRating" placeholder="" labelText="Custom Rating"/>
          <FormTextElement setValue={setTacticDescript} value={tacticDescript} elementName="tacticalDescription" placeholder="" labelText="Tactical Description"/>
          <FormTextElement setValue={setFavActions} value={favActions} elementName="favActions" placeholder="" labelText="Favorite Actions"/>
          <FormTextElement setValue={setNotes} value={notes} elementName="notes" placeholder="" labelText="Notes"/>
          <button className="submit-button Fencerbtn" type="button" onClick={submit}>
            Add Fencer
          </button>
        </form>
      </Collapse>
    </>
  );
};
