import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import { boutType } from "../../constants/VarTypes";
import { ReactComponent as EditIcon } from "../../icons/edit_icon.svg";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useDispatch } from "react-redux";
import { setAuth } from "../../redux/authSlice";
import { getNewRefreshToken } from "../../hooks/utils";
import swal from 'sweetalert';
// import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { transform } from "typescript";

export type video = {
  id: number
  url: string
}

export function BoutView() {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [dataReceived, setDataReceived] = useState(false);
  const [selectedFile, setselectedFile] = useState<File | null>(null);
  const [editId, seteditId] = useState<number | null>(null);
  const [videosData, setVideosData] = useState<[video] | []>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const fileEditRef = useRef<HTMLInputElement | null>(null);
  const [fencerASlug, setFencerASlug] = useState('')
  const [fencerBSlug, setFencerBSlug] = useState('')
  const [tournament, setTournament] = useState({
    name: "",
    slug: ""
    })
  const [event, setEvent] = useState({
    name: "",
    slug: ""
    })
  const [bout, setBout] = useState<boutType>({
    id: 99,
    slug: "",
    winnerIsA: true,
    fencerA: "",
    fencerB: "",
    scoreA: 0,
    scoreB: 0,
    fencerAHand: "",
    fencerBHand: "",
    fencerAYellowCard: false,
    fencerBYellowCard: false,
    fencerARedCard: false,
    fencerBRedCard: false,
    fencerABlackCard: false,
    fencerBBlackCard: false,
    fencerAPassivity: false,
    fencerBPassivity: false,
    fencerAMedical: false,
    fencerBMedical: false,
    fencerAVideoUsed: 0,
    fencerBVideoUsed: 0,
    fencerAFootwork: "",
    fencerBFootwork: "",
    fencerABladework: "",
    fencerBBladework: "",
    fencerADistance: "",
    fencerBDistance: "",
    fencerATechnique: "",
    fencerBTechnique: "",
    fencerAEnergy: "",
    fencerBEnergy: "",
    fencerANotes: "",
    fencerBNotes: "",
    referee: "",
    boutFormat: "",
    round: 0,
    notes: "",
    shareCoach: true,
    deleted: false,                  
  });

  useEffect(() => {
    getData();
    var myData = localStorage.getItem("userData");
    if (myData) {
      dispatch(setAuth(true));
    }
  }, []);

  const getData = async () => {
    if (dataReceived === false) {
      await getNewRefreshToken();
      const result = await axios.get("api/bouts/detail/" + slug, {
        withCredentials: true,
      });
      setDataReceived(true)
      setTournament(result.data.tournament)
      setEvent(result.data.event)
      setVideosData(result.data.bout_media)
      setFencerASlug(result.data.fencer_a_slug)
      setFencerBSlug(result.data.fencer_b_slug)
      // setBout(result.data)
      setBout({
        id: result.data.id,
        slug: result.data.slug,
        winnerIsA: result.data.winner_is_a,
        fencerA: result.data.fencer_a,
        fencerB: result.data.fencer_b,
        scoreA: result.data.score_a,
        scoreB: result.data.score_b,
        fencerAHand: result.data.fencer_a_hand,
        fencerBHand: result.data.fencer_b_hand,
        fencerAYellowCard: result.data.fencer_a_yellow_card,
        fencerBYellowCard: result.data.fencer_b_yellow_card,
        fencerARedCard: result.data.fencer_a_red_card,
        fencerBRedCard: result.data.fencer_b_red_card,
        fencerABlackCard: result.data.fencer_a_black_card,
        fencerBBlackCard: result.data.fencer_b_black_card,
        fencerAPassivity: result.data.fencer_a_passivity,
        fencerBPassivity: result.data.fencer_b_passivity,
        fencerAMedical: result.data.fencer_a_medical,
        fencerBMedical: result.data.fencer_b_medical,
        fencerAVideoUsed: result.data.fencer_a_video_used,
        fencerBVideoUsed: result.data.fencer_b_video_used,
        fencerAFootwork: result.data.fencer_a_footwork,
        fencerBFootwork: result.data.fencer_b_footwork,
        fencerABladework: result.data.fencer_a_bladework,
        fencerBBladework: result.data.fencer_b_bladework,
        fencerADistance: result.data.fencer_a_distance,
        fencerBDistance: result.data.fencer_b_distance,
        fencerATechnique: result.data.fencer_a_technique,
        fencerBTechnique: result.data.fencer_b_technique,
        fencerAEnergy: result.data.fencer_a_energy,
        fencerBEnergy: result.data.fencer_b_energy,
        fencerANotes: result.data.fencer_a_notes,
        fencerBNotes: result.data.fencer_b_notes,
        referee: result.data.referee,
        boutFormat: result.data.bout_format,
        round: result.data.round,
        notes: result.data.notes,
        shareCoach: result.data.share_coach,
        deleted: result.data.deleted,
      });
    }
  };

  useEffect(() => {
    getData();
  }, [dataReceived])


  function breadcrumbs() {
    return(
    <div className="container breadcrumbs container-width">
        <div className="backtotournament">
          <p
            className="initialclass"
            style={{ float: "left", marginRight: "5px" }}
            onClick={() => navigate("/journal/tournaments")}
          >
            Tournaments /{" "}
          </p>
          <p 
            onClick={()=>navigate(`/journal/events/${tournament.slug}`)}
            style={{ float: "left", marginRight: "5px" }}
            >
            {tournament.name} {" "}/
          </p>
          <p 
            onClick={()=>navigate(`/journal/bouts/${event.slug}`)}
            style={{ float: "left", marginRight: "5px" }}
            >
            {event.name} {" "}/
          </p>
          <span>
            Bout
          </span>
    
        </div>
      </div>
    );
  }

  function boutSummaryDetail() {
    return (
      <div className="container">
        <p className="detail">
          <a href={"/journal/fencersbouts/" + fencerASlug}>{bout.fencerA ? bout.fencerA : "Unknown"}</a> vs{" "}
          <a href={"/journal/fencersbouts/" + fencerBSlug}>{bout.fencerB ? bout.fencerB : "Unknown"}</a>
        </p>
        <p className="detail" style={{ display: 'flex', justifyContent: 'center' }}>
          {bout.scoreA}{" "}
          {bout.winnerIsA && (
            <>
              <EmojiEventsIcon style={{ fontSize: '18px', marginTop: '5px' }} />
            </>
          )}{" "}
          - {bout.scoreB}{" "}
          {!bout.winnerIsA && (
            <>
              <EmojiEventsIcon style={{ fontSize: '18px', marginTop: '5px' }} />
            </>
          )}
        </p>
        {bout.notes ? <p>{bout.notes}</p> : <></>}
      </div>
    );
  }
  
  function boutOverallDetail() {
    return (
      <section className="bout-tab-content ">
        <h2 className="Overalltitle">Overall Data: </h2>
        {bout.referee ? <p>Referee: {bout.referee}</p> : <></>}
        {bout.boutFormat ? <p>Format: {bout.boutFormat}</p> : <></>}
        {bout.round ? <p>Round: {bout.round}</p> : <></>}
        {bout.notes ? <p>Notes: {bout.notes}</p> : <></>}
        {bout.shareCoach ? <p>{bout.shareCoach}</p> : <></>}
      </section>
    );
  }

  function fencerADetail() {
    return (
      <section className="bout-tab-content">
        <h2 className="Overalltitle">Fencer A</h2>
        <h2>Info:</h2>
        {bout.fencerA ? <p>Name: {bout.fencerA}</p> : <></>}
        {bout.scoreA ? <p>Scored: {bout.scoreA}</p> : <></>}
        {bout.fencerAHand ? <p>Handedness: {bout.fencerAHand}</p> : <></>}
        {bout.fencerAPassivity ? <p>Passivity: {bout.fencerAPassivity}</p> : <></>}
        {bout.fencerAMedical ? <p>Medical time used: {bout.fencerAMedical}</p> : <></>}
        {bout.fencerAVideoUsed ? <p>Video replays used: {bout.fencerAVideoUsed}</p> : <></>}
        <h2>Cards:</h2>
        {bout.fencerAYellowCard ? <p>Yellow: {bout.fencerAYellowCard}</p> : <></>}
        {bout.fencerARedCard ? <p>Red: {bout.fencerARedCard}</p> : <></>}
        {bout.fencerABlackCard ? <p>Black: {bout.fencerABlackCard}</p> : <></>}
        <h2>Fencer Ratings:</h2>
        {bout.fencerAFootwork ? <p>Footwork: {bout.fencerAFootwork}</p> : <></>}
        {bout.fencerABladework ? <p>Bladework: {bout.fencerABladework}</p> : <></>}
        {bout.fencerATechnique ? <p>Technique: {bout.fencerATechnique}</p> : <></>}
        {bout.fencerADistance ? <p>Distance: {bout.fencerADistance}</p> : <></>}
        {bout.fencerAEnergy ? <p>Energy: {bout.fencerAEnergy}</p> : <></>}
        {bout.fencerANotes ? <p>Notes: {bout.fencerANotes}</p> : <></>}
      </section>
    );
  }

  function fencerBDetail() {
    return (
      <section className="bout-tab-content boutview">
        <h2 className="Overalltitle">Fencer B</h2>
        <h2>Info:</h2>
        {bout.fencerB ? <p>Name: {bout.fencerB}</p> : <></>}
        {bout.scoreB ? <p>Scored: {bout.scoreB}</p> : <></>}
        {bout.fencerBHand ? <p>Handedness: {bout.fencerBHand}</p> : <></>}
        {bout.fencerBPassivity ? <p>Passivity: {bout.fencerBPassivity}</p> : <></>}
        {bout.fencerBMedical ? <p> Medical time used: {bout.fencerBMedical}</p> : <></>}
        {bout.fencerBVideoUsed ? <p> Video replays used: {bout.fencerBVideoUsed}</p> : <></>}
        <h2>Cards:</h2>
        {bout.fencerBYellowCard ? <p>Yellow: {bout.fencerBYellowCard}</p> : <></>}
        {bout.fencerBRedCard ? <p>Red: {bout.fencerBRedCard}</p> : <></>}
        {bout.fencerBBlackCard ? <p>Black: {bout.fencerBBlackCard}</p> : <></>}
        <h2>Fencer Ratings:</h2>
        {bout.fencerBFootwork ? <p>Footwork: {bout.fencerBFootwork}</p> : <></>}
        {bout.fencerBBladework ? <p>Bladework: {bout.fencerBBladework}</p> : <></>}
        {bout.fencerBTechnique ? <p>Technique: {bout.fencerBTechnique}</p> : <></>}
        {bout.fencerBDistance ? <p>Distance: {bout.fencerBDistance}</p> : <></>}
        {bout.fencerBEnergy ? <p>Energy: {bout.fencerBEnergy}</p> : <></>}
        {bout.fencerBNotes ? <p>Notes: {bout.fencerBNotes}</p> : <></>}
      </section>
    );
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setselectedFile(file);
    }
  };

  //send video to the backend
  const handlePost = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (selectedFile) {
      try {
        await getNewRefreshToken();
        const formData = new FormData();
        formData.append('bout_file', selectedFile);
        const response = await axios.post(
          `/api/bouts/create-bouts-media/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
          }
        );
        setselectedFile(null);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        setDataReceived(false)
        swal({
          title: 'Success',
          timer: 3000,
          text: 'Video upload successfully',
          icon: 'success',
        });
      } catch (error: any) {
        console.log('Error on video upload', error);
      }
    } else {
      alert('Please select a video');
    }
  };

  // for deleting video
  const handleDelete = async (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    event.preventDefault();
    try {
      await getNewRefreshToken();
      const data = await axios.delete(`api/bouts/bout-media/${id}/delete/ `, {
        withCredentials: true,
      });
      setDataReceived(false)
    } catch (error: any) {
      swal({
        title: "Error",
        text: error.response?.data?.detail,
        icon: "warning",
        dangerMode: true,
      })
    }
  }

  const handleEdit = async (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    event.preventDefault();
    seteditId(id)
    if (fileEditRef.current) {
      fileEditRef.current.click();
    }
  }

  const handleEditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    // console.log(editId, file)
    if (file) {
      // console.log('swal running')
      swal({
        title: 'Update video ',
        text: file.name,
        icon: '/questionMark.png',
        buttons: ['Cancel', 'Add'],
        className: "icon-popup"
      }).then(async (confirmed) => {
        if (confirmed) {
          try {
            await getNewRefreshToken();
            const formData = new FormData();
            formData.append('bout_file', file);
            const response = await axios.put(
              `api/bouts/bout-media/${editId}/`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                withCredentials: true,
              }
            );
            setDataReceived(false)
            swal({
              title: 'Success',
              timer: 3000,
              text: 'Video uploaded',
              icon: 'success',
            })
          } catch (error) {
            console.error('API Error :', error);
          }
        }
      });
    }
  };

  const videoUpload = () => {
    return (
      <div className=" container videoUpload">
        <div className="videoUpload-section1">
          <label className="video-label" htmlFor="video-input-label">
            Choose Bout Video 
            <FileUploadIcon style={{ fontSize: '25px', marginTop: '4px', marginLeft: '2px' }} />
          </label>
          <input id="video-input-label"  type='file' style={{ display: 'none' }} accept='video/*' onChange={handleFileChange} ref={fileInputRef} />

          {selectedFile ? <p>{selectedFile.name}</p> : null}
          <button className="btnSave" style={{ marginTop: '5px' }} onClick={handlePost}>Upload</button>
        </div>

        <input type="file" style={{ display: 'none'}} accept='video/*' onChange={handleEditChange} ref={fileEditRef} />

        <div className="VideoUpload-section2">
          {videosData ?
            videosData.map((videoItem, index) => (
              <div key={index} className="videoUpload-videos">

                <video src={videoItem.url} controls />

                <div className="video-options">
                  <button onClick={(event) => handleEdit(event, videoItem.id)}>
                    {/* <EditIcon style={{ color: 'white', fontSize: '20px' }} /> */}
                    <EditIcon width="20px" height="20px" fill="white" />
                  </button>
                  <button onClick={(event) => handleDelete(event, videoItem.id)}>
                    <DeleteIcon style={{ color: 'white', fontSize: '20px' }} />
                  </button>
                </div>

              </div >
            )) : null}
        </div>

      </div >
    )
  }


  return (
    <>
      {dataReceived ? (breadcrumbs()) : null}
      {dataReceived ? (boutSummaryDetail()) : null}
      {videoUpload()}
      <div className="container">
        <div className="bout-tabs">
          <input
            className="bout-tab-radio"
            type="radio"
            id="overall"
            name="bout-info"
            defaultChecked
          />
          <label className="bout-tab-radio" htmlFor="overall">
            {/* <i className="fa fa-pencil font-20" /> */}
            <EditIcon />
            <br />
            <span>Overall</span>
          </label>
          {boutOverallDetail()}

          <input
            className="bout-tab-radio"
            type="radio"
            id="fencer-a"
            name="bout-info"
          />
          <label className="bout-tab-radio" htmlFor="fencer-a">
            {/* <i className="fa fa-pencil font-20" /> */}
            <EditIcon />
            <br />
            <span>Fencer A</span>
          </label>
          {fencerADetail()}

          <input
            className="bout-tab-radio"
            type="radio"
            id="fencer-b"
            name="bout-info"
          />
          <label className="bout-tab-radio" htmlFor="fencer-b">
            {/* <i className="fa fa-pencil font-20" /> */}
            <EditIcon />
            <br />
            <span>Fencer B</span>
          </label>
          {fencerBDetail()}
        </div>
      </div>
    </>
  );
}
